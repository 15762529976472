import { ChangeDetectionStrategy, Component, EventEmitter, Output } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { ButtonTheme, ConfirmationDialogComponent, DialogService, OperatorStatus, QualificationStatus } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, Logger, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { first, switchMap, tap } from "rxjs/operators";
import { NewThreadComponent } from "../../../conversation/components/shared/new-thread/new-thread.component";
import { ConversationActions } from "../../../conversation/state/conversation.actions";
import { ConversationState } from "../../../conversation/state/conversation.state";
import {
    ChangeLogType,
    ForeignCompetencyDocument,
    ForeignCompetencyStatus,
    OperatorActionType,
    OtherInformation,
    OtherInformationActionType,
} from "../../../shared";
import { AddOrEditOtherInformationDialogComponent } from "../../../shared/components/add-or-edit-other-information-dialog/add-or-edit-other-information-dialog.component";
import {
    DialogWithReasonComponent,
    DialogWithReasonConfigData,
} from "../../../shared/components/dialog-with-reason/dialog-with-reason.component";
import { AddOperationalAuthorizationComponent } from "../../../shared/components/operator-profile/add-operational-authorization/add-operational-authorization.component";
import { AddQualificationComponent } from "../../../shared/components/operator-profile/add-qualification/add-qualification.component";
import { AttorneyPowerUpdateDialogComponent } from "../../../shared/components/operator-profile/attorney-power-update-dialog/attorney-power-update-dialog.component";
import {
    Attachment,
    AttorneyPower,
    AttorneyPowerStatus,
    AttorneyPowerUpdate,
    NewOperationalAuthorization,
    NewQualification,
    OperationScenario,
    OperatorDetails,
    OperatorDocumentType,
    OperatorStatusChange,
    OperatorType,
    PermissionsToGrant,
    QualificationChangeType,
    QualificationStatusChange,
    QualificationType,
} from "../../../shared/models/operator.models";
import { OperatorProfileUpdateHelperService } from "../../services/operator-profile-update-helper.service";
import { OperatorActions } from "../../state/operator.actions";
import { OperatorState } from "../../state/operator.state";

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-operator-profile-container",
    templateUrl: "./operator-profile-container.component.html",
    styleUrls: ["./operator-profile-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OperatorProfileContainerComponent {
    @Output() public detailsChange: EventEmitter<void> = new EventEmitter<void>();

    protected readonly profile$ = this.store.select(OperatorState.selectedOperatorDetails);
    protected readonly isProcessing$ = this.store.select(OperatorState.isProcessing);
    protected readonly QualificationType = QualificationType;
    protected readonly OperatorType = OperatorType;
    protected readonly ChangeLogType = ChangeLogType;

    constructor(
        private readonly store: Store,
        private readonly dialogService: DialogService,
        private readonly translocoService: TranslocoService,
        private readonly operatorProfileUpdateHelperService: OperatorProfileUpdateHelperService,
        private readonly toastService: ToastrService
    ) {}

    protected changeOperatorStatus(statusChange: OperatorStatusChange) {
        if (statusChange.newStatus === OperatorStatus.Suspended) {
            this.showStatusChangeConfirmationDialogWithReason(statusChange);

            return;
        }

        this.showStatusChangeConfirmationDialog(statusChange);
    }

    protected writeNewMessage(interlocutor: OperatorDetails) {
        const currentInterlocutor = this.store.selectSnapshot(ConversationState.interlocutor);
        if (currentInterlocutor?.id === interlocutor.id) {
            return;
        }

        if (currentInterlocutor) {
            this.confirmInterlocutorChange(interlocutor);
        } else {
            this.openNewThread(interlocutor);
        }
    }

    protected addOperationalAuthorization(profile: OperatorDetails) {
        this.store
            .dispatch(new OperatorActions.GetOperatorsAvailableOperationScenarios(profile.id))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperatorState.operatorAvailableOperationScenariosError);

                if (error) {
                    this.toastService.error(
                        this.translocoService.translate(
                            "dtmAdminLibShared.operatorProfile.qualifications.operatorAvailableOperationScenariosDownloadError"
                        )
                    );

                    return;
                }

                const operatorAvailableOperationScenarios = this.store.selectSnapshot(OperatorState.operatorAvailableOperationScenarios);

                if (!operatorAvailableOperationScenarios?.length) {
                    this.toastService.warning(
                        this.translocoService.translate(
                            "dtmAdminLibShared.operatorProfile.qualifications.noOperationScenarioToAddWarning",
                            {
                                qualificationType: QualificationType.OperationalAuthorizations,
                            }
                        )
                    );

                    return;
                }

                this.openAddOperationalAuthorizationDialog(operatorAvailableOperationScenarios, profile);
            });
    }

    protected addPilotCompetency(profile: OperatorDetails) {
        const pilotsAvailableOperationScenarios = this.store.selectSnapshot(OperatorState.capabilities)?.competencies;

        if (pilotsAvailableOperationScenarios) {
            this.handlePilotOperationScenariosAndOpenDialog(pilotsAvailableOperationScenarios, profile);

            return;
        }

        this.store
            .dispatch(new OperatorActions.GetCapabilities())
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.handlePilotOperationScenariosAndOpenDialog(
                    this.store.selectSnapshot(OperatorState.capabilities)?.competencies,
                    profile
                );
            });
    }

    protected changeOperationalAuthorization(qualification: QualificationStatusChange, { id, type }: OperatorDetails) {
        if (qualification.status === QualificationStatus.Active) {
            this.getChangeQualificationConfirmation(qualification, QualificationType.OperationalAuthorizations)
                .pipe(
                    RxjsUtils.filterFalsy(),
                    switchMap(() => this.store.dispatch(new OperatorActions.ChangeOperationalAuthorizationStatus(id, qualification))),
                    untilDestroyed(this)
                )
                .subscribe(() => {
                    const changeType = this.operatorProfileUpdateHelperService.getQualificationChangeType(qualification);
                    const error = this.store.selectSnapshot(OperatorState.operationalAuthorizationsError);
                    if (error) {
                        this.operatorProfileUpdateHelperService.showActionErrorMessage(
                            OperatorActionType.UpdateOperationalAuthorization,
                            changeType
                        );

                        return;
                    }
                    this.operatorProfileUpdateHelperService.showActionSuccessMessage(
                        OperatorActionType.UpdateOperationalAuthorization,
                        changeType
                    );
                    this.store.dispatch(new OperatorActions.SelectOperator(id, type));
                });
        } else {
            const dialogRef = this.getChangeQualificationDialogWithReason(qualification, QualificationType.OperationalAuthorizations);
            dialogRef.componentInstance.setReason$
                .pipe(
                    switchMap((value: string) =>
                        this.store.dispatch(
                            new OperatorActions.ChangeOperationalAuthorizationStatus(id, {
                                ...qualification,
                                reason: value,
                            })
                        )
                    ),
                    untilDestroyed(this)
                )
                .subscribe(() => {
                    const changeType = this.operatorProfileUpdateHelperService.getQualificationChangeType(qualification);
                    const error = this.store.selectSnapshot(OperatorState.operationalAuthorizationsError);
                    if (error) {
                        this.operatorProfileUpdateHelperService.showActionErrorMessage(
                            OperatorActionType.UpdateOperationalAuthorization,
                            changeType
                        );

                        return;
                    }
                    dialogRef.close();
                    this.operatorProfileUpdateHelperService.showActionSuccessMessage(
                        OperatorActionType.UpdateOperationalAuthorization,
                        changeType
                    );
                    this.store.dispatch(new OperatorActions.SelectOperator(id, type));
                });
        }
    }

    protected changePilotCompetency(qualification: QualificationStatusChange, profile: OperatorDetails) {
        if (!profile.pilotId) {
            return;
        }

        if (qualification.status === QualificationStatus.Active) {
            this.getChangeQualificationConfirmation(qualification, QualificationType.PilotCompetencies)
                .pipe(
                    RxjsUtils.filterFalsy(),
                    switchMap(() =>
                        this.store.dispatch(
                            new OperatorActions.ChangePilotCompetencyStatus(profile.id, profile.pilotId as string, qualification)
                        )
                    ),
                    untilDestroyed(this)
                )
                .subscribe(() => {
                    const changeType = this.operatorProfileUpdateHelperService.getQualificationChangeType(qualification);
                    const error = this.store.selectSnapshot(OperatorState.pilotCompetencyUpdateError);
                    if (error) {
                        this.operatorProfileUpdateHelperService.showActionErrorMessage(
                            OperatorActionType.UpdatePilotCompetency,
                            changeType
                        );

                        return;
                    }
                    this.operatorProfileUpdateHelperService.showActionSuccessMessage(OperatorActionType.UpdatePilotCompetency, changeType);
                    this.store.dispatch(new OperatorActions.SelectOperator(profile.id, OperatorType.Personal));
                });
        } else {
            const dialogRef = this.getChangeQualificationDialogWithReason(qualification, QualificationType.PilotCompetencies);
            dialogRef.componentInstance.setReason$
                .pipe(
                    switchMap((value: string) =>
                        this.store.dispatch(
                            new OperatorActions.ChangePilotCompetencyStatus(profile.id, profile.pilotId as string, {
                                ...qualification,
                                reason: value,
                            })
                        )
                    ),
                    untilDestroyed(this)
                )
                .subscribe(() => {
                    const changeType = this.operatorProfileUpdateHelperService.getQualificationChangeType(qualification);
                    const error = this.store.selectSnapshot(OperatorState.pilotCompetencyUpdateError);
                    if (error) {
                        this.operatorProfileUpdateHelperService.showActionErrorMessage(
                            OperatorActionType.UpdatePilotCompetency,
                            changeType
                        );

                        return;
                    }
                    dialogRef.close();
                    this.operatorProfileUpdateHelperService.showActionSuccessMessage(OperatorActionType.UpdatePilotCompetency, changeType);
                    this.store.dispatch(new OperatorActions.SelectOperator(profile.id, OperatorType.Personal));
                });
        }
    }

    protected removePilotCompetency(qualificationId: string, profile: OperatorDetails) {
        if (!profile.pilotId) {
            return;
        }

        const dialogRef = this.getRemoveConfirmationDialog(QualificationType.PilotCompetencies);
        dialogRef.componentInstance.setReason$
            .pipe(
                switchMap(() =>
                    this.store.dispatch(new OperatorActions.RemovePilotCompetency(profile.id, qualificationId, profile.pilotId as string))
                ),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperatorState.pilotCompetencyUpdateError);
                if (error) {
                    this.operatorProfileUpdateHelperService.showActionErrorMessage(
                        OperatorActionType.UpdatePilotCompetency,
                        QualificationChangeType.Delete
                    );

                    return;
                }
                this.operatorProfileUpdateHelperService.showActionSuccessMessage(
                    OperatorActionType.UpdatePilotCompetency,
                    QualificationChangeType.Delete
                );
                dialogRef.close();
                this.store.dispatch(new OperatorActions.SelectOperator(profile.id, OperatorType.Personal));
            });
    }

    protected removeOperationalAuthorization(qualificationId: string, { id, type }: OperatorDetails) {
        const dialogRef = this.getRemoveConfirmationDialog(QualificationType.OperationalAuthorizations);
        dialogRef.componentInstance.setReason$
            .pipe(
                switchMap(() => this.store.dispatch(new OperatorActions.RemoveOperationalAuthorization(id, qualificationId))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperatorState.operationalAuthorizationsError);
                if (error) {
                    this.operatorProfileUpdateHelperService.showActionErrorMessage(
                        OperatorActionType.UpdateOperationalAuthorization,
                        QualificationChangeType.Delete
                    );

                    return;
                }
                this.operatorProfileUpdateHelperService.showActionSuccessMessage(
                    OperatorActionType.UpdateOperationalAuthorization,
                    QualificationChangeType.Delete
                );
                dialogRef.close();
                this.store.dispatch(new OperatorActions.SelectOperator(id, type));
            });
    }

    protected suspendAllPilotCompetencies(profile: OperatorDetails) {
        const dialogRef = this.dialogService.open(DialogWithReasonComponent, {
            data: {
                titleText: this.translocoService.translate(
                    "dtmAdminLibShared.operatorProfile.qualifications.suspendAllPilotCompetenciesReasonTitle"
                ),
                isReasonProcessing$: this.isProcessing$,
            },
        });

        dialogRef.componentInstance.setReason$
            .pipe(
                switchMap((value: string) =>
                    this.store.dispatch(
                        new OperatorActions.SuspendAllPilotCompetencies(profile.id, profile.pilotId as string, {
                            status: QualificationStatus.Suspended,
                            reason: value,
                        })
                    )
                ),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperatorState.pilotCompetenciesSuspendAllError);
                if (error) {
                    this.operatorProfileUpdateHelperService.showActionErrorMessage(OperatorActionType.SuspendAllPilotCompetencies);

                    return;
                }
                this.operatorProfileUpdateHelperService.showActionSuccessMessage(OperatorActionType.SuspendAllPilotCompetencies);
                dialogRef.close();
                this.store.dispatch(new OperatorActions.SelectOperator(profile.id, OperatorType.Personal));
            });
    }

    protected updateAttorneyPower(attorneyPower: AttorneyPower, profile: OperatorDetails) {
        const availablePermissions = this.store.selectSnapshot(OperatorState.capabilities)?.permissionsToGrant;

        if (availablePermissions) {
            this.handleAttorneyPowerPermissionsAndOpenDialog(availablePermissions, attorneyPower, profile);

            return;
        }

        this.store
            .dispatch(new OperatorActions.GetCapabilities())
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.handleAttorneyPowerPermissionsAndOpenDialog(
                    this.store.selectSnapshot(OperatorState.capabilities)?.permissionsToGrant,
                    attorneyPower,
                    profile
                );
            });
    }

    protected downloadAttorneyPowerDocument({ documentId, documentFileName }: AttorneyPower, { id }: OperatorDetails) {
        const attachment: Attachment = {
            documentId: documentId as string,
            documentFileName: documentFileName as string,
        };
        this.store.dispatch(new OperatorActions.GetDocument(id, attachment, OperatorDocumentType.AttorneyPower));
    }

    protected downloadAdministrativeFeeDocument(profile: OperatorDetails) {
        const attachment: Attachment = {
            documentId: profile.administrativeFee?.feeConfirmationId as string,
            documentFileName: profile.administrativeFee?.filename as string,
        };
        this.store.dispatch(new OperatorActions.GetDocument(profile.id, attachment, OperatorDocumentType.AdministrativeFee));
    }

    protected downloadForeignCompetencyDocument(foreignCompetencyDocument: ForeignCompetencyDocument) {
        this.store.dispatch(new OperatorActions.GetForeignCompetencyDocument(foreignCompetencyDocument));
    }

    protected acceptCompetencyDocument(competencyId: string) {
        const selectedOperator = this.store.selectSnapshot(OperatorState.selectedOperatorDetails);
        const pilotId = selectedOperator?.pilotId;
        if (!pilotId) {
            return;
        }

        const dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
            data: {
                titleText: this.translocoService.translate(
                    "dtmAdminLibOperator.operatorProfile.foreignCompetencies.confirmAcceptanceDialogTitle"
                ),
                confirmationText: "",
                declineButtonLabel: this.translocoService.translate(
                    "dtmAdminLibOperator.operatorProfile.foreignCompetencies.dialogCancelLabel"
                ),
                confirmButtonLabel: this.translocoService.translate(
                    "dtmAdminLibOperator.operatorProfile.foreignCompetencies.dialogAcceptanceConfirmLabel"
                ),
                theme: ButtonTheme.Primary,
            },
        });
        dialogRef
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() =>
                    this.store.dispatch(
                        new OperatorActions.UpdateForeignCompetencyStatus(pilotId, competencyId, ForeignCompetencyStatus.Accepted)
                    )
                ),
                switchMap(() => this.store.select(OperatorState.updateForeignCompetencyStatusError)),
                first(),
                tap((error) => {
                    if (error) {
                        this.toastService.error(
                            this.translocoService.translate(
                                "dtmAdminLibOperator.operatorProfile.foreignCompetencies.competencyStatusAcceptanceError"
                            )
                        );

                        return;
                    }
                    this.toastService.success(
                        this.translocoService.translate(
                            "dtmAdminLibOperator.operatorProfile.foreignCompetencies.competencyStatusAcceptanceSuccess"
                        )
                    );
                    this.store.dispatch(new OperatorActions.SelectOperator(selectedOperator.id, selectedOperator.type));
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    protected rejectCompetencyDocument(competencyId: string) {
        const selectedOperator = this.store.selectSnapshot(OperatorState.selectedOperatorDetails);
        const pilotId = selectedOperator?.pilotId;
        if (!pilotId) {
            return;
        }

        const dialogRef = this.dialogService.open(DialogWithReasonComponent, {
            data: {
                titleText: this.translocoService.translate("dtmAdminLibOperator.operatorProfile.foreignCompetencies.rejectDocumentTitle"),
                confirmationText: "",
                declineButtonLabel: this.translocoService.translate(
                    "dtmAdminLibOperator.operatorProfile.foreignCompetencies.dialogCancelLabel"
                ),
                confirmButtonLabel: this.translocoService.translate(
                    "dtmAdminLibOperator.operatorProfile.foreignCompetencies.dialogRejectDocumentConfirmationLabel"
                ),
                isReasonProcessing$: this.isProcessing$,
            },
        });

        dialogRef.componentInstance.setReason$
            .pipe(
                switchMap((value: string) =>
                    this.store.dispatch(
                        new OperatorActions.UpdateForeignCompetencyStatus(pilotId, competencyId, ForeignCompetencyStatus.Rejected, value)
                    )
                ),
                switchMap(() => this.store.select(OperatorState.updateForeignCompetencyStatusError)),
                first(),
                tap((error) => {
                    if (error) {
                        this.toastService.error(
                            this.translocoService.translate(
                                "dtmAdminLibOperator.operatorProfile.foreignCompetencies.competencyStatusRejectionError"
                            )
                        );

                        return;
                    }
                    dialogRef.close();
                    this.toastService.success(
                        this.translocoService.translate(
                            "dtmAdminLibOperator.operatorProfile.foreignCompetencies.competencyStatusRejectionSuccess"
                        )
                    );
                    this.detailsChange.emit();
                    this.store.dispatch(new OperatorActions.SelectOperator(selectedOperator.id, selectedOperator.type));
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    protected addOtherInformation() {
        const operatorProfile = this.store.selectSnapshot(OperatorState.selectedOperatorDetails);

        if (!operatorProfile) {
            Logger.captureMessage("OperatorProfileContainer.addOtherInformation: Missing selectedOperatorDetails");

            return;
        }

        const dialogRef = this.dialogService.open(AddOrEditOtherInformationDialogComponent, {
            disableClose: true,
            data: {
                isProcessing$: this.isProcessing$,
                actionType: OtherInformationActionType.Add,
            },
        });

        dialogRef.componentInstance.otherInfoText$
            .pipe(
                switchMap((value: string) => this.store.dispatch(new OperatorActions.AddOtherInformation(operatorProfile.id, value))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                if (this.store.selectSnapshot(OperatorState.addOtherInformationError)) {
                    this.operatorProfileUpdateHelperService.showActionErrorMessage(OperatorActionType.AddOtherInformation);

                    return;
                }

                this.operatorProfileUpdateHelperService.showActionSuccessMessage(OperatorActionType.AddOtherInformation);
                this.store.dispatch(new OperatorActions.SelectOperator(operatorProfile.id, operatorProfile.type));
                dialogRef.close();
            });
    }

    protected editOtherInformation(otherInformation: OtherInformation) {
        const operatorProfile = this.store.selectSnapshot(OperatorState.selectedOperatorDetails);

        if (!operatorProfile) {
            Logger.captureMessage("OperatorProfileContainer.editOtherInformation: Missing selectedOperatorDetails");

            return;
        }

        const dialogRef = this.dialogService.open(AddOrEditOtherInformationDialogComponent, {
            disableClose: true,
            data: {
                isProcessing$: this.isProcessing$,
                actionType: OtherInformationActionType.Edit,
                existingText: otherInformation.text,
            },
        });

        dialogRef.componentInstance.otherInfoText$
            .pipe(
                switchMap((value: string) =>
                    this.store.dispatch(new OperatorActions.EditOtherInformation(operatorProfile.id, otherInformation.id, value))
                ),
                untilDestroyed(this)
            )
            .subscribe(() => {
                if (this.store.selectSnapshot(OperatorState.editOtherInformationError)) {
                    this.operatorProfileUpdateHelperService.showActionErrorMessage(OperatorActionType.EditOtherInformation);

                    return;
                }

                this.operatorProfileUpdateHelperService.showActionSuccessMessage(OperatorActionType.EditOtherInformation);
                this.store.dispatch(new OperatorActions.SelectOperator(operatorProfile.id, operatorProfile.type));
                dialogRef.close();
            });
    }

    protected deleteOtherInformation(otherInformationId: string) {
        const operatorProfile = this.store.selectSnapshot(OperatorState.selectedOperatorDetails);

        if (!operatorProfile) {
            Logger.captureMessage("OperatorProfileContainer.deleteOtherInformation: Missing selectedOperatorDetails");

            return;
        }

        const dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
            data: {
                titleText: this.translocoService.translate(
                    "dtmAdminLibOperator.operatorProfile.otherInformationChange.deleteOtherInformationDialogHeader"
                ),
                confirmationText: "",
                declineButtonLabel: this.translocoService.translate(
                    "dtmAdminLibOperator.operatorProfile.otherInformationChange.deleteOtherInformationCancelLabel"
                ),
                confirmButtonLabel: this.translocoService.translate(
                    "dtmAdminLibOperator.operatorProfile.otherInformationChange.deleteOtherInformationConfirmLabel"
                ),
                theme: ButtonTheme.Warn,
            },
        });

        dialogRef
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.store.dispatch(new OperatorActions.DeleteOtherInformation(operatorProfile.id, otherInformationId))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                if (this.store.selectSnapshot(OperatorState.deleteOtherInformationError)) {
                    this.operatorProfileUpdateHelperService.showActionErrorMessage(OperatorActionType.DeleteOtherInformation);

                    return;
                }

                this.operatorProfileUpdateHelperService.showActionSuccessMessage(OperatorActionType.DeleteOtherInformation);
                this.store.dispatch(new OperatorActions.SelectOperator(operatorProfile.id, operatorProfile.type));
                dialogRef.close();
            });
    }

    protected deleteOperator(profile: OperatorDetails) {
        this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.translocoService.translate(
                        "dtmAdminLibOperator.operatorProfile.removeOperator.confirmOperatorRemovalDialog.titleText"
                    ),
                    confirmationText: this.translocoService.translate(
                        "dtmAdminLibOperator.operatorProfile.removeOperator.confirmOperatorRemovalDialog.contentText"
                    ),
                    declineButtonLabel: this.translocoService.translate(
                        "dtmAdminLibOperator.operatorProfile.removeOperator.confirmOperatorRemovalDialog.cancelButtonLabel"
                    ),
                    confirmButtonLabel: this.translocoService.translate(
                        "dtmAdminLibOperator.operatorProfile.removeOperator.confirmOperatorRemovalDialog.confirmButtonLabel"
                    ),
                    theme: ButtonTheme.Warn,
                },
            })
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.store.dispatch(new OperatorActions.DeleteOperator(profile.id))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperatorState.deleteOperatorError);

                if (error) {
                    this.operatorProfileUpdateHelperService.showActionErrorMessage(OperatorActionType.DeleteOperator);

                    return;
                }
                this.operatorProfileUpdateHelperService.showActionSuccessMessage(OperatorActionType.DeleteOperator);
                this.detailsChange.emit();
            });
    }

    private handleAttorneyPowerPermissionsAndOpenDialog(
        availablePermissions: PermissionsToGrant[] | undefined,
        attorneyPower: AttorneyPower,
        profile: OperatorDetails
    ) {
        const isAttorneyPowerApproved = attorneyPower.status === AttorneyPowerStatus.Approved;
        const error = this.store.selectSnapshot(OperatorState.capabilitiesError);

        if (error) {
            this.toastService.error(
                this.translocoService.translate("dtmAdminLibOperator.operatorProfile.attorneyPowerUpdate.permissionsDownloadError")
            );

            return;
        }

        if (!availablePermissions?.length) {
            this.toastService.warning(
                this.translocoService.translate("dtmAdminLibOperator.operatorProfile.attorneyPowerUpdate.noPermissionsToAddWarning")
            );

            return;
        }

        const dialogRef = this.dialogService.open(AttorneyPowerUpdateDialogComponent, {
            data: {
                permissions: attorneyPower.permissions,
                availablePermissions,
            },
        });

        dialogRef.componentInstance.newValue$
            .pipe(
                switchMap((permissions: PermissionsToGrant[]) => {
                    if (isAttorneyPowerApproved) {
                        return this.store.dispatch(
                            new OperatorActions.UpdateAttorneyPowerPermissions(profile.id, attorneyPower.id, permissions)
                        );
                    } else {
                        const attorneyPowerUpdate: AttorneyPowerUpdate = {
                            id: attorneyPower.id,
                            status: AttorneyPowerStatus.Approved,
                            permissions,
                        };

                        return this.store.dispatch(new OperatorActions.UpdateAttorneyPower(profile.id, attorneyPowerUpdate));
                    }
                }),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const attorneyPowerError = this.store.selectSnapshot(OperatorState.updateAttorneyPowerError);
                if (attorneyPowerError) {
                    this.operatorProfileUpdateHelperService.showActionErrorMessage(OperatorActionType.UpdateAttorneyPower);

                    return;
                }
                this.operatorProfileUpdateHelperService.showActionSuccessMessage(OperatorActionType.UpdateAttorneyPower);
                dialogRef.close();
                this.store.dispatch(new OperatorActions.SelectOperator(profile.id, profile.type));
            });
    }

    private handlePilotOperationScenariosAndOpenDialog(
        pilotsAvailableOperationScenarios: OperationScenario[] | undefined,
        profile: OperatorDetails
    ) {
        const error = this.store.selectSnapshot(OperatorState.capabilitiesError);

        if (error) {
            this.toastService.error(
                this.translocoService.translate("dtmAdminLibShared.operatorProfile.qualifications.competenciesDownloadError")
            );

            return;
        }

        if (!pilotsAvailableOperationScenarios?.length) {
            this.toastService.warning(
                this.translocoService.translate("dtmAdminLibShared.operatorProfile.qualifications.noOperationScenarioToAddWarning", {
                    qualificationType: QualificationType.PilotCompetencies,
                })
            );

            return;
        }

        this.openAddPilotCompetencyModal(pilotsAvailableOperationScenarios, profile);
    }

    private getChangeQualificationConfirmation(
        qualification: QualificationStatusChange,
        qualificationType: QualificationType
    ): Observable<boolean> {
        const titleTextTranslationKey =
            qualificationType === QualificationType.OperationalAuthorizations
                ? "dtmAdminLibShared.operatorProfile.qualifications.authorizationStatusChangeConfirmationTitle"
                : "dtmAdminLibShared.operatorProfile.qualifications.competencyStatusChangeConfirmationTitle";

        const dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
            data: {
                titleText: this.translocoService.translate(titleTextTranslationKey, { qualificationStatus: qualification.status }),
                declineButtonLabel: this.translocoService.translate("dtmAdminLibShared.operatorProfile.qualifications.cancelLabel"),
                confirmButtonLabel: this.translocoService.translate("dtmAdminLibShared.operatorProfile.qualifications.confirmButtonLabel"),
            },
        });

        return dialogRef.afterClosed();
    }

    private getChangeQualificationDialogWithReason(
        qualification: QualificationStatusChange,
        qualificationType: QualificationType
    ): MatDialogRef<DialogWithReasonComponent> {
        const titleTextTranslationKey =
            qualificationType === QualificationType.OperationalAuthorizations
                ? "dtmAdminLibShared.operatorProfile.qualifications.authorizationStatusChangeReasonTitle"
                : "dtmAdminLibShared.operatorProfile.qualifications.competencyStatusChangeReasonTitle";

        return this.dialogService.open(DialogWithReasonComponent, {
            data: {
                titleText: this.translocoService.translate(titleTextTranslationKey, { qualificationStatus: qualification.status }),
                isReasonProcessing$: this.isProcessing$,
            },
        });
    }

    private getRemoveConfirmationDialog(qualificationType: QualificationType): MatDialogRef<DialogWithReasonComponent> {
        return this.dialogService.open(DialogWithReasonComponent, {
            data: {
                titleText: this.translocoService.translate(
                    "dtmAdminLibShared.operatorProfile.qualifications.qualificationRemoveConfirmationTitle",
                    { qualificationType }
                ),
                isReasonProcessing$: this.isProcessing$,
            },
        });
    }

    private confirmInterlocutorChange(interlocutor: OperatorDetails) {
        const dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
            data: {
                titleText: this.translocoService.translate(
                    "dtmAdminLibOperator.operatorProfile.interlocutorChange.declineMessageTitleText"
                ),
                confirmationText: this.translocoService.translate(
                    "dtmAdminLibOperator.operatorProfile.interlocutorChange.declineMessageConfirmText"
                ),
                declineButtonLabel: this.translocoService.translate(
                    "dtmAdminLibOperator.operatorProfile.interlocutorChange.declineMessageCancelLabel"
                ),
                confirmButtonLabel: this.translocoService.translate(
                    "dtmAdminLibOperator.operatorProfile.interlocutorChange.declineMessageConfirmLabel"
                ),
            },
        });

        dialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe((isConfirmed) => {
                if (!isConfirmed) {
                    return;
                }
                this.openNewThread(interlocutor);
            });
    }

    private openNewThread(interlocutor: OperatorDetails) {
        this.store.dispatch(new ConversationActions.SetInterlocutor(interlocutor));
        const dialog = this.dialogService.open(NewThreadComponent, {
            hasBackdrop: true,
            disableClose: true,
            closeOnNavigation: true,
            panelClass: "conversation-sheet",
        });

        dialog
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe(() => this.store.dispatch(new ConversationActions.ClearInterlocutorData()));
    }

    private showStatusChangeConfirmationDialogWithReason(newStatusValue: OperatorStatusChange) {
        const dialogRef = this.dialogService.open(DialogWithReasonComponent, {
            data: {
                titleText: this.translocoService.translate("dtmAdminLibOperator.operatorProfile.operatorStatus.changeStatusTitleText"),
                introHtml: this.translocoService.translate(
                    "dtmAdminLibOperator.operatorProfile.operatorStatus.changeStatusConfirmationHtml",
                    {
                        operatorName: newStatusValue.operator.name,
                        statusLabel: this.translocoService.translate(
                            "dtmAdminLibOperator.operatorProfile.operatorStatus.operatorStatusLabel",
                            {
                                value: newStatusValue.newStatus,
                            }
                        ),
                    }
                ),
                reasonFormLabel: this.translocoService.translate(
                    "dtmAdminLibOperator.operatorProfile.operatorStatus.suspensionReasonControlLabel"
                ),
                confirmButtonLabel: this.translocoService.translate(
                    "dtmAdminLibOperator.operatorProfile.operatorStatus.changeStatusConfirmationConfirmLabel"
                ),
                isReasonProcessing$: this.isProcessing$,
            } satisfies DialogWithReasonConfigData,
            disableClose: true,
        });

        dialogRef.componentInstance.setReason$
            .pipe(
                switchMap((reason: string) => this.store.dispatch(new OperatorActions.ChangeOperatorStatus({ ...newStatusValue, reason }))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperatorState.changeStatusError);
                if (error) {
                    this.operatorProfileUpdateHelperService.showActionErrorMessage(OperatorActionType.ChangeStatus);

                    return;
                }

                dialogRef.close();
                this.operatorProfileUpdateHelperService.showActionSuccessMessage(OperatorActionType.ChangeStatus);
                this.store.dispatch(new OperatorActions.SelectOperator(newStatusValue.operator.id, newStatusValue.operator.type));
                this.detailsChange.emit();
            });
    }

    private showStatusChangeConfirmationDialog(newStatusValue: OperatorStatusChange) {
        const dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
            data: this.operatorProfileUpdateHelperService.getChangeStatusConfirmationDialogConfig(newStatusValue),
        });

        dialogRef
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.store.dispatch(new OperatorActions.ChangeOperatorStatus(newStatusValue))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperatorState.changeStatusError);
                if (error) {
                    this.operatorProfileUpdateHelperService.showActionErrorMessage(OperatorActionType.ChangeStatus);

                    return;
                }
                this.operatorProfileUpdateHelperService.showActionSuccessMessage(OperatorActionType.ChangeStatus);
                this.store.dispatch(new OperatorActions.SelectOperator(newStatusValue.operator.id, newStatusValue.operator.type));
                this.detailsChange.emit();
            });
    }

    private openAddPilotCompetencyModal(pilotsAvailableOperationScenarios: OperationScenario[], profile: OperatorDetails) {
        const dialogRef = this.dialogService.open(AddQualificationComponent, {
            data: {
                availableOperationScenarios: pilotsAvailableOperationScenarios,
                qualificationType: QualificationType.PilotCompetencies,
            },
        });

        dialogRef.componentInstance.newValue$
            .pipe(
                switchMap((operationScenario: NewQualification) =>
                    this.store.dispatch(new OperatorActions.AddPilotsCompetency(profile.id, operationScenario, profile.pilotId as string))
                ),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperatorState.pilotCompetencyUpdateError);
                if (error) {
                    this.operatorProfileUpdateHelperService.showActionErrorMessage(
                        OperatorActionType.UpdatePilotCompetency,
                        QualificationChangeType.Add
                    );

                    return;
                }
                dialogRef.close();
                this.operatorProfileUpdateHelperService.showActionSuccessMessage(
                    OperatorActionType.UpdatePilotCompetency,
                    QualificationChangeType.Add
                );
                this.store.dispatch(new OperatorActions.SelectOperator(profile.id, OperatorType.Personal));
            });
    }

    private openAddOperationalAuthorizationDialog(availableOperationScenarios: OperationScenario[], profile: OperatorDetails) {
        const dialogRef = this.dialogService.open(AddOperationalAuthorizationComponent, {
            data: {
                availableOperationScenarios,
            },
        });

        dialogRef.componentInstance.newValue$
            .pipe(
                switchMap((newOperationalAuthorizations: NewOperationalAuthorization[]) =>
                    this.store.dispatch(new OperatorActions.AddOperationalAuthorization(profile.id, newOperationalAuthorizations))
                ),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperatorState.operationalAuthorizationsError);
                if (error) {
                    this.operatorProfileUpdateHelperService.showActionErrorMessage(
                        OperatorActionType.UpdateOperationalAuthorization,
                        QualificationChangeType.Add
                    );

                    return;
                }
                dialogRef.close();
                this.operatorProfileUpdateHelperService.showActionSuccessMessage(
                    OperatorActionType.UpdateOperationalAuthorization,
                    QualificationChangeType.Add
                );
                this.store.dispatch(new OperatorActions.SelectOperator(profile.id, profile.type));
            });
    }
}
