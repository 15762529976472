import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";
import { AuthenticationHttpInterceptor, SharedAuthModule } from "@dtm-frontend/shared/auth";
import { SharedNotificationsModule } from "@dtm-frontend/shared/notifications";
import { FILES_UPLOAD_API_PROVIDER, SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import {
    ConvertToCentimetersPipe,
    ConvertToGramsPipe,
    ConvertToKmPerHPipe,
    ConvertToMillisecondsPipe,
    GlobalHttpErrorsInterceptor,
    NotFoundHttpInterceptor,
} from "@dtm-frontend/shared/utils";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { NgxMaskModule } from "ngx-mask";
import { AddressDetailsComponent } from "./components/address-details/address-details.component";
import { AssociationIdDetailsControlComponent } from "./components/association-id-details-control/association-id-details-control.component";
import { InsurancePolicyEditComponent } from "./components/insurance-policy-edit/insurance-policy-edit.component";
import { OperationsManualGuideComponent } from "./components/operations-manual-guide/operations-manual-guide.component";
import { OperatorQualificationsComponent } from "./components/operator-qualifications/operator-qualifications.component";
import { PilotOperatorEmptyCardComponent } from "./components/pilot-operator-empty-card/pilot-operator-empty-card.component";
import { PilotOperatorOtherInformationTileComponent } from "./components/pilot-operator-other-information-tile/pilot-operator-other-information-tile.component";
import { PilotOperatorQualificationsComponent } from "./components/pilot-operator-qualifications/pilot-operator-qualifications.component";
import { TermsOfServiceAcceptanceComponent } from "./components/terms-of-service-acceptance/terms-of-service-acceptance.component";
import { NotificationsComponent } from "./notifications/notifications/notifications.component";
import { OperatorContextButtonComponent } from "./operator-context/components/operator-context-button/operator-context-button.component";
import { GlobalCapabilitiesApiService } from "./operator-context/services/user-data-api.service";
import { OperatorContextState } from "./operator-context/state/operator-context.state";
import { DisplayStreetPipe } from "./pipes/display-street.pipe";
import { FalsyDashPipe } from "./pipes/falsy-dash.pipe";
import { TinPipe } from "./pipes/tin.pipe";
import { FilesManagementApiMock } from "./services/files-management/files-management-api.mock";
import { FilesManagementApiService } from "./services/files-management/files-management-api.service";

@NgModule({
    imports: [
        SharedI18nModule,
        NgxsModule.forFeature([OperatorContextState]),
        MatMenuModule,
        LetModule,
        PushModule,
        CommonModule,
        SharedNotificationsModule,
        SharedUiModule,
        MatProgressSpinnerModule,
        MatCardModule,
        MatTooltipModule,
        ReactiveFormsModule,
        SharedAuthModule,
        MatDatepickerModule,
        MatInputModule,
        NgxMaskModule.forRoot(),
        MatSlideToggleModule,
        FormsModule,
        MatTabsModule,
    ],
    declarations: [
        ConvertToKmPerHPipe,
        ConvertToCentimetersPipe,
        ConvertToMillisecondsPipe,
        ConvertToGramsPipe,
        OperatorContextButtonComponent,
        NotificationsComponent,
        OperatorQualificationsComponent,
        FalsyDashPipe,
        DisplayStreetPipe,
        AddressDetailsComponent,
        InsurancePolicyEditComponent,
        TinPipe,
        AssociationIdDetailsControlComponent,
        PilotOperatorQualificationsComponent,
        PilotOperatorEmptyCardComponent,
        OperationsManualGuideComponent,
        PilotOperatorOtherInformationTileComponent,
        TermsOfServiceAcceptanceComponent,
    ],
    exports: [
        ConvertToKmPerHPipe,
        ConvertToCentimetersPipe,
        ConvertToMillisecondsPipe,
        ConvertToGramsPipe,
        OperatorContextButtonComponent,
        NotificationsComponent,
        OperatorQualificationsComponent,
        FalsyDashPipe,
        DisplayStreetPipe,
        InsurancePolicyEditComponent,
        AddressDetailsComponent,
        TinPipe,
        AssociationIdDetailsControlComponent,
        PilotOperatorQualificationsComponent,
        PilotOperatorEmptyCardComponent,
        OperationsManualGuideComponent,
        PilotOperatorOtherInformationTileComponent,
        TermsOfServiceAcceptanceComponent,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmWebAppLibShared",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: GlobalCapabilitiesApiService,
            useValue: undefined,
        },
        {
            provide: FILES_UPLOAD_API_PROVIDER,
            useClass: FilesManagementApiMock,
        },
    ],
})
export class SharedWebAppModule {
    public static forRoot(): ModuleWithProviders<SharedWebAppModule> {
        return {
            ngModule: SharedWebAppModule,
            providers: [
                {
                    provide: GlobalCapabilitiesApiService,
                    useClass: GlobalCapabilitiesApiService,
                },
                {
                    provide: FILES_UPLOAD_API_PROVIDER,
                    useClass: FilesManagementApiService,
                },
                { provide: HTTP_INTERCEPTORS, useClass: AuthenticationHttpInterceptor, multi: true },
                { provide: HTTP_INTERCEPTORS, useClass: NotFoundHttpInterceptor, multi: true },
                { provide: HTTP_INTERCEPTORS, useClass: GlobalHttpErrorsInterceptor, multi: true },
            ],
        };
    }

    public static forTest(): ModuleWithProviders<SharedWebAppModule> {
        return {
            ngModule: SharedWebAppModule,
            providers: [
                {
                    provide: GlobalCapabilitiesApiService,
                    useValue: null,
                },
            ],
        };
    }
}
