import { UavClass } from "@dtm-frontend/shared/mission";
import { Page, PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM, UavType } from "@dtm-frontend/shared/ui";

export enum UavErrorType {
    Unknown = "Unknown",
    CannotGetUavs = "CannotGetUavs",
}

export interface UavError {
    type: UavErrorType;
}

export interface UavListFilterParams {
    [PAGE_NUMBER_QUERY_PARAM]?: number;
    [PAGE_SIZE_QUERY_PARAM]?: number;
    serialNumber?: string;
    operatorNumber?: string;
}

export interface UavFilters {
    serialNumber: string;
    operatorNumber: string;
}

export interface UavsWithPages {
    content: UavListItem[];
    pagination: Page;
}

export interface UavListItem {
    id: string;
    operatorId: string;
    operatorNumber: string;
    ownerName: string;
    manufacturerAndModel: string;
    type: UavType;
    serialNumbers: string[];
    isCustom: boolean;
    isSwarm: boolean;
    uavClasses: UavClass[];
}
