<ng-container
    *ngrxLet="{
        threadList: threadList$,
        storedAvatarPictures: storedAvatarPictures$,
        assignees: assignees$,
        processingThreadsAssignments: processingThreadsAssignments$,
        areFiltersApplied: areFiltersApplied$,
        pagination: pagination$
    } as vm"
>
    <ng-container *ngIf="vm.threadList?.length; else emptyListTemplate">
        <mat-card class="no-box-shadow">
            <table [dataSource]="vm.threadList" multiTemplateDataRows mat-table class="dtm-table">
                <ng-container matColumnDef="icon">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let thread">
                        <dtm-ui-thread-list-item-icon
                            [isAssigned]="!!thread.assignee"
                            [isRead]="thread.isRead"
                            [isClosed]="thread.isClosed"
                        ></dtm-ui-thread-list-item-icon>
                    </td>
                </ng-container>
                <ng-container matColumnDef="owner">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dtmAdminLibConversation.threadsContainer.operatorsListOwnerHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let thread">
                        <span class="text-ellipsis-operator"
                            >{{ thread.stakeholder.owner.firstName }} {{ thread.stakeholder.owner.lastName }}</span
                        >
                    </td>
                </ng-container>
                <ng-container matColumnDef="operator">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dtmAdminLibConversation.threadsContainer.operatorsListOperatorHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let thread">
                        <span class="text-ellipsis-operator">{{ thread.stakeholder.name }}</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="subject">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dtmAdminLibConversation.threadsContainer.operatorsListSubjectHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let thread">
                        <span class="text-ellipsis">{{ thread.name }}</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="deliveryDate">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dtmAdminLibConversation.threadsContainer.operatorsListDeliveryDateHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let thread">
                        {{ thread.modifiedAt | messageDateDisplay }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="assignment">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dtmAdminLibConversation.threadsContainer.operatorsListDeliveryAssignmentHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let thread">
                        <dtm-admin-lib-threads-list-assignee-change
                            [storedAvatarPictures]="vm.storedAvatarPictures"
                            [assignees]="vm.assignees"
                            [thread]="thread"
                            [processingThreadIds]="vm.processingThreadsAssignments"
                            (assigneeChange)="assigneeAssign.emit({ assignee: $event, thread: thread.id })"
                            (click)="$event.stopPropagation()"
                        >
                        </dtm-admin-lib-threads-list-assignee-change>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <ng-container matColumnDef="noResults">
                    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                        <dtm-ui-no-results [areFiltersApplied]="areFiltersApplied$ | ngrxPush"></dtm-ui-no-results>
                    </td>
                </ng-container>
                <ng-container matColumnDef="pagination">
                    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                        <dtm-ui-pagination
                            *ngIf="pagination$ | ngrxPush as pagination"
                            [pagination]="pagination"
                            (page)="pageChange.emit($event)"
                        >
                        </dtm-ui-pagination>
                    </td>
                </ng-container>
                <tr
                    [routerLink]="['/messages', thread.stakeholder.id]"
                    [queryParams]="{ threadId: thread.id }"
                    class="thread-row"
                    [class.unread]="!thread.isRead && !thread.isClosed"
                    mat-row
                    *matRowDef="let thread; columns: displayedColumns"
                ></tr>
                <tr
                    mat-footer-row
                    *matFooterRowDef="['pagination']"
                    [class.hide-footer]="!vm.threadList.length"
                    class="pagination-row"
                ></tr>
            </table>
        </mat-card>
    </ng-container>

    <ng-template #emptyListTemplate
        ><dtm-ui-no-results [areFiltersApplied]="areFiltersApplied$ | ngrxPush"></dtm-ui-no-results
    ></ng-template>
</ng-container>
