export enum OperatorStatus {
    Approved = "APPROVED",
    PendingApproval = "PENDING_APPROVAL",
    ApprovalInProgress = "APPROVAL_IN_PROGRESS",
    Suspended = "SUSPENDED",
    Deregistered = "DEREGISTERED",
}

export enum OperationScenarioCategory {
    Open = "OPEN",
    Specific = "SPECIFIC",
    Certified = "CERTIFIED",
}

export enum QualificationStatus {
    Active = "ACTIVE",
    Suspended = "SUSPENDED",
    Withdrawn = "WITHDRAWN",
}

export interface OperatorAndPilotQualification extends Omit<BasicQualification, "status"> {
    id: string;
    status: QualificationStatus;
}

export interface BasicQualification {
    name: string;
    category: OperationScenarioCategory;
    expirationDate: Date;
    status?: QualificationStatus;
    statusReason?: string;
}
