import { ChangeDetectionStrategy, Component, Inject, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import {
    Alpha3CountryCode,
    InvalidFormScrollableDirective,
    PhoneNumber,
    requiredValidForSmsPhoneNumberValidator,
} from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import {
    DateUtils,
    HOURS_IN_DAY,
    MILLISECONDS_IN_DAY,
    MIN_DATE_OF_BIRTH,
    ONLY_WHITE_SPACES_VALIDATION_PATTERN,
} from "@dtm-frontend/shared/utils";
import { Observable, Subject, map, startWith, timer } from "rxjs";
import { UtmUserBasicData } from "../../services/utm-users.models";

const MAX_NAME_LENGTH = 100;

interface UtmUserEditDialogData {
    user: UtmUserBasicData;
    minUserAge: number;
    isProcessing$: Observable<boolean>;
}

interface UserForm {
    firstName: FormControl<string | null>;
    lastName: FormControl<string | null>;
    email: FormControl<string | null>;
    phoneNumber: FormControl<PhoneNumber | null>;
    nationality: FormControl<Alpha3CountryCode | null>;
    dateOfBirth: FormControl<string | Date | null>;
}

@Component({
    selector: "dtm-admin-lib-utm-user-edit-basic-data-dialog",
    templateUrl: "./utm-user-edit-basic-data-dialog.component.html",
    styleUrls: ["./utm-user-edit-basic-data-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UtmUserEditBasicDataDialogComponent {
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;

    private readonly newValueSubject = new Subject<UtmUserBasicData>();
    public readonly newValue$ = this.newValueSubject.asObservable();
    protected readonly userForm = new FormGroup<UserForm>({
        firstName: new FormControl<string | null>(null, [
            Validators.required,
            Validators.maxLength(MAX_NAME_LENGTH),
            Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
        ]),
        lastName: new FormControl<string | null>(null, [
            Validators.required,
            Validators.maxLength(MAX_NAME_LENGTH),
            Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
        ]),
        email: new FormControl<string | null>(null, [Validators.required, Validators.email]),
        phoneNumber: new FormControl<PhoneNumber | null>(null, requiredValidForSmsPhoneNumberValidator),
        nationality: new FormControl<Alpha3CountryCode | null>(null, Validators.required),
        dateOfBirth: new FormControl<string | null>(null, Validators.required),
    });
    protected readonly maxDateOfBirth$ = this.prepareMaxDateOfBirthObservable();
    protected readonly MIN_DATE_OF_BIRTH = MIN_DATE_OF_BIRTH;
    protected readonly datePickerPlaceholder$ = this.translocoHelper.datePickerPlaceholder$;

    constructor(
        @Inject(MAT_DIALOG_DATA) protected readonly data: UtmUserEditDialogData,
        private readonly translocoHelper: TranslationHelperService
    ) {
        this.userForm.setValue(data.user);
    }

    protected save() {
        if (this.userForm.invalid) {
            this.userForm.markAllAsTouched();
            this.invalidFormScrollable.scrollToFirstInvalidField();

            return;
        }
        let values = this.userForm.value as UtmUserBasicData;
        const dateOfBirthValue = this.userForm.controls.dateOfBirth.value;
        if (dateOfBirthValue) {
            values = {
                ...values,
                dateOfBirth: this.convertToISOString(dateOfBirthValue),
            };
        }

        this.newValueSubject.next(values);
    }

    private convertToISOString(value: Date | string): string {
        return value instanceof Date ? DateUtils.getISOStringDate(value.toISOString()) : value;
    }

    private prepareMaxDateOfBirthObservable() {
        const now = new Date();
        const nextMidnight = new Date();
        nextMidnight.setHours(HOURS_IN_DAY, 0, 0, 0);
        const millisecondsUntilNextDay = nextMidnight.getTime() - now.getTime();

        return timer(millisecondsUntilNextDay, MILLISECONDS_IN_DAY).pipe(
            startWith(null),
            map(() => {
                const maxDateOfBirth = new Date();
                maxDateOfBirth.setFullYear(maxDateOfBirth.getFullYear() - this.data.minUserAge);
                maxDateOfBirth.setHours(0, 0, 0, 0);

                return maxDateOfBirth;
            })
        );
    }
}
