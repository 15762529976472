import { Injectable } from "@angular/core";
import { ButtonTheme, ConfirmationDialogConfig, OperatorStatus, QualificationStatus } from "@dtm-frontend/shared/ui";
import { TranslocoService } from "@jsverse/transloco";
import { ToastrService } from "ngx-toastr";
import {
    OperatorActionType,
    OperatorStatusChange,
    OperatorType,
    QualificationChangeType,
    QualificationStatusChange,
} from "../../shared/models/operator.models";

@Injectable()
export class OperatorProfileUpdateHelperService {
    constructor(private readonly toastrService: ToastrService, private readonly translocoService: TranslocoService) {}

    public showActionSuccessMessage(actionType: OperatorActionType, changeType?: QualificationChangeType) {
        switch (actionType) {
            case OperatorActionType.UpdatePilotCompetency:
                this.toastrService.success(
                    this.translocoService.translate(
                        "dtmAdminLibOperator.operatorProfile.pilotCompetencyUpdate.successfullyChangedCompetencyMessage",
                        {
                            changeType,
                        }
                    )
                );
                break;
            case OperatorActionType.SuspendAllPilotCompetencies:
                this.toastrService.success(
                    this.translocoService.translate("dtmAdminLibOperator.operatorProfile.pilotCompetenciesSuspendAll.successMessage")
                );
                break;
            case OperatorActionType.UpdateOperationalAuthorization:
                this.toastrService.success(
                    this.translocoService.translate(
                        "dtmAdminLibOperator.operatorProfile.operationalAuthorizationsUpdate.successfullyChangedOperationalAuthorization",
                        { changeType }
                    )
                );
                break;
            case OperatorActionType.ChangeStatus:
                this.toastrService.success(
                    this.translocoService.translate("dtmAdminLibOperator.operatorProfile.operatorStatus.statusChangeSuccessMessage")
                );
                break;
            case OperatorActionType.UpdateAttorneyPower:
                this.toastrService.success(
                    this.translocoService.translate("dtmAdminLibOperator.operatorProfile.attorneyPowerUpdate.updateSuccessMessage")
                );
                break;
            case OperatorActionType.AddOtherInformation:
                this.toastrService.success(
                    this.translocoService.translate(
                        "dtmAdminLibOperator.operatorProfile.otherInformationChange.addOtherInformationSuccessMessage"
                    )
                );
                break;
            case OperatorActionType.EditOtherInformation:
                this.toastrService.success(
                    this.translocoService.translate(
                        "dtmAdminLibOperator.operatorProfile.otherInformationChange.editOtherInformationSuccessMessage"
                    )
                );
                break;
            case OperatorActionType.DeleteOtherInformation:
                this.toastrService.success(
                    this.translocoService.translate(
                        "dtmAdminLibOperator.operatorProfile.otherInformationChange.deleteOtherInformationSuccessMessage"
                    )
                );
                break;
            case OperatorActionType.DeleteOperator:
                this.toastrService.success(
                    this.translocoService.translate("dtmAdminLibOperator.operatorProfile.removeOperator.deleteOperatorSuccessMessage")
                );
                break;
            default:
                break;
        }
    }

    public showActionErrorMessage(actionType: OperatorActionType, changeType?: QualificationChangeType) {
        switch (actionType) {
            case OperatorActionType.UpdatePilotCompetency:
                this.toastrService.error(
                    this.translocoService.translate(
                        "dtmAdminLibOperator.operatorProfile.pilotCompetencyUpdate.competencyChangeErrorMessage",
                        {
                            changeType,
                        }
                    )
                );
                break;
            case OperatorActionType.SuspendAllPilotCompetencies:
                this.toastrService.error(
                    this.translocoService.translate("dtmAdminLibOperator.operatorProfile.pilotCompetenciesSuspendAll.errorMessage")
                );
                break;
            case OperatorActionType.UpdateOperationalAuthorization:
                this.toastrService.error(
                    this.translocoService.translate(
                        "dtmAdminLibOperator.operatorProfile.operationalAuthorizationsUpdate.operationalAuthorizationChangeErrorMessage",
                        { changeType }
                    )
                );
                break;
            case OperatorActionType.ChangeStatus:
                this.toastrService.error(
                    this.translocoService.translate("dtmAdminLibOperator.operatorProfile.operatorStatus.errorWhileStatusChangeHint")
                );
                break;
            case OperatorActionType.UpdateAttorneyPower:
                this.toastrService.error(
                    this.translocoService.translate("dtmAdminLibOperator.operatorProfile.attorneyPowerUpdate.updateErrorMessage")
                );
                break;
            case OperatorActionType.AddOtherInformation:
                this.toastrService.error(
                    this.translocoService.translate(
                        "dtmAdminLibOperator.operatorProfile.otherInformationChange.cannotAddOtherInformationError"
                    )
                );
                break;
            case OperatorActionType.EditOtherInformation:
                this.toastrService.error(
                    this.translocoService.translate(
                        "dtmAdminLibOperator.operatorProfile.otherInformationChange.cannotEditOtherInformationError"
                    )
                );
                break;
            case OperatorActionType.DeleteOtherInformation:
                this.toastrService.error(
                    this.translocoService.translate(
                        "dtmAdminLibOperator.operatorProfile.otherInformationChange.cannotDeleteOtherInformationError"
                    )
                );
                break;
            case OperatorActionType.DeleteOperator:
                this.toastrService.error(
                    this.translocoService.translate("dtmAdminLibOperator.operatorProfile.removeOperator.cannotDeleteOperatorError")
                );
                break;
            default:
                break;
        }
    }

    public getQualificationChangeType(qualification: QualificationStatusChange) {
        switch (qualification.status) {
            case QualificationStatus.Active: {
                return QualificationChangeType.Activate;
            }
            case QualificationStatus.Withdrawn: {
                return QualificationChangeType.Withdraw;
            }
            case QualificationStatus.Suspended: {
                return QualificationChangeType.Suspend;
            }
        }
    }

    public getChangeStatusConfirmationDialogConfig(newStatusValue: OperatorStatusChange): ConfirmationDialogConfig {
        return {
            titleText: this.translocoService.translate("dtmAdminLibOperator.operatorProfile.operatorStatus.changeStatusTitleText"),
            confirmationHtml: this.getChangeStatusConfirmationHtml(newStatusValue),
            confirmButtonLabel: this.translocoService.translate(
                "dtmAdminLibOperator.operatorProfile.operatorStatus.changeStatusConfirmationConfirmLabel"
            ),
            declineButtonLabel: this.translocoService.translate(
                "dtmAdminLibOperator.operatorProfile.operatorStatus.changeStatusConfirmationRejectLabel"
            ),
            theme: newStatusValue.newStatus === OperatorStatus.Deregistered ? ButtonTheme.Warn : ButtonTheme.Primary,
        };
    }

    private getChangeStatusConfirmationHtml({ newStatus, operator }: OperatorStatusChange): string {
        let confirmationTextKey = "dtmAdminLibOperator.operatorProfile.operatorStatus.changeStatusConfirmationHtml";
        if (newStatus === OperatorStatus.Deregistered) {
            if (operator.type === OperatorType.Personal) {
                confirmationTextKey = "dtmAdminLibOperator.operatorProfile.operatorStatus.changeStatusDeregisteredPersonalConfirmationHtml";
            } else {
                confirmationTextKey =
                    "dtmAdminLibOperator.operatorProfile.operatorStatus.changeStatusDeregisteredNotPersonalConfirmationHtml";
            }
        }

        return this.translocoService.translate(confirmationTextKey, {
            operatorName: operator.name,
            statusLabel: this.translocoService.translate("dtmAdminLibOperator.operatorProfile.operatorStatus.operatorStatusLabel", {
                value: newStatus,
            }),
        });
    }
}
