<div class="form-wrapper" [formGroup]="groundRiskForm">
    <dtm-ui-select-field
        formControlName="groundAreaCharacteristic"
        [placeholder]="'dtmAdminLibPermits.groundRiskCharacteristic.chooseLabel' | transloco"
    >
        <label>{{ "dtmAdminLibPermits.groundRiskCharacteristic.groundAreaCharacteristicLabel" | transloco }}</label>
        <dtm-ui-select-option [value]="area" *ngFor="let area of groundAreaCharacteristicOptions$ | ngrxPush">
            {{ "dtmAdminLibPermits.groundRiskCharacteristic.groundAreaSelectValue" | transloco : { value: area } }}
        </dtm-ui-select-option>
        <div
            class="field-error"
            *dtmUiFieldHasError="groundRiskForm.controls.groundAreaCharacteristic; name: ['required']"
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>
    </dtm-ui-select-field>
    <dtm-ui-select-field
        *ngIf="groundRiskForm.controls.controlledGroundAreaCharacteristic.enabled"
        @slideIn
        formControlName="controlledGroundAreaCharacteristic"
        [placeholder]="'dtmAdminLibPermits.groundRiskCharacteristic.chooseLabel' | transloco"
    >
        <label>{{ "dtmAdminLibPermits.groundRiskCharacteristic.controlledGroundAreaCharacteristicLabel" | transloco }}</label>
        <dtm-ui-select-option [value]="area" *ngFor="let area of controlledGroundAreaCharacteristicOptions$ | ngrxPush">
            {{ "dtmAdminLibPermits.groundRiskCharacteristic.controlledGroundAreaSelectValue" | transloco : { value: area } }}
        </dtm-ui-select-option>
        <div
            class="field-error"
            *dtmUiFieldHasError="groundRiskForm.controls.controlledGroundAreaCharacteristic; name: ['required']"
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>
    </dtm-ui-select-field>
    <dtm-ui-select-field
        formControlName="finalGroundRiskClass"
        [placeholder]="'dtmAdminLibPermits.groundRiskCharacteristic.chooseLabel' | transloco"
    >
        <label>{{ "dtmAdminLibPermits.groundRiskCharacteristic.finalGroundRiskClassLabel" | transloco }}</label>
        <dtm-ui-select-option [value]="grc" *ngFor="let grc of FINAL_GROUND_RISK_CLASS_OPTIONS">
            {{ grc | grcLevel }}
        </dtm-ui-select-option>
        <div
            class="field-error"
            *dtmUiFieldHasError="groundRiskForm.controls.finalGroundRiskClass; name: ['required']"
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>
    </dtm-ui-select-field>
    <dtm-ui-select-field
        formControlName="adjacentGroundAreaCharacteristic"
        [placeholder]="'dtmAdminLibPermits.groundRiskCharacteristic.chooseLabel' | transloco"
    >
        <label>{{ "dtmAdminLibPermits.groundRiskCharacteristic.adjacentGroundAreaLabel" | transloco }}</label>
        <dtm-ui-select-option [value]="area" *ngFor="let area of adjacentGroundAreaCharacteristicOptions$ | ngrxPush">
            {{ "dtmAdminLibPermits.groundRiskCharacteristic.groundAreaSelectValue" | transloco : { value: area } }}
        </dtm-ui-select-option>
        <div
            class="field-error"
            *dtmUiFieldHasError="groundRiskForm.controls.adjacentGroundAreaCharacteristic; name: ['required']"
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>
    </dtm-ui-select-field>
    <dtm-ui-input-field [isClearable]="false">
        <label>{{ "dtmAdminLibPermits.groundRiskCharacteristic.adjacentGroundAreaExtentLabel" | transloco }}</label>
        <input matInput type="number" formControlName="adjacentGroundAreaExtent" />
        <div class="field-suffix">{{ "dtmAdminLibPermits.groundRiskCharacteristic.adjacentGroundAreaExtentInputSuffix" | transloco }}</div>
        <div class="field-error" *dtmUiFieldHasError="groundRiskForm.controls.adjacentGroundAreaExtent; name: 'max'">
            {{
                "dtmAdminLibPermits.groundRiskCharacteristic.maxAdjacentGroundAreaExtentErrorHint"
                    | transloco
                        : {
                              max: MAX_ADJACENT_GROUND_AREA_EXTENT,
                              unit: "dtmAdminLibPermits.groundRiskCharacteristic.adjacentGroundAreaExtentInputSuffix" | transloco
                          }
            }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="groundRiskForm.controls.adjacentGroundAreaExtent; name: 'min'">
            {{
                "dtmAdminLibPermits.groundRiskCharacteristic.minAdjacentGroundAreaExtentErrorHint"
                    | transloco
                        : {
                              min: MIN_ADJACENT_GROUND_AREA_EXTENT,
                              unit: "dtmAdminLibPermits.groundRiskCharacteristic.adjacentGroundAreaExtentInputSuffix" | transloco
                          }
            }}
        </div>
    </dtm-ui-input-field>
</div>
<ng-template #requiredErrorTemplate>
    {{ "dtmAdminLibPermits.container.requiredField" | transloco }}
</ng-template>
