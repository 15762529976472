import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { DialogService, Page } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { UavListItem } from "../../services/uav.models";
import { SerialNumbersDialogComponent } from "../serial-numbers-dialog/serial-numbers-dialog.component";

interface UavListComponentState {
    isProcessing: boolean;
    uavs: UavListItem[];
    pagination: Page | undefined;
    expandedElement: UavListItem | undefined;
}

const MAX_SERIAL_NUMBER_CHARACTERS_DISPLAYED = 30;
const MAX_OPERATOR_NUMBER_CHARACTERS_DISPLAYED = 50;

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-uav-list",
    templateUrl: "./uav-list.component.html",
    styleUrls: ["./uav-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.slideInAnimation()],
    providers: [LocalComponentStore],
})
export class UavListComponent {
    @Input({ required: true })
    public set uavs(value: UavListItem[] | undefined) {
        this.localStore.patchState({ uavs: value ?? [] });
    }

    @Input()
    public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input()
    public set pagination(value: Page | undefined) {
        this.localStore.patchState({ pagination: value });
    }

    @Output() public uavListRefresh = new EventEmitter<void>();
    @Output() public pageChange = new EventEmitter<PageEvent>();

    protected readonly expandedElement$ = this.localStore.selectByKey("expandedElement");
    protected readonly uavs$ = this.localStore.selectByKey("uavs");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly pagination$ = this.localStore.selectByKey("pagination");
    protected readonly displayedColumns = ["expandHandle", "serialNumber", "manufacturerAndModel", "uavClass", "operatorNumber"];
    protected readonly MAX_SERIAL_NUMBER_CHARACTERS_DISPLAYED = MAX_SERIAL_NUMBER_CHARACTERS_DISPLAYED;
    protected readonly MAX_OPERATOR_NUMBER_CHARACTERS_DISPLAYED = MAX_OPERATOR_NUMBER_CHARACTERS_DISPLAYED;

    constructor(private readonly localStore: LocalComponentStore<UavListComponentState>, private readonly dialogService: DialogService) {
        this.localStore.setState({
            uavs: [],
            isProcessing: false,
            pagination: undefined,
            expandedElement: undefined,
        });
    }

    protected toggleExpandableRow(row: UavListItem): void {
        this.localStore.patchState((state) => ({
            expandedElement: state.expandedElement === row ? undefined : row,
        }));
    }

    protected showSerialNumbers(uav: UavListItem) {
        return this.dialogService.open(SerialNumbersDialogComponent, {
            data: {
                serialNumbers: uav.serialNumbers,
            },
        });
    }
}
