import { ChangeDetectionStrategy, Component, ContentChildren, forwardRef, QueryList } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { MAT_LEGACY_RADIO_GROUP } from "@angular/material/legacy-radio";
import { _MatRadioGroupBase } from "@angular/material/radio";
import { RadioTileComponent } from "../radio-tile/radio-tile.component";

@Component({
    selector: "dtm-ui-radio-tile-group",
    templateUrl: "./radio-tile-group.component.html",
    styleUrls: ["./radio-tile-group.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        role: "radiogroup",
    },
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioTileGroupComponent),
            multi: true,
        },
        { provide: MAT_LEGACY_RADIO_GROUP, useExisting: RadioTileGroupComponent },
    ],
})
export class RadioTileGroupComponent extends _MatRadioGroupBase<RadioTileComponent> {
    @ContentChildren(forwardRef(() => RadioTileComponent), { descendants: true }) public _radios!: QueryList<RadioTileComponent>;
}
