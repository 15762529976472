import { ChangeDetectionStrategy, Component, forwardRef } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import { AdditionalAccidentModel } from "@dtm-frontend/shared/ui";
import { FunctionUtils, ONLY_WHITE_SPACES_VALIDATION_PATTERN } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

const MAX_OPERATIONS_MANUAL_REFERENCE_LENGTH = 50;

interface AdditionalAccidentForm {
    hasInsurance: FormControl<boolean | null>;
    operationsManualReference: FormControl<string>;
    additionalLimitations: FormControl<string>;
}

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-additional-accident-type-form",
    templateUrl: "./additional-accident-type-form.component.html",
    styleUrls: ["../../styles/shared-permit-styles.scss", "./additional-accident-type-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AdditionalAccidentTypeFormComponent), multi: true },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => AdditionalAccidentTypeFormComponent),
            multi: true,
        },
    ],
})
export class AdditionalAccidentTypeFormComponent implements ControlValueAccessor, Validator {
    protected readonly additionalAccidentForm = new FormGroup<AdditionalAccidentForm>({
        hasInsurance: new FormControl(null, Validators.required),
        operationsManualReference: new FormControl("", {
            validators: [
                Validators.required,
                Validators.maxLength(MAX_OPERATIONS_MANUAL_REFERENCE_LENGTH),
                Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
            ],
            nonNullable: true,
        }),
        additionalLimitations: new FormControl("", {
            nonNullable: true,
        }),
    });

    constructor() {
        this.additionalAccidentForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            this.propagateChange(this.additionalAccidentForm.getRawValue() as AdditionalAccidentModel);
        });
    }

    private propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: AdditionalAccidentModel) => void = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    public registerOnChange(fn: (value: AdditionalAccidentModel) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public writeValue(value: AdditionalAccidentModel): void {
        if (value) {
            this.additionalAccidentForm.reset(
                {
                    hasInsurance: value.hasInsurance,
                    operationsManualReference: value.operationsManualReference,
                    additionalLimitations: value.additionalLimitations,
                },
                { emitEvent: false }
            );
        } else {
            this.additionalAccidentForm.reset();
        }
    }

    public validate(): ValidationErrors | null {
        if (this.additionalAccidentForm.valid) {
            return null;
        }

        return { invalidFinalRiskArea: true };
    }
}
