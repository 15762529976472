<form [formGroup]="localSpatialInfoFormGroup">
    <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControl]="isLocalSpatialInfoForDtmEnabledZoneControl">
        <div class="toggle-container heading">
            <dtm-ui-icon name="community"></dtm-ui-icon>
            <p>
                {{ "dtmMapCesium.localSpatialInfoForDTM.titleLabel" | transloco }}
            </p>
        </div>
    </mat-slide-toggle>
    <dtm-ui-expandable-panel [isExpanded]="false" [hasHeaderSeparator]="false">
        <p headerSlot>{{ "dtmMapCesium.displayDetailsLabel" | transloco }}</p>

        <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControlName]="InfoZoneType.PermanentGathering">
            <p class="toggle-content">
                <dtm-ui-icon name="complex-team-fill"></dtm-ui-icon>
                <span class="label">{{ "dtmMapCesium.localSpatialInfoForDTM.continuesPeopleAssembliesLabel" | transloco }}</span>
            </p>
        </mat-slide-toggle>

        <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControlName]="InfoZoneType.TemporaryGathering">
            <p class="toggle-content">
                <dtm-ui-icon name="complex-team-fill-less"></dtm-ui-icon>
                <span class="label">{{ "dtmMapCesium.localSpatialInfoForDTM.temporalPeopleAssembliesLabel" | transloco }}</span>
            </p>
        </mat-slide-toggle>

        <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControlName]="InfoZoneType.Obstacles">
            <p class="toggle-content">
                <dtm-ui-icon name="barricade-fill"></dtm-ui-icon>
                <span class="label">{{ "dtmMapCesium.localSpatialInfoForDTM.obstaclesLabel" | transloco }}</span>
            </p>
        </mat-slide-toggle>

        <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControlName]="InfoZoneType.Landings">
            <p class="toggle-content">
                <dtm-ui-icon name="hems-emr"></dtm-ui-icon>
                <span class="label">{{ "dtmMapCesium.localSpatialInfoForDTM.emrLandingsSitesLabel" | transloco }}</span>
            </p>
        </mat-slide-toggle>

        <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControlName]="InfoZoneType.AdsbCoverMap">
            <p class="toggle-content">
                <dtm-ui-icon name="signal-tower-fill"></dtm-ui-icon>
                <span class="label">{{ "dtmMapCesium.localSpatialInfoForDTM.adsbCoverageMapsLabel" | transloco }}</span>
            </p>
        </mat-slide-toggle>

        <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControlName]="InfoZoneType.OtherInformation">
            <p class="toggle-content">
                <dtm-ui-icon name="stack-fill"></dtm-ui-icon>
                <span class="label">{{ "dtmMapCesium.localSpatialInfoForDTM.otherLabel" | transloco }}</span>
            </p>
        </mat-slide-toggle>
    </dtm-ui-expandable-panel>
</form>
