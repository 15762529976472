<ng-container *ngrxLet="filtersCount$ as filtersCount">
    <dtm-ui-filters-container [filtersCount]="filtersCount">
        <div class="filters-content">
            <dtm-ui-input-field>
                <label>{{ "dtmAdminLibUav.uavListFilters.serialNumberLabel" | transloco }}</label>
                <input
                    class="control-search"
                    matInput
                    type="text"
                    autocomplete="off"
                    [formControl]="filtersFormGroup.controls.serialNumber"
                />
                <dtm-ui-icon name="search" class="field-prefix"></dtm-ui-icon>
            </dtm-ui-input-field>
            <dtm-ui-input-field>
                <label>{{ "dtmAdminLibUav.uavListFilters.operatorNumberLabel" | transloco }}</label>
                <input
                    class="control-search"
                    matInput
                    type="text"
                    autocomplete="off"
                    [formControl]="filtersFormGroup.controls.operatorNumber"
                />
                <dtm-ui-icon name="search" class="field-prefix"></dtm-ui-icon>
            </dtm-ui-input-field>
        </div>
    </dtm-ui-filters-container>
    <dtm-ui-filter-chips-display
        [filtersMap]="FILTERS_MAP"
        [filtersCount]="filtersCount"
        [formGroup]="filtersFormGroup"
        [filtersValue]="filtersFormGroup.value"
        (allFiltersReset)="clearFilters()"
    >
    </dtm-ui-filter-chips-display>
</ng-container>
