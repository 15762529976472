import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { DefaultValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, NgControl } from "@angular/forms";
import { TimeRange } from "@dtm-frontend/shared/ui";
import { LocalizeDatePipe } from "@dtm-frontend/shared/ui/i18n";
import {
    AnimationUtils,
    DAYS_IN_WEEK,
    DateUtils,
    FormType,
    HOURS_IN_DAY,
    LocalComponentStore,
    MILLISECONDS_IN_DAY,
} from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { Observable, combineLatestWith, defer, distinctUntilChanged, filter, map, pairwise, startWith } from "rxjs";
import { AUP_START_TIME_HOUR, TimeSettingOptions, ZoneTimesSetting } from "../../../../../geo-zones/models/geo-zones.models";

interface GeographicalZonesControlsComponentState {
    zoneHeightSetting: ZoneHeightSetting;
    aupMode: AirspaceUsePlanSettings;
    timeRange:
        | {
              min: number;
              max: number;
          }
        | undefined;
    missionTimeRange: TimeRange | undefined;
    timeRangeSetting: TimeRangeSetting;
    timeSettingsOptions: TimeSettingOptions | undefined;
    aupEndTime: Date | undefined;
}

export interface GeoZonesFilters {
    zoneHeight: number | null;
    timeFrom: Date | null;
    timeTo: Date | null;
    shouldIncludeTemporaryZones: boolean;
}

/* eslint-disable no-magic-numbers */
enum ZoneHeightSetting {
    "H500" = 500,
    "H750" = 750,
    "H1000" = 1000,
    "Custom" = "Custom",
}
/* eslint-enable no-magic-numbers */

enum AirspaceUsePlanSettings {
    Current = "Current",
    Next = "Next",
}

enum TimeRangeSetting {
    OneWeek = 1,
    TwoWeeks = 2,
    // eslint-disable-next-line no-magic-numbers
    FourWeeks = 4,
}

const SLIDER_SETTINGS = {
    ticks: 4,
    subTicks: 7,
};

@UntilDestroy()
@Component({
    selector: "dtm-map-geographical-zones-filters",
    templateUrl: "./geographical-zones-filters.component.html",
    styleUrls: ["./geographical-zones-filters.component.scss", "../geographical-zones-common.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useClass: DefaultValueAccessor,
            multi: true,
        },
    ],
    animations: [AnimationUtils.slideInAnimation()],
})
export class GeographicalZonesFiltersComponent implements OnInit {
    @Input() public set missionTimeRange(value: TimeRange | undefined) {
        this.localStore.patchState({ missionTimeRange: value });
    }
    @Input() public set timeSettingsOptions(value: TimeSettingOptions | undefined) {
        this.localStore.patchState({ timeSettingsOptions: value });
        this.zoneTimesSettingFormControl.setValue(
            value?.default ?? value?.available.find((setting) => setting !== ZoneTimesSetting.MissionTime) ?? null
        );
    }
    @Input() public set aupEndTime(value: Date | undefined) {
        this.localStore.patchState({ aupEndTime: value });
    }

    @Output() public watchZonesSettingUpdate: EventEmitter<boolean> = new EventEmitter<boolean>();

    protected readonly AirspaceUsePlanSettings = AirspaceUsePlanSettings;
    protected readonly SLIDER_SETTINGS = SLIDER_SETTINGS;
    protected readonly step = MILLISECONDS_IN_DAY / HOURS_IN_DAY;
    protected readonly ZoneHeightSetting = ZoneHeightSetting;
    protected readonly ZoneTimesSetting = ZoneTimesSetting;
    protected readonly TimeRangeSetting = TimeRangeSetting;

    protected readonly customHeightSettingFormControl = new FormControl<number | null>(null);
    protected readonly heightSettingFormControl = new FormControl<number | null>(ZoneHeightSetting.H500);
    protected readonly timeFromFormControl = new FormControl<Date | null>(null);
    protected readonly timeToFormControl = new FormControl<Date | null>(null);
    protected readonly zoneTimesSettingFormControl = new FormControl<ZoneTimesSetting | null>(null);
    protected readonly shouldIncludeTemporaryZonesFormControl = new FormControl<boolean>(false, { nonNullable: true });

    @Output() public selectedZoneTimeSettingUpdate = this.zoneTimesSettingFormControl.valueChanges;

    protected readonly geoZoneFiltersFormGroup = new FormGroup<FormType<GeoZonesFilters>>({
        zoneHeight: this.heightSettingFormControl,
        timeFrom: this.timeFromFormControl,
        timeTo: this.timeToFormControl,
        shouldIncludeTemporaryZones: this.shouldIncludeTemporaryZonesFormControl,
    });

    protected readonly aupFromFormControl = new FormControl<number | null>(null);
    protected readonly aupToFormControl = new FormControl<number | null>(null);

    protected readonly aupTimeRangeFormGroup = new FormGroup({
        aupFrom: this.aupFromFormControl,
        aupTo: this.aupToFormControl,
    });
    protected readonly aupTimeRangeFormGroupValue$ = defer(() =>
        this.aupTimeRangeFormGroup.valueChanges.pipe(startWith(this.aupTimeRangeFormGroup.value))
    );

    protected readonly aupMode$ = this.localStore.selectByKey("aupMode");
    protected readonly missionTimeRange$: Observable<TimeRange> = this.localStore
        .selectByKey("missionTimeRange")
        .pipe(distinctUntilChanged(equal));
    protected readonly timeRange$ = this.localStore.selectByKey("timeRange");
    protected readonly zoneHeightSetting$ = this.localStore.selectByKey("zoneHeightSetting");
    protected readonly timeRangeSetting$ = this.localStore.selectByKey("timeRangeSetting");
    protected readonly timeSettingsOptions$ = this.localStore.selectByKey("timeSettingsOptions");
    protected readonly availableTimeSettings$ = this.timeSettingsOptions$.pipe(map((options) => options?.available ?? []));
    protected readonly doesNextAupExist$ = this.localStore
        .selectByKey("aupEndTime")
        .pipe(map((aupEndTime) => this.doesNextAupExist(aupEndTime)));

    protected readonly zoneTimesSetting$ = defer(() =>
        this.zoneTimesSettingFormControl.valueChanges.pipe(startWith(this.zoneTimesSettingFormControl.value))
    );

    private timePipe: LocalizeDatePipe;

    constructor(
        private readonly localStore: LocalComponentStore<GeographicalZonesControlsComponentState>,
        @Inject(NgControl) private readonly ngControl: NgControl
    ) {
        this.timePipe = new LocalizeDatePipe();
        const { minTime, maxTime } = this.getCurrentAupTimeRange();

        localStore.setState({
            zoneHeightSetting: ZoneHeightSetting.H500,
            aupMode: AirspaceUsePlanSettings.Current,
            timeRange: {
                min: minTime,
                max: maxTime,
            },
            missionTimeRange: undefined,
            timeRangeSetting: TimeRangeSetting.OneWeek,
            timeSettingsOptions: undefined,
            aupEndTime: undefined,
        });
    }

    public ngOnInit(): void {
        this.customHeightSettingFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            this.localStore.patchState({ zoneHeightSetting: value ? ZoneHeightSetting.Custom : ZoneHeightSetting.H500 });
            this.heightSettingFormControl.setValue(value ?? ZoneHeightSetting.H500);
        });

        this.geoZoneFiltersFormGroup.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => this.ngControl.control?.setValue(value));

        this.watchAupSetting();
        this.watchMissionTimeSettings();
        this.watchTimeSettings();
        this.watchAupEndTimeChanges();

        const timeRange = this.localStore.selectSnapshotByKey("timeRange");

        if (!timeRange) {
            return;
        }

        this.updateAupTimes(new Date(timeRange.min), new Date(timeRange.max));
    }

    protected updateAupTimes(min: Date, max: Date) {
        this.aupTimeRangeFormGroup.setValue({
            aupFrom: DateUtils.selectLatestFromDates(min, DateUtils.roundDownToFullHour(new Date())).getTime(),
            aupTo: max.getTime(),
        });
    }

    private watchMissionTimeSettings() {
        this.missionTimeRange$.pipe(pairwise(), untilDestroyed(this)).subscribe(([previous, current]) => {
            const timeSettingsOptions = this.localStore.selectSnapshotByKey("timeSettingsOptions");

            if ((!previous && current) || timeSettingsOptions?.available.includes(ZoneTimesSetting.MissionTime)) {
                this.zoneTimesSettingFormControl.setValue(ZoneTimesSetting.MissionTime);
            }

            if (!current && previous) {
                this.zoneTimesSettingFormControl.setValue(
                    timeSettingsOptions?.default && timeSettingsOptions?.default !== ZoneTimesSetting.MissionTime
                        ? timeSettingsOptions.default
                        : timeSettingsOptions?.available.find((setting) => setting !== ZoneTimesSetting.MissionTime) ?? null
                );
            }
        });

        this.zoneTimesSettingFormControl.valueChanges
            .pipe(combineLatestWith(this.missionTimeRange$), untilDestroyed(this))
            .subscribe(([zoneTimeSettings, missionTimeRange]) => {
                if (!missionTimeRange || zoneTimeSettings !== ZoneTimesSetting.MissionTime) {
                    return;
                }

                this.geoZoneFiltersFormGroup.patchValue({
                    timeFrom: missionTimeRange.min,
                    timeTo: missionTimeRange.max,
                });
            });
    }

    protected readonly tickLabelFormatter = (value: string | number) => this.timePipe.transform(new Date(value), { timeStyle: "short" });

    protected setAupMode(value: AirspaceUsePlanSettings) {
        this.localStore.patchState({ aupMode: value });
    }

    protected setZoneHeightSetting(value: ZoneHeightSetting) {
        this.localStore.patchState({ zoneHeightSetting: value });
        if (value === ZoneHeightSetting.Custom) {
            return;
        }
        this.heightSettingFormControl.setValue(value);
        this.customHeightSettingFormControl.reset(null, { emitEvent: false });
    }

    protected setTimeRangeSetting(value: TimeRangeSetting) {
        this.localStore.patchState({ timeRangeSetting: value });

        const currentAupStartTime = new Date();
        currentAupStartTime.setUTCHours(AUP_START_TIME_HOUR, 0, 0, 0);

        if (currentAupStartTime.getUTCHours() < AUP_START_TIME_HOUR) {
            currentAupStartTime.setDate(currentAupStartTime.getDate() - 1);
        }

        this.geoZoneFiltersFormGroup.patchValue({
            timeFrom: currentAupStartTime,
            timeTo: DateUtils.addDays(currentAupStartTime, DAYS_IN_WEEK * value),
            shouldIncludeTemporaryZones: false,
        });
    }

    protected doesNextAupExist(aupEndTime: Date | undefined): boolean {
        if (!aupEndTime) {
            return false;
        }

        const currentAupEndTime = new Date();
        currentAupEndTime.setUTCHours(AUP_START_TIME_HOUR, 0, 0, 0);

        // NOTE: If the current time is after new AUP start time it means that the current AUP end time is tomorrow
        if (new Date().getUTCHours() > AUP_START_TIME_HOUR) {
            currentAupEndTime.setDate(currentAupEndTime.getDate() + 1);
        }

        return aupEndTime.getTime() > currentAupEndTime.getTime();
    }

    protected getNextDay(time: number): Date {
        return new Date(time + MILLISECONDS_IN_DAY);
    }

    private getCurrentAupTimeRange() {
        const aupStartDate = new Date();
        aupStartDate.setUTCHours(AUP_START_TIME_HOUR, 0, 0, 0);

        // NOTE: If the current time is before new AUP start time it means that the AUP started yesterday
        if (new Date().getUTCHours() < AUP_START_TIME_HOUR) {
            aupStartDate.setDate(aupStartDate.getDate() - 1);
        }

        const minTime = aupStartDate.getTime();
        const maxTime = new Date(minTime + MILLISECONDS_IN_DAY).getTime();

        return { minTime, maxTime };
    }

    private watchAupSetting() {
        this.aupTimeRangeFormGroup.valueChanges
            .pipe(combineLatestWith(this.aupMode$, this.zoneTimesSetting$), untilDestroyed(this))
            .subscribe(([{ aupFrom, aupTo }, aupMode, zoneTimeSettings]) => {
                if (
                    zoneTimeSettings !== ZoneTimesSetting.Soon ||
                    ![AirspaceUsePlanSettings.Next, AirspaceUsePlanSettings.Current].includes(aupMode) ||
                    !aupFrom ||
                    !aupTo
                ) {
                    return;
                }

                if (aupMode === AirspaceUsePlanSettings.Current) {
                    this.geoZoneFiltersFormGroup.patchValue({
                        timeFrom: new Date(aupFrom),
                        timeTo: new Date(aupTo),
                    });

                    return;
                }

                this.geoZoneFiltersFormGroup.patchValue({
                    timeFrom: new Date(aupFrom + MILLISECONDS_IN_DAY),
                    timeTo: new Date(aupTo + MILLISECONDS_IN_DAY),
                });
            });
    }

    private watchTimeSettings() {
        this.updateTimeSettings(this.zoneTimesSettingFormControl.value);

        this.zoneTimesSettingFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => this.updateTimeSettings(value));
    }

    private updateTimeSettings(value: ZoneTimesSetting | null) {
        if (value === ZoneTimesSetting.CustomTimeRange) {
            this.setTimeRangeSetting(this.localStore.selectSnapshotByKey("timeRangeSetting"));
            this.watchZonesSettingUpdate.emit(false);

            return;
        }

        if (value === ZoneTimesSetting.Current) {
            this.geoZoneFiltersFormGroup.patchValue({
                timeFrom: new Date(),
                timeTo: DateUtils.addHours(new Date(), 1),
                shouldIncludeTemporaryZones: true,
            });
            this.watchZonesSettingUpdate.emit(true);
        } else {
            this.geoZoneFiltersFormGroup.patchValue({
                shouldIncludeTemporaryZones: false,
            });
            this.watchZonesSettingUpdate.emit(false);
        }
    }

    private watchAupEndTimeChanges() {
        this.localStore
            .selectByKey("aupEndTime")
            .pipe(
                distinctUntilChanged(
                    (previousAupEndTime, currentAupEndTime) => previousAupEndTime?.getTime() === currentAupEndTime?.getTime()
                ),
                filter(
                    (aupEndTime) =>
                        this.localStore.selectSnapshotByKey("aupMode") === AirspaceUsePlanSettings.Next &&
                        !this.doesNextAupExist(aupEndTime)
                ),
                untilDestroyed(this)
            )
            .subscribe(() => this.localStore.patchState({ aupMode: AirspaceUsePlanSettings.Current }));
    }
}
