<div class="form-wrapper" [formGroup]="airRiskMitigationForm">
    <dtm-ui-select-field
        formControlName="strategicMitigationMeasure"
        [placeholder]="'dtmAdminLibPermits.airRiskMitigation.chooseLabel' | transloco"
    >
        <label>{{ "dtmAdminLibPermits.airRiskMitigation.airRiskStrategicMitigationMeasuresLabel" | transloco }}</label>
        <dtm-ui-select-option [value]="risc.value" *ngFor="let risc of AirRiskMitigationType | keyvalue">
            {{ "dtmAdminLibPermits.airRiskMitigation.airRiskMitigationMeasureValueLabel" | transloco : { value: risc.value } }}
        </dtm-ui-select-option>
        <div
            class="field-error"
            *dtmUiFieldHasError="airRiskMitigationForm.controls.strategicMitigationMeasure; name: ['required']"
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>
    </dtm-ui-select-field>
    <dtm-ui-select-field
        formControlName="tacticalAirRiskMitigationMeasure"
        [placeholder]="'dtmAdminLibPermits.airRiskMitigation.chooseLabel' | transloco"
    >
        <label>{{ "dtmAdminLibPermits.airRiskMitigation.tacticalAirRiskMitigationMeasuresLabel" | transloco }}</label>
        <p class="select-field-option-header">
            {{ "dtmAdminLibPermits.airRiskMitigation.tacticalAirRiskMitigationMeasuresHeader" | transloco : { type: MissionType.VLOS } }}
        </p>
        <dtm-ui-select-option [value]="TacticalAirRiskMitigationType.VlosSeeAndAvoid">
            {{
                "dtmAdminLibPermits.airRiskMitigation.tacticalRiskMitigationMeasureValueLabel"
                    | transloco : { value: TacticalAirRiskMitigationType.VlosSeeAndAvoid }
            }}
        </dtm-ui-select-option>
        <p class="select-field-option-header">
            {{ "dtmAdminLibPermits.airRiskMitigation.tacticalAirRiskMitigationMeasuresHeader" | transloco : { type: MissionType.BVLOS } }}
        </p>
        <dtm-ui-select-option [value]="risc" *ngFor="let risc of bvlosTacticalRiskMitigationMeasures">
            {{ "dtmAdminLibPermits.airRiskMitigation.tacticalRiskMitigationMeasureValueLabel" | transloco : { value: risc } }}
        </dtm-ui-select-option>

        <div
            class="field-error"
            *dtmUiFieldHasError="airRiskMitigationForm.controls.tacticalAirRiskMitigationMeasure; name: ['required']"
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>
    </dtm-ui-select-field>
    <dtm-ui-textarea-field [maxLength]="MAX_TACTICAL_AIR_RISK_MITIGATION_REQUIREMENTS_LENGTH">
        <label>{{ "dtmAdminLibPermits.airRiskMitigation.tacticalAirRiskMitigationRequirementsLabel" | transloco }}</label>
        <textarea formControlName="tacticalAirRiskMitigationRequirements" matInput></textarea>
        <div
            class="field-error"
            *dtmUiFieldHasError="airRiskMitigationForm.controls.tacticalAirRiskMitigationRequirements; name: 'maxlength'; error as error"
        >
            {{ "dtmAdminLibPermits.airRiskMitigation.valueLongerThanMaxLengthErrorHint" | transloco : { max: error.requiredLength } }}
        </div>
    </dtm-ui-textarea-field>
    <dtm-admin-lib-containment-level-reached-control formControlName="containmentReachedLevel">
        <label>{{ "dtmAdminLibPermits.airRiskMitigation.reachedLevelLabel" | transloco }}</label>
        <div
            class="field-error"
            *dtmUiFieldHasError="airRiskMitigationForm.controls.containmentReachedLevel; name: ['required']"
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>
    </dtm-admin-lib-containment-level-reached-control>

    <ng-container *ngIf="!(shouldShowCompetenciesAsDescription$ | ngrxPush); else competenciesAsDescriptionTemplate">
        <dtm-ui-select-field [multiple]="true" [formControl]="airRiskMitigationForm.controls.competencies">
            <label>{{ "dtmAdminLibPermits.airRiskMitigation.pilotCompetenciesLabel" | transloco }}</label>
            <dtm-ui-select-option *ngFor="let competency of competencies$ | ngrxPush" [value]="competency">
                {{ competency.name }}
            </dtm-ui-select-option>
            <div
                class="field-error"
                *dtmUiFieldHasError="airRiskMitigationForm.controls.competencies; name: ['required']"
                [ngTemplateOutlet]="requiredErrorTemplate"
            ></div>
        </dtm-ui-select-field>
        <dtm-ui-additional-competencies
            formControlName="additionalCompetencies"
            [label]="'dtmAdminLibPermits.airRiskMitigation.additionalPilotCompetenciesLabel' | transloco"
        ></dtm-ui-additional-competencies>
        <dtm-ui-additional-competencies
            formControlName="staffCompetencies"
            [label]="'dtmAdminLibPermits.airRiskMitigation.staffCompetenciesLabel' | transloco"
        ></dtm-ui-additional-competencies>
    </ng-container>
    <ng-template #competenciesAsDescriptionTemplate>
        <dtm-ui-textarea-field [maxLength]="MAX_COMPETENCIES_DESCRIPTION_LENGTH">
            <label>{{ "dtmAdminLibPermits.airRiskMitigation.pilotCompetenciesDescriptionLabel" | transloco }}</label>
            <textarea formControlName="competenciesDescription" matInput></textarea>
            <div
                class="field-error"
                *dtmUiFieldHasError="airRiskMitigationForm.controls.competenciesDescription; name: 'maxlength'; error as error"
            >
                {{ "dtmAdminLibPermits.airRiskMitigation.valueLongerThanMaxLengthErrorHint" | transloco : { max: error.requiredLength } }}
            </div>
        </dtm-ui-textarea-field>
        <dtm-ui-textarea-field [maxLength]="MAX_COMPETENCIES_DESCRIPTION_LENGTH">
            <label>{{ "dtmAdminLibPermits.airRiskMitigation.staffCompetenciesLabel" | transloco }}</label>
            <textarea formControlName="staffCompetenciesDescription" matInput></textarea>
            <div
                class="field-error"
                *dtmUiFieldHasError="airRiskMitigationForm.controls.staffCompetenciesDescription; name: 'maxlength'; error as error"
            >
                {{ "dtmAdminLibPermits.airRiskMitigation.valueLongerThanMaxLengthErrorHint" | transloco : { max: error.requiredLength } }}
            </div>
        </dtm-ui-textarea-field>
    </ng-template>
</div>
<ng-template #requiredErrorTemplate>
    {{ "dtmAdminLibPermits.airRiskMitigation.requiredField" | transloco }}
</ng-template>
