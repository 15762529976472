<dtm-ui-loader-container
    [isShown]="vm.isProcessing"
    *ngrxLet="{
        isProcessing: isProcessing$,
        missionData: missionData$,
        route: route$,
        analysisStatus: analysisStatus$,
        isAnalysisAvailable: isAnalysisAvailable$,
        soraSettings: soraSettings$,
        routeData: routeData$,
        isMeasureToolActive: isMeasureToolActive$,
        isSoraApplication: isSoraApplication$,
        caaPermitData: caaPermitData$,
    } as vm"
>
    <div class="data-container">
        <div class="card-box scroll-shadows">
            <div class="slot-container" #headerSlotContent>
                <ng-content select="[headerSlot]"></ng-content>
            </div>
            <div class="default-header" *ngIf="headerSlotContent | isEmptyElement">
                <h2>{{ "dtmSharedMission.missionPreview.header" | transloco }}</h2>
            </div>
            <section>
                <!-- SIDE 1 -->
                <div class="slot-container" #side1SlotContent>
                    <ng-content select="[side1Slot]"></ng-content>
                </div>
                <div *ngIf="!side1SlotContent.hasChildNodes() && (side1SlotContent | isEmptyElement)">
                    <dtm-mission-basic-details
                        [startDate]="vm.missionData.flightStartAtMin"
                        [finishDate]="vm.missionData.flightFinishAtMax"
                        [plannedStartTime]="vm.missionData.plannedFlightStart"
                        [plannedFinishTime]="vm.missionData.plannedFlightEnd"
                        [phase]="vm.missionData.phase"
                        [missionType]="vm.missionData.flightType"
                        [isAuthorityAcceptationConfirmed]="isAuthorityAcceptationConfirmed$ | ngrxPush"
                        [isWholeDayMode]="vm.isSoraApplication"
                        (zoom)="flyToMissionRoute()"
                        (messageShow)="openMessageFromManager()"
                    ></dtm-mission-basic-details>
                </div>

                <!-- SIDE 2 -->
                <div class="slot-container" #side2SlotContent>
                    <ng-content select="[side2Slot]"></ng-content>
                </div>
                <ng-container *ngIf="!side2SlotContent.hasChildNodes() && (side2SlotContent | isEmptyElement)">
                    <dtm-mission-mission-messages
                        [messages]="vm.missionData.timeOverrideJustification ? [{ text: vm.missionData.timeOverrideJustification }] : []"
                    ></dtm-mission-mission-messages>
                    <dtm-mission-flight-requirements
                        [zones]="collisionZones$ | ngrxPush"
                        [remarks]="vm.missionData.remarks"
                        [currentPlanAnalysisStatus]="vm.analysisStatus"
                        [isProcessing]="isFlightRequirementsProcessing$ | ngrxPush"
                        [selectedZoneId]="selectedZoneId$ | ngrxPush"
                        [formalJustification]="vm.missionData.remarks.justification"
                        [missionContext]="MissionContextType.Supervisor"
                        [rejectionJustification]="vm.missionData.rejectionJustification"
                        [rejectionDate]="vm.missionData.rejectedAt"
                        [missionPhase]="vm.missionData.phase"
                        [isUtmSupervisorIntegrated]="isUtmSupervisorIntegrated$ | ngrxPush"
                        (zoneSelect)="selectZone($event)"
                        (messageFromManagerReadingConfirmed)="messageFromManagerReadingConfirmed.emit($event)"
                    ></dtm-mission-flight-requirements>
                </ng-container>

                <!-- SIDE 3 -->
                <div class="slot-container" #side3SlotContent>
                    <ng-content select="[side3Slot]"></ng-content>
                </div>
                <dtm-mission-mission-details
                    *ngIf="!side3SlotContent.hasChildNodes() && (side3SlotContent | isEmptyElement)"
                    [mission]="missionDataSimple$ | ngrxPush"
                    [isExpanded]="false"
                >
                    <dtm-ui-expandable-simple-header
                        headerSlot
                        [label]="'dtmSharedMission.missionPreview.missionDetailsHeader' | transloco"
                    >
                    </dtm-ui-expandable-simple-header>
                </dtm-mission-mission-details>

                <!-- SIDE 4 -->
                <div class="slot-container" #side4SlotContent>
                    <ng-content select="[side4Slot]"></ng-content>
                </div>
                <dtm-mission-traffic-analysis
                    *ngIf="!side4SlotContent.hasChildNodes() && (side4SlotContent | isEmptyElement) && vm.analysisStatus?.traffic"
                    [nearbyMissions]="nearbyMissions$ | ngrxPush"
                    [collisionMissions]="collisionMission$ | ngrxPush"
                    [isProcessing]="isTrafficAnalysisProcessing$ | ngrxPush"
                    [isExpanded]="false"
                    (flyToRoute)="flyToOtherMissionRoute($event)"
                    (flyToMainMissionRoute)="flyToMissionRoute()"
                    (otherMissionSelectedId)="selectOtherMission($event)"
                ></dtm-mission-traffic-analysis>

                <!-- SIDE 5 -->
                <div class="slot-container" #side5SlotContent>
                    <ng-content select="[side5Slot]"></ng-content>
                </div>
                <dtm-ui-expandable-panel
                    *ngIf="!side5SlotContent.hasChildNodes() && (side5SlotContent | isEmptyElement)"
                    [hasHeaderSeparator]="false"
                >
                    <dtm-ui-expandable-simple-header headerSlot [label]="'dtmSharedMission.missionPreview.routeDetailsHeader' | transloco">
                    </dtm-ui-expandable-simple-header>
                    <dtm-ui-route-details [route]="vm.route"></dtm-ui-route-details>
                </dtm-ui-expandable-panel>

                <!-- SIDE 6 -->
                <div class="slot-container" #side6SlotContent>
                    <ng-content select="[side6Slot]"></ng-content>
                </div>
                <dtm-mission-operational-area-geometry
                    *ngIf="!side6SlotContent.hasChildNodes() && (side6SlotContent | isEmptyElement) && vm.isAnalysisAvailable"
                    [operationalGeometryData]="vm.caaPermitData?.operationalGeometry"
                    [isExpanded]="false"
                ></dtm-mission-operational-area-geometry>

                <!-- SIDE 7 -->
                <div class="slot-container" #side7SlotContent>
                    <ng-content select="[side7Slot]"></ng-content>
                </div>
                <dtm-ui-expandable-panel
                    *ngIf="!side7SlotContent.hasChildNodes() && (side7SlotContent | isEmptyElement) && vm.isAnalysisAvailable"
                    [hasHeaderSeparator]="false"
                    [isExpanded]="false"
                >
                    <dtm-ui-expandable-simple-header headerSlot [label]="'dtmSharedMission.missionPreview.groundRiskHeader' | transloco">
                    </dtm-ui-expandable-simple-header>
                    <dtm-mission-population-density
                        [populationDensity]="vm.analysisStatus?.sora?.result?.populationDensity"
                    ></dtm-mission-population-density>
                </dtm-ui-expandable-panel>

                <!-- SIDE 8 -->
                <div class="slot-container" #side8SlotContent>
                    <ng-content select="[side8Slot]"></ng-content>
                </div>
                <dtm-mission-operational-area-airspace-risk
                    *ngIf="!side8SlotContent.hasChildNodes() && (side8SlotContent | isEmptyElement) && vm.isAnalysisAvailable"
                    [airRiskMitigations]="vm.soraSettings?.airRiskMitigations"
                    [airRisk]="vm.analysisStatus?.sora?.result?.airRisk"
                    [isExpanded]="false"
                >
                </dtm-mission-operational-area-airspace-risk>

                <!-- SIDE 9 -->
                <div class="slot-container" #side9SlotContent>
                    <ng-content select="[side9Slot]"></ng-content>
                </div>
                <dtm-mission-initial-sora-issues
                    *ngIf="!side9SlotContent.hasChildNodes() && (side9SlotContent | isEmptyElement) && vm.isAnalysisAvailable"
                    [isExpanded]="false"
                    [isControlledGroundArea]="!!vm.soraSettings?.controlledGroundAreaComment"
                    [soraResult]="vm.analysisStatus?.sora?.result"
                    [airspaceAnalysis]="vm.analysisStatus?.airspace"
                >
                </dtm-mission-initial-sora-issues>

                <!-- SIDE 10 -->
                <div class="slot-container" #side10SlotContent>
                    <ng-content select="[side10Slot]"></ng-content>
                </div>
                <dtm-mission-risk-mitigation-measures
                    *ngIf="
                        !side10SlotContent.hasChildNodes() &&
                        (side10SlotContent | isEmptyElement) &&
                        vm.isAnalysisAvailable &&
                        vm.soraSettings?.riskMitigations
                    "
                    [airRisk]="vm.analysisStatus?.sora?.result?.airRisk"
                    [soraSettings]="vm.soraSettings"
                    [isExpanded]="false"
                >
                </dtm-mission-risk-mitigation-measures>

                <!-- SIDE 11 -->
                <div class="slot-container" #side11SlotContent>
                    <ng-content select="[side11Slot]"></ng-content>
                </div>
                <dtm-mission-tactical-risk-mitigation-measures
                    *ngIf="
                        !side11SlotContent.hasChildNodes() &&
                            (side11SlotContent | isEmptyElement) &&
                            vm.isAnalysisAvailable &&
                            isTacticalMitigationPerformanceRequirementsPanelVisible$ | ngrxPush
                    "
                    [tacticalMitigationPerformanceRequirementProperty]="
                        vm.analysisStatus?.sora?.result?.tacticalMitigationPerformanceRequirementProperty
                    "
                    [tacticalMitigationPerformanceRequirements]="vm.soraSettings?.tmpr"
                    [isExpanded]="false"
                >
                </dtm-mission-tactical-risk-mitigation-measures>

                <!-- SIDE 12 -->
                <div class="slot-container" #side12SlotContent>
                    <ng-content select="[side12Slot]"></ng-content>
                </div>
                <dtm-mission-final-sora-issues
                    *ngIf="!side12SlotContent.hasChildNodes() && (side12SlotContent | isEmptyElement) && vm.isAnalysisAvailable"
                    [soraResult]="vm.analysisStatus?.sora?.result"
                    [isExpanded]="false"
                >
                </dtm-mission-final-sora-issues>

                <!-- SIDE 13 -->
                <div class="slot-container" #side13SlotContent>
                    <ng-content select="[side13Slot]"></ng-content>
                </div>
                <dtm-mission-adjacent-area-and-space
                    *ngIf="!side13SlotContent.hasChildNodes() && (side13SlotContent | isEmptyElement) && vm.isAnalysisAvailable"
                    [soraResult]="vm.analysisStatus?.sora?.result"
                    [airspaceAnalysis]="vm.analysisStatus?.airspace"
                    [technicalSpecification]="vm.caaPermitData?.technicalSpecification"
                    [isExpanded]="false"
                >
                </dtm-mission-adjacent-area-and-space>

                <!-- SIDE rest -->
                <ng-content select="[sideRestSlot]"></ng-content>
            </section>
        </div>
    </div>
    <div class="visualization-container">
        <div class="map-container">
            <div class="slot-container" #mapSlotContent>
                <ng-content select="[mapSlot]"></ng-content>
            </div>
            <ng-container *ngIf="mapSlotContent | isEmptyElement">
                <ac-map [sceneMode]="SceneMode.SCENE3D" *ngIf="initialViewBox$ | ngrxPush" dtmMapPointerManager>
                    <ng-content select="[mapLayersSlot]"></ng-content>
                    <dtm-map-azure-maps-layer [options]="AZURE_MAPS_LAYER_OPTIONS"></dtm-map-azure-maps-layer>
                    <dtm-map-route-viewer-layer
                        [routeData]="vm.routeData"
                        [drawableFeatures]="routeDrawableFeature"
                        [nearbyMissionsDrawableFeatures]="nearbyMissionsRouteDrawableFeature"
                        [isShown]="true"
                        [shouldDisplayTimeRange]="!vm.isSoraApplication"
                        (entityZoom)="flyToGeometry($event)"
                    ></dtm-map-route-viewer-layer>
                    <div class="layers-controls">
                        <dtm-map-layers-with-controls
                            [missionTimeRange]="missionTimeRange$ | ngrxPush"
                            [shouldShowAreaBuffers]="vm.missionData.category?.type !== MissionCategory.Open"
                            [routes]="vm.routeData | invoke : getRoutesFromRouteData"
                            [timeSettingOptions]="{ available: [ZoneTimesSetting.MissionTime], default: ZoneTimesSetting.MissionTime }"
                        ></dtm-map-layers-with-controls>
                        <dtm-map-measures-cesium [isMeasureEnabled]="vm.isMeasureToolActive"></dtm-map-measures-cesium>
                    </div>
                </ac-map>
                <dtm-map-controls
                    [isMeasureToolEnabled]="isMeasureToolEnabled$ | ngrxPush"
                    [isMeasureToolActive]="vm.isMeasureToolActive"
                    (measureToolStatusChange)="updateMeasureToolStatus($event)"
                ></dtm-map-controls>
            </ng-container>
        </div>

        <div class="side-view">
            <div class="slot-container" #sideViewSlotContent>
                <ng-content select="[sideViewSlot]"></ng-content>
            </div>
            <dtm-mission-route-side-view
                *ngIf="sideViewSlotContent | isEmptyElement"
                [route]="vm.route"
                [isExpanded]="isRouteSideViewInitiallyExpanded$ | ngrxPush"
                [isSimpleMode]="vm.missionData.itineraryEditorType === ItineraryEditorType.Standard"
                [areBuffersVisible]="vm.missionData.flightType === MissionType.BVLOS"
            >
                <h2 headerSlot>
                    {{ "dtmSharedMission.missionPreview.sideViewHeader" | transloco }}
                </h2>
                <ng-container emptyMessageSlot>
                    {{ "dtmSharedMission.missionPreview.routeSideViewEmptyMessage" | transloco }}
                </ng-container>
            </dtm-mission-route-side-view>
        </div>
    </div>
</dtm-ui-loader-container>
