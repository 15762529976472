import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
    AdditionalAccidentModel,
    AirRiskMitigationModel,
    BasicDataModel,
    ButtonTheme,
    CaaContactPerson,
    Competency,
    ConfirmationDialogComponent,
    ContactPerson,
    DialogService,
    DtmLocations,
    FinalRiskAreaModel,
    GroundRiskCharacteristicModel,
    GroundRiskMitigationModel,
    InvalidFormScrollableDirective,
    OperationRestrictionsModel,
    OperationalVolumeHeightModel,
    Operator,
    Permit,
    UploadedFile,
} from "@dtm-frontend/shared/ui";
import { AVIATION_REPORTING_URL, AnimationUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { firstValueFrom } from "rxjs";
import { distinctUntilChanged, tap } from "rxjs/operators";

interface BasicDataForm {
    operator: FormControl<Operator | null>;
    operatorContact: FormControl<ContactPerson | null>;
    operationRestrictions: FormControl<OperationRestrictionsModel | null>;
    groundRiskCharacteristic: FormControl<GroundRiskCharacteristicModel | null>;
    operationalVolumeHeight: FormControl<OperationalVolumeHeightModel | null>;
    finalRiskArea: FormControl<FinalRiskAreaModel | null>;
    groundRiskMitigation: FormControl<GroundRiskMitigationModel | null>;
    airRiskMitigation: FormControl<AirRiskMitigationModel | null>;
    additionalAccidentType: FormControl<AdditionalAccidentModel | null>;
    caaContactUser: FormControl<CaaContactPerson | null>;
}

interface CrossBorderPermitBasicDataComponentState {
    dtmLocations: DtmLocations[] | undefined;
    operators: Operator[] | undefined;
    isFormVisible: boolean;
    competencies: Competency[] | undefined;
    isProcessing: boolean;
    caaUsers: CaaContactPerson[];
    existingPermitDetails: Permit | undefined;
    areUavsAvailable: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-cross-border-permit-basic-data",
    templateUrl: "./cross-border-permit-basic-data.component.html",
    styleUrls: ["../../../styles/shared-permit-styles.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
})
export class CrossBorderPermitBasicDataComponent {
    @Input()
    public set dtmLocations(value: DtmLocations[] | undefined) {
        this.localStore.patchState({ dtmLocations: value });
    }
    @Input()
    public set operators(value: Operator[] | undefined) {
        this.localStore.patchState({ operators: value });
    }
    @Input()
    public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }
    @Input()
    public set competencies(value: Competency[] | undefined) {
        this.localStore.patchState({ competencies: value });
    }
    @Input()
    public set caaUsers(value: CaaContactPerson[] | undefined) {
        this.localStore.patchState({ caaUsers: value ?? [] });
    }
    @Input()
    public set areUavsAvailable(value: BooleanInput) {
        this.localStore.patchState({ areUavsAvailable: coerceBooleanProperty(value) });
    }
    @Input()
    public set existingPermitDetails(value: Permit | undefined) {
        this.localStore.patchState({ existingPermitDetails: value });
        if (value) {
            this.assignExistingPermitDetails(value);
        }
    }

    @Output() public readonly operatorSelect = new EventEmitter<Operator>();
    @Output() public readonly searchTextChanged = new EventEmitter<string>();
    @Output() public readonly basicDataFormSave = new EventEmitter<BasicDataModel>();
    @Output() public readonly kmlFilePreview = new EventEmitter<UploadedFile>();
    @Output() public readonly back = new EventEmitter<void>();

    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;

    protected readonly AVIATION_REPORTING_URL = AVIATION_REPORTING_URL;
    protected readonly dtmLocations$ = this.localStore.selectByKey("dtmLocations");
    protected readonly existingPermitDetails$ = this.localStore.selectByKey("existingPermitDetails");
    protected readonly caaContactUsers$ = this.localStore.selectByKey("caaUsers");
    protected readonly operators$ = this.localStore.selectByKey("operators");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly competencies$ = this.localStore.selectByKey("competencies");
    protected readonly areUavsAvailable$ = this.localStore.selectByKey("areUavsAvailable");
    protected readonly basicDataForm = new FormGroup<BasicDataForm>({
        caaContactUser: new FormControl(null, Validators.required),
        operator: new FormControl(null, Validators.required),
        operationRestrictions: new FormControl(null),
        groundRiskCharacteristic: new FormControl(null),
        operationalVolumeHeight: new FormControl(null),
        finalRiskArea: new FormControl(null),
        groundRiskMitigation: new FormControl(null),
        airRiskMitigation: new FormControl(null),
        additionalAccidentType: new FormControl(null),
        operatorContact: new FormControl(null),
    });

    constructor(
        private readonly localStore: LocalComponentStore<CrossBorderPermitBasicDataComponentState>,
        private readonly dialogService: DialogService,
        private readonly transloco: TranslocoService
    ) {
        this.localStore.setState({
            dtmLocations: undefined,
            competencies: undefined,
            operators: undefined,
            isFormVisible: false,
            isProcessing: false,
            caaUsers: [],
            existingPermitDetails: undefined,
            areUavsAvailable: true,
        });
        this.watchOperatorChange();
    }

    protected async validateFormAndGoToNextStep(): Promise<void> {
        const areUavsAvailable = await firstValueFrom(this.areUavsAvailable$.pipe(RxjsUtils.filterFalsy(), untilDestroyed(this)));

        if (this.basicDataForm.invalid || !areUavsAvailable) {
            this.basicDataForm.markAllAsTouched();
            this.invalidFormScrollable.scrollToFirstInvalidField();

            return;
        }

        this.basicDataFormSave.emit(this.basicDataForm.getRawValue() as BasicDataModel);
    }

    protected caaUserCompare(optionLeft: CaaContactPerson | null, optionRight: CaaContactPerson | null) {
        return optionLeft?.id === optionRight?.id;
    }

    protected goBack() {
        if (!this.basicDataForm.touched) {
            this.back.emit();

            return;
        }

        this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dtmAdminLibPermits.confirmEditionCloseDialog.title"),
                    confirmationText: this.transloco.translate("dtmAdminLibPermits.confirmEditionCloseDialog.contentText"),
                    declineButtonLabel: this.transloco.translate("dtmAdminLibPermits.confirmEditionCloseDialog.cancelButtonLabel"),
                    confirmButtonLabel: this.transloco.translate("dtmAdminLibPermits.confirmEditionCloseDialog.confirmButtonLabel"),
                    theme: ButtonTheme.Warn,
                },
            })
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => this.back.emit());
    }

    private watchOperatorChange(): void {
        this.basicDataForm.controls.operator.valueChanges
            .pipe(
                RxjsUtils.filterFalsy(),
                distinctUntilChanged(equal),
                tap(() => {
                    Object.keys(this.basicDataForm.controls)
                        .filter((key) => key !== "operator" && key !== "caaContactUser")
                        .map((key) => this.basicDataForm.controls[key as keyof BasicDataForm].reset());
                }),
                tap((value) => this.operatorSelect.emit(value)),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private assignExistingPermitDetails(permit: Permit) {
        this.basicDataForm.patchValue({
            caaContactUser: permit.basicData.caaContactUser,
            operator: permit.basicData.operator,
            operatorContact: permit.basicData.operatorContact,
            operationRestrictions: permit.basicData.operationRestrictions,
            groundRiskCharacteristic: permit.basicData.groundRiskCharacteristic,
            operationalVolumeHeight: permit.basicData.operationalVolumeHeight,
            finalRiskArea: permit.basicData.finalRiskArea,
            groundRiskMitigation: permit.basicData.groundRiskMitigation,
            airRiskMitigation: permit.basicData.airRiskMitigation,
            additionalAccidentType: permit.basicData.additionalAccidentType,
        });

        this.basicDataForm.controls.operator.disable();
    }
}
