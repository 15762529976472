export function selectEarlierDate(date?: Date, compareDate?: Date): Date | undefined {
    if (!compareDate || !date) {
        return date;
    }

    return date.getTime() - compareDate.getTime() > 0 ? date : compareDate;
}

export function selectLaterDate(date?: Date, compareDate?: Date): Date | undefined {
    if (!date || isNaN(date.getTime())) {
        return compareDate;
    }
    if (!compareDate || isNaN(compareDate.getTime())) {
        return date;
    }

    return date.getTime() > compareDate.getTime() ? date : compareDate;
}

export function selectLatestFromDates(...dates: Date[]): Date {
    return dates.reduce((result, date) => selectLaterDate(result, date) ?? new Date(NaN), new Date(NaN));
}

export function roundDownToFullHour(date: Date): Date {
    const roundedDate = new Date(date);
    roundedDate.setMinutes(0, 0, 0);

    return roundedDate;
}
