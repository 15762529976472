<ng-container *ngIf="thread$ | ngrxPush as thread">
    <ng-container *ngrxLet="assignees$; let assignees">
        <button
            *ngIf="!thread.isClosed"
            [disabled]="isProcessing$ | ngrxPush"
            type="button"
            class="button-secondary selection-button"
            [matMenuTriggerFor]="assigneesMenu"
        >
            <ng-template
                [ngTemplateOutlet]="selectedAssigneeTemplate"
                [ngTemplateOutletContext]="{ assignee: selectedAssignee$ | ngrxPush, isSelected: true }"
            ></ng-template>
        </button>
        <mat-menu #assigneesMenu="matMenu">
            <ng-container *ngIf="assignees$ | ngrxPush as assignees">
                <button type="button" mat-menu-item (click)="changeAssignee(NULL_ASSIGNEE)">
                    <ng-template [ngTemplateOutlet]="selectedAssigneeTemplate" [ngTemplateOutletContext]="{ assignee: null }"></ng-template>
                </button>
                <button
                    type="button"
                    *ngFor="let availableAssignee of assignees | keyvalue"
                    (click)="changeAssignee(availableAssignee.key)"
                    mat-menu-item
                >
                    <ng-template
                        [ngTemplateOutlet]="selectedAssigneeTemplate"
                        [ngTemplateOutletContext]="{ assignee: availableAssignee.value }"
                    ></ng-template>
                </button>
            </ng-container>
        </mat-menu>
    </ng-container>

    <ng-template #selectedAssigneeTemplate let-assignee="assignee" let-isSelected="isSelected">
        <span
            class="selected-assignee"
            [class.selected]="assignee"
            [class.unread]="!thread.isRead && isSelected"
            *ngIf="assignee; else noAssignmentTemplate"
        >
            <span *ngrxLet="storedAvatarPictures$ as storedAvatarPictures">
                <img *ngIf="assignee.avatarUrl" alt="" [src]="storedAvatarPictures[assignee.avatarUrl]" />
                <span class="name">{{ assignee.firstName }} {{ assignee.lastName }}</span>
            </span>
            <dtm-ui-icon name="edit" *ngIf="isSelected"></dtm-ui-icon>
        </span>

        <ng-template #noAssignmentTemplate>
            <span class="selected-assignee" [class.selected]="isSelected" [class.unread]="!thread.isRead && isSelected">
                <p>{{ "dtmAdminLibConversation.threadsContainer.noAssignmentLabel" | transloco }}</p>
                <dtm-ui-icon name="edit" *ngIf="isSelected"></dtm-ui-icon>
            </span>
        </ng-template>
    </ng-template>
</ng-container>
