import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { AircraftEntity, AircraftType, FlightMissionPhase } from "@dtm-frontend/shared/ui";
import { EmergencyType, Violation } from "@dtm-frontend/shared/ui/tactical";
import { LocalComponentStore, MILLISECONDS_IN_MINUTE } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatestWith } from "rxjs";
import { first, map } from "rxjs/operators";

interface FlightPinComponentState {
    aircraftEntity: AircraftEntity | undefined;
    isInVerificationMode: boolean;
    violation: Violation | undefined;
    name: string | undefined;
    emergency: EmergencyType | undefined;
    isHeightVisible: boolean;
    isInfoVisible: boolean;
    isTrackerSelectionEnabled: boolean;
    phase: FlightMissionPhase | undefined;
    isSelected: boolean;
    customFlightId: string | undefined;
    mainAircraftTrackerIds: string[];
}

// NOTE: Backend will calculate height only for 10 minutes and after that user needs to ask again
const HEIGHT_ACTIVITY_TIME_IN_MINUTES = 10;

const FLIGHT_PHASE_STYLE_CLASS_MAP: Record<FlightMissionPhase, string> = {
    [FlightMissionPhase.Active]: "active",
    [FlightMissionPhase.Started]: "started",
    [FlightMissionPhase.Incoming]: "incoming",
    [FlightMissionPhase.Finished]: "finished",
};

const TRACK_BASE_ROTATION_IN_DEGREES = 45;

@UntilDestroy()
@Component({
    selector: "dtm-map-flight-pin[aircraftEntity]",
    templateUrl: "./flight-pin.component.html",
    styleUrls: ["./flight-pin.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class FlightPinComponent implements OnDestroy {
    @Input()
    public set aircraftEntity(value: AircraftEntity | undefined) {
        this.localStore.patchState({ aircraftEntity: value });
    }
    @Input()
    public set isInVerificationMode(value: BooleanInput) {
        this.localStore.patchState({ isInVerificationMode: coerceBooleanProperty(value) });
    }
    @Input()
    public set violation(value: Violation | undefined) {
        this.localStore.patchState({ violation: value });
    }
    @Input()
    public set name(value: string | undefined) {
        this.localStore.patchState({ name: value });
    }
    @Input()
    public set emergency(value: EmergencyType | undefined) {
        this.localStore.patchState({ emergency: value });
    }
    @Input()
    public set isTrackerSelectionEnabled(value: BooleanInput) {
        this.localStore.patchState({ isTrackerSelectionEnabled: coerceBooleanProperty(value) });
    }
    @Input()
    public set phase(value: FlightMissionPhase | undefined) {
        this.localStore.patchState({ phase: value });
    }
    @Input()
    public set isSelected(value: BooleanInput) {
        this.localStore.patchState({ isSelected: coerceBooleanProperty(value) });
    }
    @Input()
    public set customFlightId(value: string | undefined) {
        this.localStore.patchState({ customFlightId: value });
    }
    @Input()
    public set mainAircraftTrackerIds(value: string[] | undefined) {
        this.localStore.patchState({ mainAircraftTrackerIds: value ?? [] });
    }
    @Output() public readonly flightPositionUpdatesEnrich = new EventEmitter<boolean>();
    @Output() public readonly selectById = new EventEmitter<string | undefined>();
    @Output() public readonly detailsOpen = new EventEmitter<AircraftEntity>();

    protected readonly AircraftType = AircraftType;
    protected readonly EmergencyType = EmergencyType;

    protected readonly aircraftEntity$ = this.localStore.selectByKey("aircraftEntity");
    protected readonly isMainAircraft$ = this.localStore.selectByKey("mainAircraftTrackerIds").pipe(
        combineLatestWith(this.aircraftEntity$),
        map(([ids, entity]) => ids.includes(entity?.trackerIdentifier ?? ""))
    );
    protected readonly isInVerificationMode$ = this.localStore.selectByKey("isInVerificationMode").pipe(
        combineLatestWith(this.isMainAircraft$),
        map(([isInVerificationMode, isMainAircraft]) => isInVerificationMode && isMainAircraft)
    );
    protected readonly violationClass$ = this.localStore.selectByKey("violation").pipe(map(this.mapViolationToClass));
    protected readonly name$ = this.localStore.selectByKey("name");
    protected readonly emergency$ = this.localStore.selectByKey("emergency").pipe(
        combineLatestWith(this.isMainAircraft$),
        map(([emergency, isMainAircraft]) => (isMainAircraft ? emergency : undefined))
    );
    protected readonly isHeightVisible$ = this.localStore.selectByKey("isHeightVisible");
    protected readonly isInfoVisible$ = this.localStore.selectByKey("isInfoVisible");
    protected readonly isTrackerSelectionEnabled$ = this.localStore.selectByKey("isTrackerSelectionEnabled");
    protected readonly phase$ = this.localStore.selectByKey("phase");
    protected readonly isSelected$ = this.localStore.selectByKey("isSelected");

    private heightVisibilityTimer: number | undefined;

    constructor(protected readonly localStore: LocalComponentStore<FlightPinComponentState>) {
        localStore.setState({
            aircraftEntity: undefined,
            isInVerificationMode: false,
            violation: undefined,
            name: undefined,
            emergency: undefined,
            isHeightVisible: false,
            isInfoVisible: false,
            isTrackerSelectionEnabled: false,
            phase: undefined,
            isSelected: false,
            customFlightId: undefined,
            mainAircraftTrackerIds: [],
        });

        this.isMainAircraft$
            .pipe(first(Boolean), untilDestroyed(this))
            .subscribe(() => this.localStore.patchState({ isInfoVisible: true }));
    }

    public ngOnDestroy(): void {
        clearTimeout(this.heightVisibilityTimer);
    }

    protected toggleHeightVisibility(): void {
        const isHeightVisible = this.localStore.selectSnapshotByKey("isHeightVisible");

        if (!isHeightVisible) {
            this.flightPositionUpdatesEnrich.emit(true);

            clearTimeout(this.heightVisibilityTimer);
            this.heightVisibilityTimer = setTimeout(
                () => this.localStore.patchState({ isHeightVisible: false }),
                HEIGHT_ACTIVITY_TIME_IN_MINUTES * MILLISECONDS_IN_MINUTE
            ) as unknown as number;
        }

        this.localStore.patchState({ isHeightVisible: !isHeightVisible });
    }

    protected toggleInfoVisibility(): void {
        this.localStore.patchState(({ isInfoVisible }) => ({
            isInfoVisible: !isInfoVisible,
        }));
    }

    protected select(): void {
        const customId = this.localStore.selectSnapshotByKey("customFlightId");
        if (customId) {
            this.selectById.emit(customId);

            return;
        }

        const trackerId = this.localStore.selectSnapshotByKey("aircraftEntity")?.trackerIdentifier;

        this.selectById.emit(trackerId);
    }

    protected getPhaseStyleClass(phase?: FlightMissionPhase): string {
        return phase ? FLIGHT_PHASE_STYLE_CLASS_MAP[phase] : "";
    }

    protected getAircraftRotationStyles(track?: number): { [key: string]: string } | undefined {
        if (track === undefined) {
            return;
        }

        return {
            transform: `rotate(${track - TRACK_BASE_ROTATION_IN_DEGREES}deg)`,
        };
    }

    protected getAccuracy(accuracy: AircraftEntity["position"]["accuracy"]): string | undefined {
        if (!accuracy.latitude || !accuracy.longitude) {
            return;
        }

        return Math.max(accuracy.latitude, accuracy.longitude).toFixed(2);
    }

    protected openDetails(): void {
        this.detailsOpen.emit(this.localStore.selectSnapshotByKey("aircraftEntity"));
        this.flightPositionUpdatesEnrich.emit(true);
    }

    private mapViolationToClass(violation?: Violation): NgClass["ngClass"] {
        if (!violation) {
            return;
        }

        return {
            warning: violation === Violation.UavLeftOwnFlightArea,
            danger: [
                Violation.UavOutsideStartingFlightZone,
                Violation.UavEnteredForeignSafetyArea,
                Violation.UavLeftOwnSafetyArea,
            ].includes(violation),
        };
    }
}
