<div class="form-wrapper" [formGroup]="operationalVolumeHeightForm">
    <dtm-ui-input-field [isClearable]="false">
        <label>{{ "dtmAdminLibPermits.operationAreaHeight.operationAreaHeightLabel" | transloco }}</label>
        <input matInput type="number" [formControl]="operationalVolumeHeightForm.controls.operationalVolumeHeightLimit" />
        <div class="field-suffix">{{ "dtmAdminLibPermits.operationAreaHeight.operationAreaInputSuffix" | transloco }}</div>
        <div class="field-error" *dtmUiFieldHasError="operationalVolumeHeightForm.controls.operationalVolumeHeightLimit; name: 'required'">
            {{ "dtmAdminLibPermits.container.requiredField" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="operationalVolumeHeightForm.controls.operationalVolumeHeightLimit; name: 'max'">
            {{
                "dtmAdminLibPermits.operationAreaHeight.maxValueErrorHint"
                    | transloco
                        : {
                              max: MAX_AREA_HEIGHT_VALUE,
                              unit: "dtmAdminLibPermits.operationAreaHeight.operationAreaInputSuffix" | transloco
                          }
            }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="operationalVolumeHeightForm.controls.operationalVolumeHeightLimit; name: 'min'">
            {{
                "dtmAdminLibPermits.operationAreaHeight.minValueErrorHint"
                    | transloco
                        : {
                              min: MIN_AREA_HEIGHT_VALUE,
                              unit: "dtmAdminLibPermits.operationAreaHeight.operationAreaInputSuffix" | transloco
                          }
            }}
        </div>
    </dtm-ui-input-field>
</div>
