import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { AbstractControl, ValidationErrors } from "@angular/forms";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { takeUntil } from "rxjs";

interface FieldHasErrorDirectiveState {
    formControl: AbstractControl | undefined;
}

@UntilDestroy()
@Directive({
    selector: "[dtmUiFieldHasError]",
    providers: [LocalComponentStore],
})
export class FieldHasErrorDirective {
    @Input("dtmUiFieldHasError") public set formControl(value: AbstractControl) {
        this.localStore.patchState({ formControl: value });

        if (value) {
            this.initFormControl(value);
        }
    }
    @Input("dtmUiFieldHasErrorName") public errorName: string[] | string | null = null;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(
        private readonly templateRef: TemplateRef<any>,
        private readonly viewContainer: ViewContainerRef,
        private readonly localStore: LocalComponentStore<FieldHasErrorDirectiveState>
    ) {
        localStore.setState({ formControl: undefined });
    }

    public initFormControl(formControl: AbstractControl): void {
        this.decorateFormControlMethods(formControl);

        formControl.statusChanges.pipe(untilDestroyed(this), takeUntil(this.localStore.selectByKey("formControl"))).subscribe(() => {
            this.manageErrorVisibility(formControl);
        });
    }

    private decorateFormControlMethods(formControl: AbstractControl): void {
        const decorateMethods: (keyof AbstractControl)[] = [
            "markAsTouched",
            "setValue",
            "reset",
            "patchValue",
            "setErrors",
            "updateValueAndValidity",
        ];

        decorateMethods.forEach((method) => {
            const sourceMethod = formControl[method];
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (formControl as any)[method] = (...args: unknown[]) => {
                sourceMethod.bind(formControl)(...args);
                this.manageErrorVisibility(formControl);
            };
        });
    }

    private manageErrorVisibility(formControl: AbstractControl): void {
        if (formControl.invalid && this.canShowError(formControl) && this.hasError()) {
            this.viewContainer.clear();
            this.viewContainer.createEmbeddedView(this.templateRef, { error: this.getError() });
        } else {
            this.viewContainer.clear();
        }
    }

    private getError(): ValidationErrors | null {
        const formControl = this.localStore.selectSnapshotByKey("formControl");

        if (!formControl) {
            return null;
        }

        if (!this.errorName) {
            return formControl.errors;
        }

        if (Array.isArray(this.errorName)) {
            const result: ValidationErrors = {};

            for (const validatorName of this.errorName) {
                result[validatorName] = this.getFormControlError(formControl, validatorName);
            }

            if (!Object.values(result).filter(Boolean).length) {
                return null;
            }

            return result;
        }

        return this.getFormControlError(formControl, this.errorName);
    }

    private getFormControlError(formControl: AbstractControl, errorName: string): ValidationErrors | null {
        const validatorName = errorName === "requiredTouched" ? "required" : errorName;

        return formControl.getError(validatorName);
    }

    private hasError(): boolean {
        return !!this.getError();
    }

    private canShowError(formControl: AbstractControl): boolean {
        const isRequiredTouched =
            this.errorName === "requiredTouched" || (Array.isArray(this.errorName) && this.errorName.includes("requiredTouched"));

        if (
            (Array.isArray(this.errorName) && this.errorName.includes("required") && this.errorName.includes("pattern")) ||
            isRequiredTouched
        ) {
            return formControl.touched;
        }

        return true;
    }
}
