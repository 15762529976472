<dtm-ui-loader-container [isShown]="isProcessing$ | ngrxPush">
    <div class="grid main-container">
        <div class="grid-col-8 list-container">
            <dtm-admin-lib-filters
                (filtersChange)="applyFilters($event)"
                *ngIf="initialFilters$ | ngrxPush as filters"
                [initialFilters]="filters"
            ></dtm-admin-lib-filters>
            <dtm-admin-lib-operator-list
                [selectedOperatorId]="(selectedOperatorDetails$ | ngrxPush)?.id"
                [operators]="operators$ | ngrxPush"
                (operatorSelect)="operatorSelected($event?.id, $event?.type)"
                [pagination]="pages$ | ngrxPush"
                [operatorListError]="operatorListError$ | ngrxPush"
                (operatorsListRefresh)="refreshList()"
                (pageChange)="changePage($event)"
            ></dtm-admin-lib-operator-list>
        </div>
        <div class="grid-col-4 profile-container">
            <dtm-ui-error
                *ngIf="operatorError$ | ngrxPush as error; else operatorDetailsTemplate"
                (reload)="refreshOperatorDetails()"
            ></dtm-ui-error>
            <ng-template #operatorDetailsTemplate>
                <dtm-admin-lib-operator-profile-container (detailsChange)="refreshList()"></dtm-admin-lib-operator-profile-container>
            </ng-template>
        </div>
    </div>
</dtm-ui-loader-container>
