import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ActivatedRoute, Router } from "@angular/router";
import {
    ConfirmationDialogComponent,
    DialogService,
    IdentityDocumentStatus,
    MIDDLE_PAGE_SIZE_VALUE,
    PAGE_NUMBER_QUERY_PARAM,
    PAGE_SIZE_QUERY_PARAM,
} from "@dtm-frontend/shared/ui";
import { LocalComponentStore, MIN_USER_AGE, PREVIOUS_MIN_USER_AGE, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import {
    CaaFeatures,
    GlobalCapabilitiesState,
    LegalGuardianDetailsEditionDialogData,
    LegalGuardianDetailsUpdate,
    NULL_VALUE,
} from "../../../shared";
import { LegalGuardianDetailsEditionComponent } from "../../../shared/components/operator-profile/legal-guardian-details-edition/legal-guardian-details-edition.component";
import {
    UtmUserBasicData,
    UtmUserDetails,
    UtmUserError,
    UtmUserErrorType,
    UtmUserFilters,
    UtmUserIdentityCardStatusRequest,
    UtmUsersFiltersParams,
} from "../../services/utm-users.models";
import { UtmUsersActions } from "../../state/utm-users.actions";
import { UtmUsersState } from "../../state/utm-users.state";
import { UtmUserEditBasicDataDialogComponent } from "../utm-user-edit-basic-data-dialog/utm-user-edit-basic-data-dialog.component";
import { UtmUserRejectDocumentDialogComponent } from "../utm-user-reject-document-dialog/utm-user-reject-document-dialog.component";

const XML_EXTENSION = ".xml";

interface UtmUsersContainerState {
    selectedUserId: string | undefined;
    [PAGE_NUMBER_QUERY_PARAM]: number;
    [PAGE_SIZE_QUERY_PARAM]: number;
    filtersQuery: UtmUserFilters | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-utm-users-container",
    templateUrl: "./utm-users-container.component.html",
    styleUrls: ["./utm-users-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UtmUsersContainerComponent implements OnInit {
    protected readonly isUserListProcessing$ = this.store.select(UtmUsersState.isUserListProcessing);
    protected readonly isUserProfileProcessing$ = this.store.select(UtmUsersState.isUserProfileProcessing);
    protected readonly page$ = this.store.select(UtmUsersState.page);
    protected readonly userList$ = this.store.select(UtmUsersState.userList);
    protected readonly selectedUser$ = this.store.select(UtmUsersState.selectedUser);
    protected readonly userListError$ = this.store.select(UtmUsersState.userListError);
    protected readonly userError$ = this.store.select(UtmUsersState.userError);
    protected readonly filtersQuery$ = this.localStore.selectByKey("filtersQuery").pipe(RxjsUtils.filterFalsy());

    constructor(
        private readonly store: Store,
        private readonly localStore: LocalComponentStore<UtmUsersContainerState>,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly dialogService: DialogService,
        private readonly toastService: ToastrService,
        private readonly transloco: TranslocoService
    ) {
        const initialParams = this.route.snapshot.queryParams;
        localStore.setState({
            selectedUserId: undefined,
            [PAGE_NUMBER_QUERY_PARAM]: initialParams[PAGE_NUMBER_QUERY_PARAM] ?? 0,
            [PAGE_SIZE_QUERY_PARAM]: initialParams[PAGE_SIZE_QUERY_PARAM] ?? MIDDLE_PAGE_SIZE_VALUE,
            filtersQuery: {
                searchText: initialParams.searchText,
                statuses: initialParams.statuses?.split(","),
                isDefaultCountry: initialParams.isDefaultCountry ?? NULL_VALUE,
                isLegalAge: initialParams.isLegalAge ?? NULL_VALUE,
                isWaitingIdentityDocument: initialParams.isWaitingIdentityDocument ?? NULL_VALUE,
                isWaitingCompletionRequest: initialParams.isWaitingCompletionRequest ?? NULL_VALUE,
            },
        });
    }

    public ngOnInit() {
        this.refreshList();
    }

    protected refreshList() {
        this.route.queryParams.pipe(untilDestroyed(this)).subscribe((queryParams: UtmUsersFiltersParams) => {
            this.store.dispatch(new UtmUsersActions.GetUsers(queryParams));
        });
    }

    protected userSelected(selectedUserId: string) {
        this.localStore.patchState({ selectedUserId });
        this.store.dispatch(new UtmUsersActions.SelectUser(selectedUserId));
    }

    protected changePage(event: PageEvent) {
        this.localStore.patchState({
            [PAGE_NUMBER_QUERY_PARAM]: event.pageIndex,
            [PAGE_SIZE_QUERY_PARAM]: event.pageSize,
            selectedUserId: undefined,
        });
        this.navigateByParams();
    }

    protected applyFilters(filters: UtmUserFilters) {
        this.localStore.patchState({ filtersQuery: filters });
        this.navigateByParams(true);
    }

    protected editBasicData(user: UtmUserDetails) {
        this.openEditBasicDataDialogAndGetValues(user)
            .pipe(untilDestroyed(this))
            .subscribe((dialogRef) => {
                const error = this.store.selectSnapshot(UtmUsersState.updateUserBasicDataError);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }

                this.toastService.success(this.transloco.translate("dtmAdminLibUtmUsers.userProfile.basicDataEdit.successMessage"));
                this.store.dispatch(new UtmUsersActions.SelectUser(user.id));
                this.refreshList();
                dialogRef.close();
            });
    }

    protected downloadDocument(user: UtmUserDetails) {
        this.store
            .dispatch(new UtmUsersActions.DownloadIdentityDocument(user.id, user.identityDocument.id, user.identityDocument.filename))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(UtmUsersState.downloadDocumentError);

                if (error) {
                    this.displayErrorMessage(error);
                }
            });
    }

    protected acceptDocument(user: UtmUserDetails) {
        const dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
            data: {
                titleText: this.transloco.translate("dtmAdminLibUtmUsers.userProfile.identityDocument.acceptDocumentDialogTitleText"),
                declineButtonLabel: this.transloco.translate("dtmAdminLibUtmUsers.userProfile.identityDocument.cancelLabel"),
                confirmButtonLabel: this.transloco.translate("dtmAdminLibUtmUsers.userProfile.identityDocument.acceptDocumentConfirmLabel"),
            },
        });

        dialogRef
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => {
                    const statusRequest: UtmUserIdentityCardStatusRequest = {
                        status: IdentityDocumentStatus.Active,
                    };

                    return this.store.dispatch(
                        new UtmUsersActions.UpdateIdentityDocumentStatus(user.id, user.identityDocument.id, statusRequest)
                    );
                }),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(UtmUsersState.updateIdentityDocumentStatusError);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }

                this.store.dispatch(new UtmUsersActions.SelectUser(user.id));
                this.refreshList();
                this.toastService.success(
                    this.transloco.translate("dtmAdminLibUtmUsers.userProfile.identityDocument.acceptSuccessMessage")
                );
            });
    }

    protected rejectDocument(user: UtmUserDetails) {
        this.openDocumentRejectDialogAndGetValue(user)
            .pipe(untilDestroyed(this))
            .subscribe((dialogRef) => {
                const error = this.store.selectSnapshot(UtmUsersState.updateIdentityDocumentStatusError);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }

                this.toastService.success(
                    this.transloco.translate("dtmAdminLibUtmUsers.userProfile.identityDocument.rejectSuccessMessage")
                );
                this.store.dispatch(new UtmUsersActions.SelectUser(user.id));
                this.refreshList();
                dialogRef.close();
            });
    }

    protected requestForDocumentUpdate(user: UtmUserDetails) {
        const dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
            data: {
                confirmationText: "",
                titleText: this.transloco.translate("dtmAdminLibUtmUsers.userProfile.identityDocument.requestForUpdateConfirmationText"),
                declineButtonLabel: this.transloco.translate("dtmAdminLibUtmUsers.userProfile.identityDocument.cancelLabel"),
                confirmButtonLabel: this.transloco.translate(
                    "dtmAdminLibUtmUsers.userProfile.identityDocument.confirmRequestForUpdateLabel"
                ),
            },
        });
        dialogRef
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.store.dispatch(new UtmUsersActions.RequestForDocumentUpdate(user.id))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                if (this.store.selectSnapshot(UtmUsersState.requestForDocumentUpdateError)) {
                    this.toastService.error(
                        this.transloco.translate("dtmAdminLibUtmUsers.userProfile.identityDocument.requestForDocumentUpdateErrorMessage")
                    );

                    return;
                }
                this.toastService.success(
                    this.transloco.translate("dtmAdminLibUtmUsers.userProfile.identityDocument.requestForDocumentUpdateSuccessMessage")
                );
                this.refreshList();
                this.userSelected(user.id);
            });
    }

    protected requestLegalGuardianUpdate(user: UtmUserDetails) {
        const dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
            data: {
                confirmationText: "",
                titleText: this.transloco.translate(
                    "dtmAdminLibUtmUsers.userProfile.legalGuardianDetailsRequired.requestForUpdateConfirmationText"
                ),
                declineButtonLabel: this.transloco.translate("dtmAdminLibUtmUsers.userProfile.legalGuardianDetailsRequired.cancelLabel"),
                confirmButtonLabel: this.transloco.translate(
                    "dtmAdminLibUtmUsers.userProfile.legalGuardianDetailsRequired.confirmRequestForUpdateLabel"
                ),
            },
        });
        dialogRef
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.store.dispatch(new UtmUsersActions.RequestLegalGuardianUpdate(user.id))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                if (this.store.selectSnapshot(UtmUsersState.requestForLegalGuardianUpdateError)) {
                    this.toastService.error(
                        this.transloco.translate(
                            "dtmAdminLibUtmUsers.userProfile.legalGuardianDetailsRequired.requestForLegalGuardianUpdateErrorMessage"
                        )
                    );

                    return;
                }
                this.toastService.success(
                    this.transloco.translate(
                        "dtmAdminLibUtmUsers.userProfile.legalGuardianDetailsRequired.requestForLegalGuardianUpdateSuccessMessage"
                    )
                );
                this.refreshList();
                this.userSelected(user.id);
            });
    }

    private openDocumentRejectDialogAndGetValue(user: UtmUserDetails) {
        const dialogRef = this.dialogService.open(UtmUserRejectDocumentDialogComponent, {
            data: {
                isProcessing: this.isUserListProcessing$,
                disableClose: true,
            },
        });

        return dialogRef.componentInstance.reason$.pipe(
            switchMap((value: string) => {
                const statusRequest: UtmUserIdentityCardStatusRequest = {
                    status: IdentityDocumentStatus.Rejected,
                    statusReason: value,
                };

                return this.store.dispatch(
                    new UtmUsersActions.UpdateIdentityDocumentStatus(user.id, user.identityDocument.id, statusRequest)
                );
            }),
            map(() => dialogRef)
        );
    }

    protected deleteLegalGuardian(user: UtmUserDetails) {
        this.store
            .dispatch(new UtmUsersActions.DeleteLegalGuardian(user.id))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(UtmUsersState.deleteLegalGuardianError);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }

                this.store.dispatch(new UtmUsersActions.SelectUser(user.id));
                this.refreshList();
                this.toastService.success(this.transloco.translate("dtmAdminLibUtmUsers.userProfile.legalGuardian.deleteSuccessMessage"));
            });
    }

    protected editLegalGuardianDetails(user: UtmUserDetails) {
        if (user.legalGuardian) {
            const data: LegalGuardianDetailsEditionDialogData = {
                legalGuardian: user.legalGuardian,
                isProcessing$: this.isUserProfileProcessing$,
            };
            this.updateLegalGuardian(user.id, data);
        }
    }

    protected fillLegalGuardianDetails(user: UtmUserDetails) {
        const data: LegalGuardianDetailsEditionDialogData = {
            legalGuardianConfirmation: user.legalGuardianConfirmationRequest,
            isProcessing$: this.isUserProfileProcessing$,
        };
        this.updateLegalGuardian(user.id, data);
    }

    protected downloadLegalGuardianSignedDocument(userId: string) {
        this.store
            .dispatch(
                new UtmUsersActions.GetLegalGuardianSignedDocument(
                    userId,
                    this.transloco.translate("dtmAdminLibUtmUsers.userProfile.legalGuardian.signatureFileName", {
                        extension: XML_EXTENSION,
                    })
                )
            )
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(UtmUsersState.legalGuardianSignedDocumentError);
                if (!error) {
                    return;
                }

                this.toastService.error(this.transloco.translate("dtmAdminLibUtmUsers.userProfile.legalGuardian.signatureDownloadError"));
            });
    }

    private openEditLegalGuardianDialogAndGetValues(
        userId: string,
        data: LegalGuardianDetailsEditionDialogData
    ): Observable<MatDialogRef<LegalGuardianDetailsEditionComponent>> {
        const dialogRef = this.dialogService.open(LegalGuardianDetailsEditionComponent, {
            data,
        });

        return dialogRef.componentInstance.newValue$.pipe(
            switchMap((values: LegalGuardianDetailsUpdate) => this.store.dispatch(new UtmUsersActions.UpdateLegalGuardian(userId, values))),
            map(() => dialogRef)
        );
    }

    private openEditBasicDataDialogAndGetValues({
        basicData,
        id,
    }: UtmUserDetails): Observable<MatDialogRef<UtmUserEditBasicDataDialogComponent>> {
        const minUserAge = this.store.selectSnapshot(GlobalCapabilitiesState.isFeatureAvailable(CaaFeatures.AviationLav2025))
            ? MIN_USER_AGE
            : PREVIOUS_MIN_USER_AGE;
        const dialogRef = this.dialogService.open(UtmUserEditBasicDataDialogComponent, {
            data: {
                user: basicData,
                minUserAge,
                isProcessing$: this.isUserProfileProcessing$,
            },
        });

        return dialogRef.componentInstance.newValue$.pipe(
            switchMap((values: UtmUserBasicData) => this.store.dispatch(new UtmUsersActions.UpdateUser(id, values))),
            map(() => dialogRef)
        );
    }

    private updateLegalGuardian(userId: string, data: LegalGuardianDetailsEditionDialogData) {
        this.openEditLegalGuardianDialogAndGetValues(userId, data)
            .pipe(untilDestroyed(this))
            .subscribe((dialogRef) => {
                const error = this.store.selectSnapshot(UtmUsersState.updateLegalGuardianError);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }

                this.toastService.success(this.transloco.translate("dtmAdminLibUtmUsers.userProfile.legalGuardian.updateSuccessMessage"));
                this.store.dispatch(new UtmUsersActions.SelectUser(userId));
                this.refreshList();
                dialogRef.close();
            });
    }

    private navigateByParams(shouldResetPage?: boolean) {
        const pageIndex = shouldResetPage ? 0 : this.localStore.selectSnapshotByKey(PAGE_NUMBER_QUERY_PARAM);
        const filtersQuery = this.localStore.selectSnapshotByKey("filtersQuery");
        const params: UtmUsersFiltersParams = {
            [PAGE_NUMBER_QUERY_PARAM]: pageIndex,
            [PAGE_SIZE_QUERY_PARAM]: this.localStore.selectSnapshotByKey(PAGE_SIZE_QUERY_PARAM),
        };

        if (shouldResetPage) {
            this.localStore.patchState({ [PAGE_NUMBER_QUERY_PARAM]: pageIndex });
        }

        if (filtersQuery?.searchText) {
            params.searchText = filtersQuery.searchText;
        }

        if (filtersQuery?.statuses && filtersQuery.statuses.length > 0) {
            params.statuses = filtersQuery.statuses.join(",");
        }

        if (filtersQuery?.isDefaultCountry) {
            params.isDefaultCountry = filtersQuery.isDefaultCountry;
        }

        if (filtersQuery?.isLegalAge) {
            params.isLegalAge = filtersQuery.isLegalAge;
        }

        if (filtersQuery?.isWaitingIdentityDocument) {
            params.isWaitingIdentityDocument = filtersQuery.isWaitingIdentityDocument;
        }

        if (filtersQuery?.isWaitingCompletionRequest) {
            params.isWaitingCompletionRequest = filtersQuery.isWaitingCompletionRequest;
        }

        this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams: params,
            replaceUrl: true,
        });
    }

    private displayErrorMessage(error: UtmUserError) {
        switch (error.type) {
            case UtmUserErrorType.CannotUpdateUser:
                this.toastService.error(this.transloco.translate("dtmAdminLibUtmUsers.userProfile.basicDataEdit.genericErrorMessage"));
                break;
            case UtmUserErrorType.CannotDownloadDocument:
                this.toastService.error(this.transloco.translate("dtmAdminLibUtmUsers.userProfile.identityDocument.downloadErrorMessage"));
                break;
            case UtmUserErrorType.CannotUpdateDocumentStatus:
                this.toastService.error(
                    this.transloco.translate("dtmAdminLibUtmUsers.userProfile.identityDocument.updateStatusErrorMessage")
                );
                break;
            case UtmUserErrorType.CannotUpdateLegalGuardian:
                this.toastService.error(this.transloco.translate("dtmAdminLibUtmUsers.userProfile.legalGuardian.updateErrorMessage"));
                break;
            case UtmUserErrorType.CannotDeleteLegalGuardian:
                this.toastService.error(this.transloco.translate("dtmAdminLibUtmUsers.userProfile.legalGuardian.deleteErrorMessage"));
                break;
            default:
                this.toastService.error(this.transloco.translate("dtmAdminLibUtmUsers.userProfile.genericErrorMessage"));
        }
    }
}
