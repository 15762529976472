import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import {
    AIR_RISK_MITIGATIONS_CATEGORIES,
    AirRisk,
    AirRiskElement,
    AirRiskMitigations,
    AirRiskMitigationsArc,
    AirRiskMitigationsCategory,
    M1BStatementsKeys,
    M2StatementsKeys,
    RobustnessLevel,
    SoraSettings,
} from "../../models/mission.models";

interface AirRiskData {
    designator?: string;
    initialArc: AirRiskMitigationsArc;
    finalArc: AirRiskMitigationsArc;
    category: AirRiskMitigationsCategory;
    methods: string[];
}

export interface RiskMitigationMeasuresComponentState {
    soraSettings: SoraSettings | undefined;
    airRisk: AirRisk | undefined;
    isExpanded: boolean;
}

@Component({
    selector: "dtm-mission-risk-mitigation-measures",
    templateUrl: "./risk-mitigation-measures.component.html",
    styleUrls: ["./risk-mitigation-measures.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskMitigationMeasuresComponent {
    @Input() public set airRisk(value: AirRisk | undefined) {
        this.localStore.patchState({ airRisk: value });
    }

    @Input() public set soraSettings(value: SoraSettings | undefined) {
        if (!!value?.controlledGroundAreaComment && (value.riskMitigations?.m1A || value.riskMitigations?.m1B)) {
            value = {
                ...value,
                riskMitigations: {
                    ...value.riskMitigations,
                    m1A: undefined,
                    m1B: undefined,
                },
            };
        }

        this.localStore.patchState({ soraSettings: this.getSoraSettingsWithFilteredStatements(value) });
    }

    @Input() public set isExpanded(value: BooleanInput) {
        this.localStore.patchState({ isExpanded: coerceBooleanProperty(value) });
    }

    protected readonly soraSettings$ = this.localStore.selectByKey("soraSettings");
    protected readonly airRiskMitigation$ = combineLatest([this.soraSettings$, this.localStore.selectByKey("airRisk")]).pipe(
        map(([soraSettings, airRisk]) => this.getAirRiskMitigation(soraSettings?.airRiskMitigations, airRisk))
    );
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");

    protected readonly RobustnessLevel = RobustnessLevel;
    protected readonly M1BStatementsKeys = M1BStatementsKeys;
    protected readonly M2StatementsKeys = M2StatementsKeys;

    constructor(
        private readonly localStore: LocalComponentStore<RiskMitigationMeasuresComponentState>,
        private readonly transloco: TranslocoService
    ) {
        localStore.setState({
            soraSettings: undefined,
            airRisk: undefined,
            isExpanded: true,
        });
    }

    private getAirRiskMitigation(airRiskMitigations: AirRiskMitigations | undefined, airRisk: AirRisk | undefined): AirRiskData[] {
        const airRiskElements: AirRiskElement[] = airRisk?.elements ?? [];
        const availableMitigations = airRiskMitigations?.mitigatingMeasures.map(({ category }) => category);
        const areaAirspaceRiskData = new Map<AirRiskMitigationsCategory, AirRiskData>();
        let mitigation;

        airRiskElements
            .filter(
                ({ designator, category }) =>
                    (!!designator || category === AirRiskMitigationsCategory.Other) &&
                    AIR_RISK_MITIGATIONS_CATEGORIES.includes(category) &&
                    availableMitigations?.includes(category)
            )
            .forEach(({ category, designator, initialArc }) => {
                mitigation = airRiskMitigations?.mitigatingMeasures.find((data) => data.category === category);
                areaAirspaceRiskData.set(category, {
                    designator:
                        (areaAirspaceRiskData.get(category)?.designator ? `${areaAirspaceRiskData.get(category)?.designator}, ` : "") +
                        designator,
                    finalArc: mitigation?.arc ?? initialArc,
                    initialArc: initialArc,
                    methods: mitigation?.methods.map(({ title }) => title) ?? [],
                    category,
                });
            });

        if (airRisk?.operationalEmpty?.initialArc && airRisk.operationalEmpty.residualArc && !airRisk.elements.length) {
            areaAirspaceRiskData.set(AirRiskMitigationsCategory.EmptyAirspace, {
                finalArc: airRisk.operationalEmpty.residualArc,
                initialArc: airRisk.operationalEmpty.initialArc,
                methods: airRiskMitigations?.mitigatingMeasures[0].methods.map(({ title }) => title) ?? [],
                category: AirRiskMitigationsCategory.EmptyAirspace,
            });
        }

        return [...areaAirspaceRiskData.values()];
    }

    private getSoraSettingsWithFilteredStatements(soraSettings?: SoraSettings): SoraSettings | undefined {
        if (!soraSettings?.riskMitigations) {
            return soraSettings;
        }

        return {
            ...soraSettings,
            riskMitigations: {
                ...soraSettings.riskMitigations,
                m1A: soraSettings.riskMitigations.m1A
                    ? {
                          ...soraSettings.riskMitigations.m1A,
                          userStatements: this.filterStatementsByExistingTranslations(soraSettings.riskMitigations.m1A.userStatements),
                      }
                    : undefined,
                m1B: soraSettings.riskMitigations.m1B
                    ? {
                          ...soraSettings.riskMitigations.m1B,
                          userStatements: this.filterStatementsByExistingTranslations(soraSettings.riskMitigations.m1B.userStatements),
                      }
                    : undefined,
            },
        };
    }

    private filterStatementsByExistingTranslations<Key extends string>(statements?: Key[]): Key[] | undefined {
        return statements?.reduce((result: Key[], statement) => {
            if (this.transloco.translate(statement) !== statement) {
                result.push(statement);
            }

            return result;
        }, []);
    }
}
