<ng-container *ngrxLet="insuranceNumber$ as insuranceNumber">
    <ng-container *ngrxLet="isEditModeOn$ as isEditModeOn">
        <dtm-ui-editable-card
            *ngIf="insuranceNumber || isEditModeOn; else emptyPolicyTemplate"
            [label]="'dtmWebAppLibShared.insurancePolicyEdit.insurancePolicyHeader' | transloco"
            [isSaveButtonDisabled]="policyFormGroup.invalid || policyFormGroup.pristine"
            [isEditModeOn]="isEditModeOn"
            (actionSave)="savePolicy()"
            [isEditButtonVisible]="!insuranceNumber"
            (editModeChange)="setEditMode($event)"
        >
            <dtm-ui-multiple-actions
                additionalActions
                *ngIf="insuranceNumber"
                (edit)="setEditMode(true)"
                (delete)="tryDeleteInsurancePolicy()"
            ></dtm-ui-multiple-actions>
            <ng-container previewContent>
                <div class="insurance-details" *ngIf="insuranceNumber; else emptyPolicyTemplate">
                    <dtm-ui-label-value
                        [label]="'dtmWebAppLibShared.insurancePolicyEdit.policyNumberLabel' | transloco"
                        [value]="insuranceNumber | falsyDash"
                    >
                    </dtm-ui-label-value>
                    <dtm-ui-label-value
                        [label]="'dtmWebAppLibShared.insurancePolicyEdit.policyExpirationDateLabel' | transloco"
                        [value]="expirationDate$ | ngrxPush | localizeDate | falsyDash"
                    >
                    </dtm-ui-label-value>
                </div>
            </ng-container>
            <div editableContent class="form" [formGroup]="policyFormGroup">
                <div class="form">
                    <dtm-ui-input-field>
                        <label for="number">
                            {{ "dtmWebAppLibShared.insurancePolicyEdit.policyNumberLabel" | transloco }}
                        </label>
                        <input matInput id="number" formControlName="number" />
                        <div
                            class="field-error"
                            *dtmUiFieldHasError="policyFormGroup.controls.number; name: ['required', 'requiredTouched']"
                            [ngTemplateOutlet]="requiredErrorTemplate"
                        ></div>
                        <div class="field-error" *dtmUiFieldHasError="policyFormGroup.controls.number; name: 'maxlength'; error as error">
                            {{
                                "dtmWebAppLibShared.insurancePolicyEdit.insuranceNumberLengthError"
                                    | transloco : { max: error.requiredLength }
                            }}
                        </div>
                    </dtm-ui-input-field>
                    <dtm-ui-date-field>
                        <label for="sendDateControl">
                            {{ "dtmWebAppLibShared.insurancePolicyEdit.policyExpirationDateLabel" | transloco }}
                        </label>
                        <input
                            id="sendDateControl"
                            formControlName="expirationDate"
                            matInput
                            [min]="TOMORROW_DATE"
                            [matDatepicker]="expirationDatepicker"
                            *ngrxLet="datePickerPlaceholder$ as datePickerPlaceholder"
                            [placeholder]="datePickerPlaceholder"
                        />
                        <mat-datepicker #expirationDatepicker></mat-datepicker>
                        <div
                            class="field-error"
                            *dtmUiFieldHasError="
                                policyFormGroup.controls.expirationDate;
                                name: ['required', 'requiredTouched'];
                                error as error
                            "
                            [ngTemplateOutlet]="requiredErrorTemplate"
                        ></div>
                        <div class="field-error" *ngIf="policyFormGroup.controls.expirationDate.errors?.matDatepickerMin">
                            {{
                                "dtmWebAppLibShared.insurancePolicyEdit.minInsuranceExpirationDateError"
                                    | transloco
                                        : { minDate: policyFormGroup.controls.expirationDate.errors?.matDatepickerMin.min | localizeDate }
                            }}
                        </div>
                    </dtm-ui-date-field>
                </div>
            </div>
        </dtm-ui-editable-card>
    </ng-container>
</ng-container>

<ng-template #requiredErrorTemplate>
    {{ "dtmWebAppLibShared.insurancePolicyEdit.requiredFieldError" | transloco }}
</ng-template>

<ng-template #emptyPolicyTemplate>
    <dtm-ui-empty-state>
        <ng-container titleSlot>
            {{ "dtmWebAppLibShared.insurancePolicyEdit.insurancePolicyHeader" | transloco }}
        </ng-container>
        <img imageSlot src="assets/images/insurance-policy-empty-state.svg" />
        <div messageSlot class="empty-state-info">
            <p>{{ "dtmWebAppLibShared.insurancePolicyEdit.noAddedPolicyInfo" | transloco }}</p>
            <p>
                {{
                    "dtmWebAppLibShared.insurancePolicyEdit.noAddedPolicyReminderInfo"
                        | transloco : { fineInfo: isFineInfoVisible$ | ngrxPush }
                }}
            </p>
        </div>
        <button footerSlot type="button" class="button-primary" (click)="setEditMode(true)">
            {{ "dtmWebAppLibShared.insurancePolicyEdit.addInsuranceLabel" | transloco }}
        </button>
    </dtm-ui-empty-state>
</ng-template>
