import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Injector, Input, Output } from "@angular/core";
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    NgControl,
    ValidationErrors,
    Validator,
    ValidatorFn,
    Validators,
} from "@angular/forms";
import { FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

interface ExpandedNumberInputComponentState {
    valueSuffix: string;
    minValue: number;
    maxValue: number;
    stepValue: number;
    predefinedOptions: number[] | undefined;
    minValueValidator: ValidatorFn;
    maxValueValidator: ValidatorFn;
    predefinedOptionsPlacement: "before" | "after";
}

const DEFAULT_STEP_VALUE = 10;
enum ValueChangeType {
    Incremental = "Incremental",
    Decremental = "Decremental",
    Set = "Set",
}

@UntilDestroy()
@Component({
    selector: "dtm-ui-expanded-number-input",
    templateUrl: "expanded-number-input.component.html",
    styleUrls: ["expanded-number-input.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ExpandedNumberInputComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ExpandedNumberInputComponent),
            multi: true,
        },
    ],
})
export class ExpandedNumberInputComponent implements ControlValueAccessor, Validator, AfterViewInit {
    @Input() public set valueSuffix(value: string | undefined) {
        this.localStore.patchState({ valueSuffix: value ?? "" });
    }

    @Input() public set minValue(value: number | undefined) {
        const newValue = value ?? Number.NEGATIVE_INFINITY;
        const minValueValidator = Validators.min(newValue);

        this.localStore.patchState({ minValue: newValue, minValueValidator });
    }

    @Input() public set maxValue(value: number | undefined) {
        const newValue = value ?? Number.POSITIVE_INFINITY;
        const maxValueValidator = Validators.max(newValue);

        this.localStore.patchState({ maxValue: newValue, maxValueValidator });
    }

    @Input() public set stepValue(value: number | undefined) {
        this.localStore.patchState({ stepValue: value ?? DEFAULT_STEP_VALUE });
    }

    @Input() public set predefinedOptions(value: number[] | undefined) {
        this.localStore.patchState({ predefinedOptions: value });
    }

    @Input() public set predefinedOptionsPlacement(value: "before" | "after" | undefined) {
        this.localStore.patchState({ predefinedOptionsPlacement: value ?? "after" });
    }

    @Output() public readonly valueChange = new EventEmitter();

    private onChange: (value: number | null) => void = FunctionUtils.noop;
    protected onTouched: () => void = FunctionUtils.noop;

    protected readonly ValueChangeType = ValueChangeType;
    protected readonly control = new FormControl<number | null>(null);

    protected readonly valueSuffix$ = this.localStore.selectByKey("valueSuffix");
    protected readonly stepValue$ = this.localStore.selectByKey("stepValue");
    protected readonly minValue$ = this.localStore.selectByKey("minValue");
    protected readonly maxValue$ = this.localStore.selectByKey("maxValue");
    protected readonly predefinedOptions$ = this.localStore.selectByKey("predefinedOptions");
    protected readonly predefinedOptionsPlacement$ = this.localStore.selectByKey("predefinedOptionsPlacement");

    constructor(private readonly injector: Injector, private readonly localStore: LocalComponentStore<ExpandedNumberInputComponentState>) {
        this.localStore.setState({
            valueSuffix: "",
            stepValue: DEFAULT_STEP_VALUE,
            minValue: Number.NEGATIVE_INFINITY,
            maxValue: Number.POSITIVE_INFINITY,
            predefinedOptions: undefined,
            minValueValidator: Validators.min(Number.NEGATIVE_INFINITY),
            maxValueValidator: Validators.max(Number.POSITIVE_INFINITY),
            predefinedOptionsPlacement: "after",
        });

        this.watchNumberInputControlValueChange();
    }

    public ngAfterViewInit() {
        const parentControl = this.injector.get(NgControl, null)?.control;
        parentControl?.statusChanges.pipe(untilDestroyed(this)).subscribe((status) => {
            this.control.setErrors(status === "INVALID" ? { wrongNumber: true } : null);
        });
    }

    public registerOnChange(fn: (value: number | null) => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public writeValue(value: number | null): void {
        this.control.setValue(value, { emitEvent: false });
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.control.disable();
        } else {
            this.control.enable();
        }
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        const minValueValidator = this.localStore.selectSnapshotByKey("minValueValidator");
        const maxValueValidator = this.localStore.selectSnapshotByKey("maxValueValidator");

        return minValueValidator(control) ?? maxValueValidator(control);
    }

    protected updateValue(updateValue: number, valueChangeType: ValueChangeType): void {
        const currentValue = this.control.value;

        if (valueChangeType === ValueChangeType.Incremental) {
            const maxValue = this.localStore.selectSnapshotByKey("maxValue");
            const updatedValue = FunctionUtils.isNullOrUndefined(currentValue) ? updateValue : +currentValue + updateValue;

            this.control.setValue(Math.min(maxValue, updatedValue));

            return;
        }

        if (valueChangeType === ValueChangeType.Decremental) {
            const minValue = this.localStore.selectSnapshotByKey("minValue");
            const updatedValue = FunctionUtils.isNullOrUndefined(currentValue) ? -updateValue : +currentValue - updateValue;

            this.control.setValue(Math.max(minValue, updatedValue));

            return;
        }

        this.control.setValue(updateValue);
    }

    private watchNumberInputControlValueChange(): void {
        this.control.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            this.onChange(value === null ? null : +value);
            this.valueChange.emit(value);
        });
    }
}
