import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM } from "@dtm-frontend/shared/ui";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { UAV_ENDPOINTS, UavEndpoints } from "../uav.tokens";
import { UavListResponseBody, convertUavListResponseBodyToUavsWithPages } from "./uav-api.converters";
import { UavErrorType, UavListFilterParams, UavsWithPages } from "./uav.models";

@Injectable({
    providedIn: "root",
})
export class UavApiService {
    constructor(private readonly httpClient: HttpClient, @Inject(UAV_ENDPOINTS) private readonly endpoints: UavEndpoints) {}

    public getUavs(params: UavListFilterParams): Observable<UavsWithPages> {
        let httpParams = new HttpParams()
            .set("page", `${params[PAGE_NUMBER_QUERY_PARAM] ?? 0}`)
            .set("size", `${params[PAGE_SIZE_QUERY_PARAM]}`);

        if (params.serialNumber) {
            httpParams = httpParams.append("serialNumbers", params.serialNumber);
        }

        if (params.operatorNumber) {
            httpParams = httpParams.append("operatorNumber", params.operatorNumber);
        }

        return this.httpClient.get<UavListResponseBody>(this.endpoints.getUavList, { params: httpParams }).pipe(
            map((response: UavListResponseBody) => convertUavListResponseBodyToUavsWithPages(response)),
            catchError(() => throwError(() => ({ type: UavErrorType.CannotGetUavs })))
        );
    }
}
