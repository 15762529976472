import { AuthorityAcceptationItem, MissionPlanRemarks } from "../models";

export function isAuthorityAcceptationConfirmed(remarks?: MissionPlanRemarks): boolean | undefined {
    const authorityAcceptation = remarks?.authorityAcceptation;
    const partialAcceptations = remarks?.partialAcceptations;

    if (!authorityAcceptation && !partialAcceptations?.length) {
        return;
    }

    const acceptationWithComments = authorityAcceptation
        ? Object.values(authorityAcceptation).find((acceptation: AuthorityAcceptationItem | undefined) => acceptation?.comment)
        : undefined;

    if (acceptationWithComments) {
        return acceptationWithComments.isConfirmedByUser;
    }

    const partialAcceptationWithComments = partialAcceptations?.filter((acceptation) => acceptation.comment);

    if (!acceptationWithComments && !partialAcceptationWithComments?.length) {
        return;
    }

    return partialAcceptationWithComments?.every((acceptation) => acceptation.acknowledgedByUserAt);
}
