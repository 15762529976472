import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { FilterType, FiltersMap } from "@dtm-frontend/shared/ui";
import { DEFAULT_DEBOUNCE_TIME, FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { UavFilters } from "../../services/uav.models";

interface UavFiltersComponentState {
    filtersCount: number;
}

interface UavFiltersFormGroup {
    serialNumber: FormControl<string>;
    operatorNumber: FormControl<string>;
}

const FILTERS_MAP: FiltersMap[] = [
    {
        key: "serialNumber",
        filterLabel: "dtmAdminLibUav.uavListFilters.serialNumberLabel",
        type: FilterType.TextEllipsis,
    },
    {
        key: "operatorNumber",
        filterLabel: "dtmAdminLibUav.uavListFilters.operatorNumberLabel",
        type: FilterType.TextEllipsis,
    },
];

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-uav-filters",
    templateUrl: "./uav-filters.component.html",
    styleUrls: ["./uav-filters.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UavFiltersComponent {
    @Input({ required: true }) public set initialFilters(value: UavFilters | undefined) {
        this.filtersFormGroup.controls.serialNumber.setValue(value?.serialNumber ?? "", { emitEvent: false });
        this.filtersFormGroup.controls.operatorNumber.setValue(value?.operatorNumber ?? "", { emitEvent: false });
    }

    @Output() public filtersChange = new EventEmitter<UavFilters>();

    protected readonly FILTERS_MAP = FILTERS_MAP;
    protected readonly filtersCount$ = this.localStore.selectByKey("filtersCount");
    protected readonly filtersFormGroup = new FormGroup<UavFiltersFormGroup>({
        serialNumber: new FormControl<string>("", { nonNullable: true }),
        operatorNumber: new FormControl<string>("", { nonNullable: true }),
    });

    constructor(private readonly localStore: LocalComponentStore<UavFiltersComponentState>) {
        localStore.setState({ filtersCount: 0 });
        this.watchFormChanges();
    }

    protected clearFilters() {
        this.filtersFormGroup.reset();
    }

    private watchFormChanges() {
        this.filtersFormGroup.valueChanges
            .pipe(debounceTime(DEFAULT_DEBOUNCE_TIME), distinctUntilChanged(equal), untilDestroyed(this))
            .subscribe((value) => {
                this.updateFiltersCount();
                this.filtersChange.emit(value);
            });
    }

    private updateFiltersCount() {
        if (this.filtersFormGroup.valid) {
            const filtersCount = Object.values(this.filtersFormGroup.value).flat().filter(FunctionUtils.isTruthy).length;

            this.localStore.patchState({ filtersCount });
        }
    }
}
