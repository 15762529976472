<ng-container
    *ngrxLet="{
        minValue: minValue$,
        maxValue: maxValue$,
        stepValue: stepValue$,
        valueSuffix: valueSuffix$,
        predefinedOptionsPlacement: predefinedOptionsPlacement$,
        predefinedOptions: predefinedOptions$
    } as vm"
>
    <dtm-ui-input-field [isClearable]="false">
        <ng-content select="label" ngProjectAs="label"></ng-content>
        <div *ngIf="vm.predefinedOptions && vm.predefinedOptionsPlacement === 'before'" class="options-container input-prefix">
            <ng-container
                *ngTemplateOutlet="predefinedOptionsTemplate; context: { options: vm.predefinedOptions, valueSuffix: vm.valueSuffix }"
            />
        </div>
        <div class="field-prefix">
            <button
                type="button"
                class="button-icon input-control"
                [disabled]="(control.value !== null && control.value <= vm.minValue) || control.disabled"
                (click)="updateValue(vm.stepValue, ValueChangeType.Decremental)"
            >
                <dtm-ui-icon name="subtract"></dtm-ui-icon>
            </button>
        </div>
        <input
            matInput
            type="text"
            inputmode="numeric"
            autocomplete="off"
            class="input-field"
            digitOnly
            pattern="[0-9]*"
            [formControl]="control"
            (blur)="onTouched()"
        />
        <div class="field-suffix" *ngIf="vm.valueSuffix">
            <span class="value-suffix">{{ vm.valueSuffix }}</span>
        </div>
        <div class="field-suffix">
            <button
                type="button"
                class="button-icon input-control"
                [disabled]="(control.value !== null && control.value >= vm.maxValue) || control.disabled"
                (click)="updateValue(vm.stepValue, ValueChangeType.Incremental)"
            >
                <dtm-ui-icon name="add"></dtm-ui-icon>
            </button>
        </div>
        <ng-content select=".field-hint" ngProjectAs=".field-hint"></ng-content>
        <ng-content select=".field-error" ngProjectAs=".field-error"></ng-content>
    </dtm-ui-input-field>
    <div *ngIf="vm.predefinedOptions && vm.predefinedOptionsPlacement === 'after'" class="options-container">
        <ng-container
            *ngTemplateOutlet="predefinedOptionsTemplate; context: { options: vm.predefinedOptions, valueSuffix: vm.valueSuffix }"
        />
    </div>
</ng-container>

<ng-template #predefinedOptionsTemplate let-options="options" let-valueSuffix="valueSuffix">
    <button
        *ngFor="let option of options"
        type="button"
        class="selectable-tile option"
        [class.disabled]="control.disabled"
        [disabled]="control.disabled"
        (click)="updateValue(option, ValueChangeType.Set)"
    >
        {{ option }} {{ valueSuffix }}
    </button>
</ng-template>
