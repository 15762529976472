<div class="details-container" *ngIf="aircraftEntity$ | ngrxPush as aircraft">
    <div class="header">
        <h2>{{ aircraft.name }}</h2>
        <button type="button" class="button-icon" (click)="closeDetails()">
            <dtm-ui-icon name="close" />
        </button>
    </div>
    <div class="flight-info">
        <div class="field">
            <span class="label">{{ "dtmMapCesium.flightPin.heightLabel" | transloco }}:</span>

            <span>
                <ng-container *ngTemplateOutlet="valueTemplate; context: { $implicit: aircraft.position.altitude }"></ng-container>
                {{ "dtmMapCesium.flightPin.amslLabel" | transloco }}
            </span>
            <span>
                (
                <ng-container *ngTemplateOutlet="valueTemplate; context: { $implicit: aircraft.position.height }"> </ng-container>
                )
            </span>
        </div>
        <div class="field">
            <span class="label">{{ "dtmMapCesium.flightPin.baroLabel" | transloco }}:</span>
            <span>
                <ng-container
                    *ngTemplateOutlet="valueTemplate; context: { $implicit: aircraft.position.originalAltitudeBaro }"
                ></ng-container>
                {{ "dtmMapCesium.flightPin.amslLabel" | transloco }}
            </span>
        </div>
        <div class="field">
            <span class="label">{{ "dtmMapCesium.flightPin.geoLabel" | transloco }}:</span>
            <span>
                <ng-container
                    *ngTemplateOutlet="valueTemplate; context: { $implicit: aircraft.position.originalAltitudeGeo }"
                ></ng-container>
                {{ "dtmMapCesium.flightPin.amslLabel" | transloco }}
            </span>
        </div>
        <div class="field" *ngIf="aircraft.position.groundSpeed as value">
            <span class="label">{{ "dtmMapCesium.flightPin.speedLabel" | transloco }}:</span>
            <span> {{ "dtmMapCesium.flightPin.valueInMetersPerSecond" | transloco : { value } }} </span>
        </div>
        <div class="field" *ngIf="aircraft.position.accuracy | invoke : getAccuracy as accuracyValue">
            <span class="label"><dtm-ui-icon name="shape-cylinder" />:</span>
            <span>{{ "dtmMapCesium.flightPin.accuracyInMeters" | transloco : { value: accuracyValue } }}</span>
        </div>
        <button
            class="button-tertiary show-pilot-button"
            type="button"
            *ngIf="aircraft.operator?.longitude && aircraft.operator?.latitude"
            (click)="toggleOperatorVisibility()"
        >
            <dtm-ui-icon name="gamepad" />
            <span>
                {{ "dtmMapCesium.flightPin.toggleOperatorLocationButtonLabel" | transloco : { isVisible: isOperatorVisible$ | ngrxPush } }}
            </span>
        </button>
    </div>
    <ac-layer acFor="let operator of operatorData$" [context]="this">
        <ac-html-desc props="{position: operator.position}">
            <ng-template>
                <dtm-ui-icon name="gamepad" class="operator-icon" />
            </ng-template>
        </ac-html-desc>
    </ac-layer>
</div>
<ng-template #valueTemplate let-value>
    {{ value ? ("dtmMapCesium.flightPin.valueInMeters" | transloco : { value }) : ("dtmMapCesium.flightPin.emptyValue" | transloco) }}
</ng-template>
