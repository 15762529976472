import { GeoJSON } from "./geojson.models";
import { PhoneNumber } from "./phone-number.models";
import { MissionPlanRoute, TimeRange, Trajectory } from "./route-area.model";

export enum MissionType {
    BVLOS = "BVLOS",
    VLOS = "VLOS",
}

export interface Mission {
    missionId: string;
    planId: string;
    name?: string;
    flightType: MissionType;
    status: MissionStatus;
    uav: Uav;
    earlyActivationTime?: Date;
    startTime: TimeRange;
    endTime: TimeRange;
    plannedStartTime?: TimeRange;
    plannedEndTime?: TimeRange;
    realizationTime: Date | null;
    isPathBased?: boolean;
    pilotPhoneNumber?: PhoneNumber;
    pilotId: string;
    trajectories?: Trajectory[];
    dtmNames: string[];
    trackers?: Tracker[];
    operatorName: string;
    timeOverrideJustification?: string;
}

export enum TacticalErrorType {
    Unknown = "TacticalErrorUnknown",
    NotAuthorized = "TacticalErrorNotAuthorized",
    CannotActivateMission = "CannotActivateMission",
    NotFound = "NotFound",
    CannotCancelMission = "CannotCancelMission",
    CannotDeactivateMission = "CannotDeactivateMission",
    CannotConfirmReadings = "CannotConfirmReadings",
    CannotUpdateAdditionalInformation = "CannotUpdateAdditionalInformation",
    CannotReportEmergency = "CannotReportEmergency",
    CannotCancelEmergency = "CannotCancelEmergency",
}

export interface TacticalError {
    type: TacticalErrorType;
    code?: string;
    fieldErrors?: {
        code?: string;
        args?: Record<string, string>;
    }[];
}

export enum MissionStatus {
    Accepted = "ACCEPTED",
    Activated = "ACTIVATED",
    Canceled = "CANCELED",
    Finished = "FINISHED",
    Rejected = "REJECTED",
    Started = "STARTED",
}

export interface UavResponseBody {
    id: string;
    name: string;
    model: string;
    setupName: string;
    manufacturer?: string;
    trackersIdentifiers: string[];
}

export interface Uav {
    id: string;
    name: string;
    model: string;
    manufacturer: string;
    setupName: string;
    trackersIdentifiers: string[];
}

export interface MissionData extends Mission {
    route: MissionPlanRoute;
    isMain?: boolean;
    isSelected?: boolean;
    activatedAt: Date | null;
    emergency?: EmergencyType | undefined;
    activeViolations?: Violation[];
}

export interface TrackerResponseBody {
    trackerIdentifier: string;
    trackerTypeId: string;
    confirmed: boolean;
}

export interface Tracker {
    trackerIdentifier: string;
    trackerTypeId?: string;
    isConfirmed?: boolean;
}

export enum TrackerWarning {
    Verification = "Verification",
    Confirmation = "Confirmation",
    BadReadings = "BadReadings",
}

export interface FlightViolationUpdate {
    flightId: string;
    trackerIdentifier: string;
    targetMissionId: string;
    violation: Violation;
    createdAt: string;
    operatorId: string;
    uav: UavResponseBody;
    position: Position;
    receivers: string[];
}

interface Position {
    track: number;
    groundSpeed?: number;
    latitude: number;
    longitude: number;
    altitude: number;
    height: number;
    originalAltitude: number;
    originalAltitudeGeo?: number;
}

export enum FlightViolationEvent {
    FlightViolationOccurredEvent = "FlightViolationOccurredEvent",
    FlightViolationCanceledEvent = "FlightViolationCanceledEvent",
}

export enum Violation {
    UavOutsideStartingFlightZone = "UAV_OUTSIDE_STARTING_FLIGHT_ZONE",
    UavLeftOwnSafetyArea = "UAV_LEFT_OWN_SAFETY_AREA",
    UavLeftOwnFlightArea = "UAV_LEFT_OWN_FLIGHT_AREA",
    UavEnteredForeignSafetyArea = "UAV_ENTERED_FOREIGN_SAFETY_AREA",
}

export interface DeactivatedSectionsInfo {
    trackerId: string;
    sectionIndex: number;
}

export type MissionPlanOperationCategoryOption =
    | (
          | MissionPlanOpenOperationCategoryOption
          | MissionPlanStsOperationCategoryOption
          | MissionPlanLucOperationCategoryOption
          | {
                type: MissionCategory.Specific;
                specificPermitType: MissionPlanSpecificPermitType.Individual;
            }
      ) & { specificCaaPermitId?: string };

export interface MissionPlanOpenOperationCategoryOption {
    type: MissionCategory.Open;
    scenarioName: string;
}

export interface MissionPlanStsOperationCategoryOption {
    type: MissionCategory.Specific;
    scenarioName: string;
    specificPermitType: MissionPlanSpecificPermitType.Sts;
}

export interface MissionPlanLucOperationCategoryOption {
    type: MissionCategory.Specific;
    shouldSkipSora: boolean;
    specificPermitType: MissionPlanSpecificPermitType.Luc;
}

export enum MissionCategory {
    Specific = "SPECIFIC",
    Open = "OPEN",
    Certified = "CERTIFIED",
}

export enum MissionPlanSpecificPermitType {
    Sts = "STS",
    Individual = "INDIVIDUAL",
    Luc = "LUC",
}

export interface MissionViewSettings {
    areAllEnabled: boolean;
    areOnlyActive: boolean;
}

export enum EmergencyType {
    TechnicalIssue = "TECHNICAL_ISSUE",
    LostControl = "LOST_CONTROL",
    EmergencyLanding = "EMERGENCY_LANDING",
}

export interface Checkin {
    id: string;
    missionId?: string;
    uavName?: string;
    flightStartAt: Date;
    flightFinishAt: Date;
    phoneNumber?: PhoneNumber;
    heightAgl: number | undefined;
    isEmergency: boolean;
    pilotNumber: string;
    status: CheckinStatus;
    checkinType: MissionType;
    amslCeil?: number;
    geometry: GeoJSON;
}

export enum CheckinStatus {
    Submitted = "SUBMITTED",
    InRealization = "IN_REALIZATION",
    Expired = "EXPIRED",
    Completed = "COMPLETED",
    All = "ALL",
}

export interface CheckinMessageEvent {
    id: string;
    utmMissionId: string;
    missionId?: string;
    status: CheckinStatus;
    startTime: string;
    endTime: string;
    position: {
        longitude: number;
        latitude: number;
    };
    zone?: {
        floor: number;
        ceiling: number;
    };
    aproxArea: GeoJSON;
    pilot: {
        number: string;
        phoneNumber: string;
    };
    operator: {
        number: string;
        phoneNumber: string;
    };
    flightDetails: {
        category: string;
        subcategory: string;
        extraInfo: string;
        uavName: string;
    };
}

export enum CheckinsErrorType {
    CannotGetCheckins = "CannotGetCheckins",
}
