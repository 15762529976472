import { UavClass } from "@dtm-frontend/shared/mission";
import { PageResponseBody, UavType } from "@dtm-frontend/shared/ui";
import { UavListItem, UavsWithPages } from "./uav.models";

interface UavItemResponseBody {
    id: string;
    operatorId: string;
    operatorNumber: string;
    ownerName: string;
    model: {
        id: string;
        manufacturer: string;
        name: string;
        imageId?: string;
        type: string;
        uavClasses?: string[];
        ceCompliant: boolean;
        custom: boolean;
        imageUrl?: string;
    };
    serialNumbers: string[];
    shared: boolean;
    swarm: boolean;
    uavClasses?: string[];
}

export type UavListResponseBody = PageResponseBody<UavItemResponseBody>;

export function convertUavListResponseBodyToUavsWithPages(response: UavListResponseBody): UavsWithPages {
    return {
        content: response.content.map(
            (uavItemResponseBody): UavListItem => ({
                id: uavItemResponseBody.id,
                operatorId: uavItemResponseBody.operatorId,
                operatorNumber: uavItemResponseBody.operatorNumber,
                ownerName: uavItemResponseBody.ownerName,
                manufacturerAndModel: `${uavItemResponseBody.model.manufacturer} ${uavItemResponseBody.model.name}`,
                type: uavItemResponseBody.model.type as UavType,
                serialNumbers: uavItemResponseBody.serialNumbers,
                isCustom: uavItemResponseBody.model.custom,
                isSwarm: uavItemResponseBody.swarm,
                uavClasses: (uavItemResponseBody.uavClasses ?? []) as UavClass[],
            })
        ),
        pagination: {
            pageNumber: response.number,
            pageSize: response.size,
            totalElements: response.totalElements,
        },
    };
}
