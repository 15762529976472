import { ChangeDetectionStrategy, Component, forwardRef } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatLegacyRadioButton as MatRadioButton } from "@angular/material/legacy-radio";

@Component({
    selector: "dtm-ui-radio-field",
    templateUrl: "./radio-field.component.html",
    styleUrls: ["./radio-field.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        "[class.mat-radio-button]": "false",
        "[class.mat-radio-disabled]": "false",
        "[class.mat-radio-checked]": "false",
        "[class.radio-field-checked]": "checked",
        "[class.radio-field-disabled]": "disabled",
        "[class.mat-primary]": "false",
        "[class.mat-accent]": "false",
        "[class.mat-warn]": "false",
    },
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioFieldComponent),
            multi: true,
        },
    ],
})
export class RadioFieldComponent extends MatRadioButton {}
