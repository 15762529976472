import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { UavContainerComponent } from "./components/uav-container/uav-container.component";

const routes: Routes = [
    {
        path: "uav/list",
        component: UavContainerComponent,
        canActivate: [DtmRoleGuard],
        data: {
            titleTranslationKey: "dtmAdminLibUav.routeTitles.uavList",
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class UavRoutingModule {}
