<dtm-ui-presentation-tile-with-action
    *ngrxLet="{ operatorNumber: operatorNumber$ } as vm"
    [titleTile]="'dtmAdminLibShared.operatorProfile.uavList.title' | transloco"
>
    <button
        *ngIf="vm.operatorNumber"
        actionButtonSlot
        class="button-secondary"
        type="button"
        (click)="redirectToUavList(vm.operatorNumber)"
    >
        <dtm-ui-icon name="eye"></dtm-ui-icon>
        {{ "dtmAdminLibShared.operatorProfile.uavList.actionButtonLabel" | transloco }}
    </button>
</dtm-ui-presentation-tile-with-action>
