import { InjectionToken } from "@angular/core";

export interface UavEndpoints {
    getUavList: string;
    getUavDetails: string;
    getUavSetupDocument: string;
    getUavModelDocument: string;
}

export const UAV_ENDPOINTS = new InjectionToken<UavEndpoints>("UAV endpoints");
