<ng-container *ngIf="threadsError$ | ngrxPush; else threadsTemplate">
    <dtm-ui-error (reload)="refreshList()" [errorMessage]="'dtmAdminLibConversation.threadsContainer.threadsDownloadError' | transloco">
    </dtm-ui-error>
</ng-container>

<ng-template #threadsTemplate>
    <dtm-ui-loader-container [isShown]="isThreadsListLoading$ | ngrxPush">
        <div class="grid main-container">
            <dtm-admin-lib-filters-container
                [userAssignedCategories]="userAssignedCategories$ | ngrxPush"
                [categories]="categories$ | ngrxPush"
                [initialFilters]="initialFilters$ | ngrxPush"
                (filtersChange)="changeFilters($event)"
                (areFiltersAppliedChange)="changeAreFiltersApplied($event)"
            ></dtm-admin-lib-filters-container>

            <div class="threads-list-container">
                <dtm-admin-lib-threads-list
                    [storedAvatarPictures]="storedAvatarPictures$ | ngrxPush"
                    [areFiltersApplied]="filtersApplied$ | ngrxPush"
                    [threadsList]="threadsList$ | ngrxPush"
                    [assignees]="assignees$ | ngrxPush"
                    (assigneeAssign)="changeAssignee($event)"
                    [processingThreadsAssignments]="processingThreadsAssignments$ | ngrxPush"
                    [pagination]="pagination$ | ngrxPush"
                    (pageChange)="changePage($event)"
                ></dtm-admin-lib-threads-list>
            </div>
        </div>
    </dtm-ui-loader-container>
</ng-template>
