import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ActivatedRoute, Router } from "@angular/router";
import { OperatorStatus } from "@dtm-frontend/shared/ui";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import equal from "fast-deep-equal";
import { Observable, map, pairwise, startWith } from "rxjs";
import { first } from "rxjs/operators";
import { AttorneyPowerStatus, NULL_VALUE, OperatorDetails, OperatorType } from "../../../shared";
import { OperatorProfileUpdateHelperService } from "../../services/operator-profile-update-helper.service";
import { OperatorFilterFormKeys, OperatorFilters, OperatorsFilterParams } from "../../services/operator.models";
import { OperatorActions } from "../../state/operator.actions";
import { OperatorState } from "../../state/operator.state";
import { DEFAULT_PAGE_SIZE } from "../../utils/default-operator.page";

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-operators-container",
    templateUrl: "./operators-container.component.html",
    styleUrls: ["./operators-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [OperatorProfileUpdateHelperService],
})
export class OperatorsContainerComponent implements OnInit, OnDestroy {
    protected readonly pages$ = this.store.select(OperatorState.pages);
    protected readonly operators$ = this.store.select(OperatorState.operatorList);
    protected readonly operatorListError$ = this.store.select(OperatorState.operatorListError);
    protected readonly operatorError$ = this.store.select(OperatorState.operatorError);
    protected readonly selectedOperatorDetails$ = this.store.select(OperatorState.selectedOperatorDetails);
    protected readonly isProcessing$ = this.store.select(OperatorState.isProcessing);
    protected readonly initialFilters$ = this.prepareInitialFilters();

    constructor(
        private readonly store: Store,
        private readonly bottomSheet: MatBottomSheet,
        private readonly route: ActivatedRoute,
        private readonly router: Router
    ) {}

    public ngOnInit() {
        this.watchOperatorListParamsChange();
        this.watchQueryParamsChange();
        this.watchSelectedOperatorDetailsChange();

        if (!this.store.selectSnapshot(OperatorState.capabilities)) {
            this.store.dispatch(new OperatorActions.GetCapabilities());
        }
    }

    public ngOnDestroy() {
        this.bottomSheet.dismiss();
    }

    protected changePage(event: PageEvent) {
        const operatorListParams = this.store.selectSnapshot(OperatorState.operatorListParams) ?? {};
        this.setOperatorListParams({
            ...operatorListParams,
            page: `${event.pageIndex}`,
            size: `${event.pageSize}`,
            selectedOperator: undefined,
        });
    }

    protected applyFilters(filters: OperatorFilters) {
        const operatorListParams = this.store.selectSnapshot(OperatorState.operatorListParams) ?? {};
        this.setOperatorListParams({
            ...this.prepareOperatorsFiltersParams(filters),
            selectedOperator: operatorListParams.selectedOperator,
        });
    }

    protected refreshList() {
        const persistedQueryParams = this.store.selectSnapshot(OperatorState.operatorListParams);
        if (persistedQueryParams) {
            this.setOperatorListParams(persistedQueryParams);
        } else {
            this.setOperatorListParams({});
        }
    }

    protected refreshOperatorDetails() {
        const selectedOperator = this.extractSelectedOperatorParam(
            this.store.selectSnapshot(OperatorState.operatorListParams)?.selectedOperator
        );
        if (!selectedOperator) {
            return;
        }

        this.operatorSelected(...selectedOperator);
    }

    protected operatorSelected(operatorId: string | undefined, operatorType: OperatorType | undefined) {
        if (operatorId && operatorType) {
            this.store.dispatch(new OperatorActions.SelectOperator(operatorId, operatorType));
        } else {
            this.store.dispatch(new OperatorActions.ClearOperatorDetails());
        }
    }

    private watchOperatorListParamsChange() {
        this.store
            .select(OperatorState.operatorListParams)
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe((params: OperatorsFilterParams | undefined) => {
                this.router.navigate(["."], {
                    relativeTo: this.route,
                    queryParams: params,
                    replaceUrl: true,
                });
            });
    }

    private watchQueryParamsChange() {
        this.route.queryParams
            .pipe(startWith({}), pairwise(), untilDestroyed(this))
            .subscribe(([previousQueryParams, queryParams]: [OperatorsFilterParams, OperatorsFilterParams]) => {
                this.unifyOperatorListsParams(queryParams);
                const areQueryParamsEmpty = !Object.values(queryParams).length;

                // NOTE: if query params are empty, we pass the flow to router navigation to set basic query params
                if (areQueryParamsEmpty) {
                    return;
                }

                const hasFilterParamsBeenChanged = !equal(
                    { ...previousQueryParams, selectedOperator: undefined },
                    { ...queryParams, selectedOperator: undefined }
                );
                if (hasFilterParamsBeenChanged) {
                    this.store.dispatch(new OperatorActions.GetOperators(queryParams));
                }

                const selectedOperatorParam = this.extractSelectedOperatorParam(queryParams.selectedOperator);
                const persistedSelectedOperator = this.store.selectSnapshot(OperatorState.selectedOperatorDetails);
                if (selectedOperatorParam && selectedOperatorParam[0] !== persistedSelectedOperator?.id) {
                    this.operatorSelected(...selectedOperatorParam);
                }
            });
    }

    private unifyOperatorListsParams(queryParams: OperatorsFilterParams) {
        const persistedQueryParams = this.store.selectSnapshot(OperatorState.operatorListParams);
        const areQueryParamsEmpty = !Object.values(queryParams).length;

        if (areQueryParamsEmpty) {
            if (persistedQueryParams) {
                this.setOperatorListParams(persistedQueryParams);
            } else {
                this.setOperatorListParams({});
            }
        } else if (!persistedQueryParams) {
            this.setOperatorListParams(queryParams);
        }
    }

    private watchSelectedOperatorDetailsChange() {
        this.store
            .select(OperatorState.selectedOperatorDetails)
            .pipe(untilDestroyed(this))
            .subscribe((selectedOperator) => {
                const persistedOperatorListParams = this.store.selectSnapshot(OperatorState.operatorListParams) ?? {};

                this.setOperatorListParams({
                    ...persistedOperatorListParams,
                    selectedOperator: this.packSelectedOperatorParam(selectedOperator),
                });
            });
    }

    private setOperatorListParams(operatorListParams: OperatorsFilterParams) {
        this.store.dispatch(
            new OperatorActions.SetOperatorListParams({
                page: "0",
                size: `${DEFAULT_PAGE_SIZE}`,
                ...operatorListParams,
            })
        );
    }

    private extractSelectedOperatorParam(selectedOperatorParam: string | undefined): [string, OperatorType] | undefined {
        const selectedOperator = selectedOperatorParam?.split(",");

        if (!selectedOperator || selectedOperator.length < 2) {
            return;
        }

        return [selectedOperator[0], selectedOperator[1] as OperatorType];
    }

    private packSelectedOperatorParam(operatorDetails: OperatorDetails | undefined): string | undefined {
        return operatorDetails ? `${operatorDetails.id},${operatorDetails.type}` : undefined;
    }

    private prepareOperatorsFiltersParams(filters: OperatorFilters): OperatorsFilterParams {
        let params: OperatorsFilterParams = {
            page: "0",
        };

        if (filters[OperatorFilterFormKeys.SearchByText]) {
            params = { ...params, searchByText: filters[OperatorFilterFormKeys.SearchByText] };
        }

        if (filters[OperatorFilterFormKeys.Type] !== NULL_VALUE) {
            params = { ...params, type: filters[OperatorFilterFormKeys.Type] };
        }

        if (filters[OperatorFilterFormKeys.IsDefaultCountryRegistration] !== NULL_VALUE) {
            params = { ...params, isDefaultCountryRegistration: `${filters[OperatorFilterFormKeys.IsDefaultCountryRegistration]}` };
        }

        if (filters[OperatorFilterFormKeys.Status].length) {
            params = { ...params, status: filters[OperatorFilterFormKeys.Status]?.join(",") };
        }

        if (filters[OperatorFilterFormKeys.PowerOfAttorneyStatus] !== NULL_VALUE) {
            params = { ...params, powerOfAttorneyStatus: filters[OperatorFilterFormKeys.PowerOfAttorneyStatus] };
        }

        if (filters[OperatorFilterFormKeys.IsWaitingCompetencyConfirmation] !== NULL_VALUE) {
            params = { ...params, isWaitingCompetencyConfirmation: `${filters[OperatorFilterFormKeys.IsWaitingCompetencyConfirmation]}` };
        }

        return params;
    }

    private prepareInitialFilters(): Observable<OperatorFilters> {
        return this.store.select(OperatorState.operatorListParams).pipe(
            first((params) => !!params),
            map((params) => ({
                [OperatorFilterFormKeys.SearchByText]: params?.searchByText ?? "",
                [OperatorFilterFormKeys.Type]: (params?.type as OperatorType) ?? NULL_VALUE,
                [OperatorFilterFormKeys.IsDefaultCountryRegistration]: params?.isDefaultCountryRegistration
                    ? params.isDefaultCountryRegistration === "true"
                    : NULL_VALUE,
                [OperatorFilterFormKeys.Status]: (params?.status?.split(",") as OperatorStatus[]) ?? [],
                [OperatorFilterFormKeys.PowerOfAttorneyStatus]: (params?.powerOfAttorneyStatus as AttorneyPowerStatus) ?? NULL_VALUE,
                [OperatorFilterFormKeys.IsWaitingCompetencyConfirmation]: params?.isWaitingCompetencyConfirmation
                    ? params.isWaitingCompetencyConfirmation === "true"
                    : NULL_VALUE,
            }))
        );
    }
}
