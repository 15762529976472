import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
    GroundRiskMitigationType,
    InvalidFormScrollableDirective,
    Operator,
    Permit,
    PermitUavModel,
    UavDetailsModel,
    UavType,
} from "@dtm-frontend/shared/ui";
import { LocalComponentStore, ONLY_WHITE_SPACES_VALIDATION_PATTERN, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatestWith, map } from "rxjs";

const MIN_FIELD_NUMBER = 0.1;
const MAX_OPERATED_NUMBER = 10000;
const MAX_TAKE_OFF_WEIGHT = 50;
const MIN_TAKE_OFF_WEIGHT = 0.1;
const MAX_FLIGHT_SPEED = 300;
const MIN_FLIGHT_SPEED = 1;
const MAX_UAV_WIDTH = 1000;
const MAX_ADDITIONAL_TECH_REQUIREMENTS_LENGTH = 500;
const MAX_DVR_REPORT_LENGTH = 2000;
const MAX_COMMENTS_LENGTH = 2000;
const MAX_SERIAL_NUMBER_LENGTH = 15000;
const MAX_TEXT_INPUT_LENGTH = 255;

interface UavDetailsForm {
    manufacturer: FormControl<string | null>;
    uavModel: FormControl<PermitUavModel | null>;
    uavType: FormControl<UavType | null>;
    operatedNumber: FormControl<number | null>;
    isDroneSwarm: FormControl<boolean | null>;
    serialNumbers: FormControl<string>;
    maxUavWidth: FormControl<number | null>;
    takeOffWeight: FormControl<number | null>;
    maxFlightSpeed: FormControl<number | null>;
    additionalTechRequirements: FormControl<string>;
    designVerificationReport: FormControl<string>;
    certificateOfAirworthiness: FormControl<string>;
    noiseCertificate: FormControl<string>;
    riskMitigation: FormControl<GroundRiskMitigationType | null>;
    comments: FormControl<string>;
}

interface UavDetailsComponentState {
    uavModels: PermitUavModel[] | undefined;
    filteredUavModels: PermitUavModel[] | undefined;
    selectedOperator: Operator | undefined;
    existingPermitDetails: Permit | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-permit-uav-details-step[uavModels]",
    templateUrl: "./permit-uav-details-step.component.html",
    styleUrls: ["./permit-uav-details-step.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PermitUavDetailsStepComponent {
    @Input() public set uavModels(value: PermitUavModel[] | undefined) {
        this.localStore.patchState({ uavModels: value });
    }
    @Input() public set selectedOperator(value: Operator | undefined) {
        this.localStore.patchState({ selectedOperator: value });
    }
    @Input()
    public set existingPermitDetails(value: Permit | undefined) {
        this.localStore.patchState({ existingPermitDetails: value });
        if (value) {
            this.assignExistingPermitDetails(value);
        }
    }

    @Output() public readonly uavDetailsFormSave = new EventEmitter<UavDetailsModel>();
    @Output() public readonly back = new EventEmitter<void>();

    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;

    protected readonly MIN_FIELD_NUMBER = MIN_FIELD_NUMBER;
    protected readonly MAX_OPERATED_NUMBER = MAX_OPERATED_NUMBER;
    protected readonly MAX_COMMENTS_LENGTH = MAX_COMMENTS_LENGTH;
    protected readonly MAX_ADDITIONAL_TECH_REQUIREMENTS_LENGTH = MAX_ADDITIONAL_TECH_REQUIREMENTS_LENGTH;
    protected readonly MAX_DVR_REPORT_LENGTH = MAX_DVR_REPORT_LENGTH;
    protected readonly MAX_UAV_WIDTH = MAX_UAV_WIDTH;
    protected readonly MIN_TAKE_OFF_WEIGHT = MIN_TAKE_OFF_WEIGHT;
    protected readonly MAX_TAKE_OFF_WEIGHT = MAX_TAKE_OFF_WEIGHT;
    protected readonly MAX_FLIGHT_SPEED = MAX_FLIGHT_SPEED;
    protected readonly MIN_FLIGHT_SPEED = MIN_FLIGHT_SPEED;
    protected readonly MAX_SERIAL_NUMBER_LENGTH = MAX_SERIAL_NUMBER_LENGTH;
    protected readonly GroundRiskMitigationType = GroundRiskMitigationType;
    protected readonly filteredUavModels$ = this.localStore.selectByKey("filteredUavModels");
    protected readonly selectedOperator$ = this.localStore.selectByKey("selectedOperator").pipe(map((operator) => operator?.name));
    protected readonly manufacturerList$ = this.localStore.selectByKey("uavModels").pipe(
        RxjsUtils.filterFalsy(),
        map((list) => new Set(list.map((item) => item.manufacturer)))
    );
    protected readonly uavDetailsForm = new FormGroup<UavDetailsForm>({
        manufacturer: new FormControl(null, Validators.required),
        uavModel: new FormControl({ value: null, disabled: true }, Validators.required),
        uavType: new FormControl({ value: null, disabled: true }, { nonNullable: true }),
        operatedNumber: new FormControl(null, [Validators.required, Validators.min(MIN_FIELD_NUMBER), Validators.max(MAX_OPERATED_NUMBER)]),
        isDroneSwarm: new FormControl(null, Validators.required),
        serialNumbers: new FormControl("", {
            nonNullable: true,
            validators: [
                Validators.required,
                Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
                Validators.maxLength(MAX_SERIAL_NUMBER_LENGTH),
            ],
        }),
        maxUavWidth: new FormControl(null, [Validators.required, Validators.min(MIN_FIELD_NUMBER), Validators.max(MAX_UAV_WIDTH)]),
        takeOffWeight: new FormControl(null, [
            Validators.required,
            Validators.min(MIN_TAKE_OFF_WEIGHT),
            Validators.max(MAX_TAKE_OFF_WEIGHT),
        ]),
        maxFlightSpeed: new FormControl(null, [Validators.required, Validators.min(MIN_FLIGHT_SPEED), Validators.max(MAX_FLIGHT_SPEED)]),
        additionalTechRequirements: new FormControl("", {
            nonNullable: true,
            validators: [
                Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
                Validators.maxLength(MAX_ADDITIONAL_TECH_REQUIREMENTS_LENGTH),
            ],
        }),
        designVerificationReport: new FormControl("", {
            nonNullable: true,
            validators: [Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN), Validators.maxLength(MAX_DVR_REPORT_LENGTH)],
        }),
        certificateOfAirworthiness: new FormControl("", {
            nonNullable: true,
            validators: [Validators.maxLength(MAX_TEXT_INPUT_LENGTH), Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN)],
        }),
        noiseCertificate: new FormControl("", {
            nonNullable: true,
            validators: [Validators.maxLength(MAX_TEXT_INPUT_LENGTH), Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN)],
        }),
        riskMitigation: new FormControl(null, Validators.required),
        comments: new FormControl("", {
            nonNullable: true,
            validators: [Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN), Validators.maxLength(MAX_COMMENTS_LENGTH)],
        }),
    });

    constructor(private readonly localStore: LocalComponentStore<UavDetailsComponentState>, private readonly transloco: TranslocoService) {
        this.localStore.setState({
            uavModels: undefined,
            filteredUavModels: undefined,
            selectedOperator: undefined,
            existingPermitDetails: undefined,
        });

        this.listenOnManufacturerChanges();
        this.listenOnUavModelChanges();
    }

    protected listenOnManufacturerChanges(): void {
        this.uavDetailsForm.controls.manufacturer.valueChanges
            .pipe(combineLatestWith(this.localStore.selectByKey("uavModels").pipe(RxjsUtils.filterFalsy())), untilDestroyed(this))
            .subscribe(([manufacturer, models]) => {
                if (!manufacturer) {
                    this.uavDetailsForm.controls.uavModel.reset({ value: null, disabled: true });

                    return;
                }

                this.uavDetailsForm.controls.uavModel.enable({ emitEvent: false });
                const filteredModels = models.filter((model) => model.manufacturer === manufacturer);
                this.localStore.patchState({ filteredUavModels: filteredModels });
            });
    }

    protected validateFormAndGoToNextStep(): void {
        if (this.uavDetailsForm.invalid) {
            this.uavDetailsForm.markAllAsTouched();
            this.invalidFormScrollable.scrollToFirstInvalidField();

            return;
        }

        this.uavDetailsFormSave.emit(this.uavDetailsForm.getRawValue() as UavDetailsModel);
    }

    protected listenOnUavModelChanges(): void {
        this.uavDetailsForm.controls.uavModel.valueChanges.pipe(untilDestroyed(this)).subscribe((model: PermitUavModel | null) => {
            if (!model) {
                this.uavDetailsForm.controls.uavType.patchValue(null);

                return;
            }

            this.uavDetailsForm.controls.uavType.patchValue(
                this.transloco.translate("dtmAdminLibPermits.uavDetails.uavTypeValue", {
                    value: model.type,
                })
            );
            this.uavDetailsForm.controls.maxFlightSpeed.setValue(model.technicalSpecification.maxFlightSpeed, { emitEvent: false });
            this.uavDetailsForm.controls.takeOffWeight.setValue(model.technicalSpecification.takeOffMass, { emitEvent: false });
            this.uavDetailsForm.controls.maxUavWidth.setValue(model.technicalSpecification.maxDroneWidth, { emitEvent: false });
        });
    }

    protected keepOrder(): number {
        return 0;
    }

    private assignExistingPermitDetails(permit: Permit) {
        this.uavDetailsForm.patchValue({
            ...permit.uavDetails,
            manufacturer: permit.uavDetails.uavModel.manufacturer,
            uavType: permit.uavDetails.uavModel.type,
        });
    }
}
