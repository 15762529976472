import { AirspaceElement } from "@dtm-frontend/shared/map/geo-zones";
import { IconName } from "@dtm-frontend/shared/ui";
import {
    AirRisk,
    MissionPlanStatus,
    MissionType,
    PlanVerificationStatus,
    PopulationDensity,
    SoraSettings,
    TechnicalSpecification,
} from "./mission.models";

export interface SoraResult {
    finalGrl: number;
    finalGrc: number;
    intrinsicGrc: number;
    intrinsicGrl?: number;
    effectiveGrl?: number;
    criticalArea?: number;
    initialArc?: number;
    peopleAssembly?: boolean;
    groundRiskTradeoff: number;
    residualArc: number | undefined;
    sail: number;
    tacticalMitigationPerformanceRequirementProperty?: TacticalMitigationPerformanceRequirementProperty;
    populationDensity?: PopulationDensity;
    flightTerminationSystem: FlightTerminationSystem;
    adjacentArea?: Omit<SoraResult, "adjacentArea" | "airRisk" | "tacticalMitigationPerformanceRequirementProperty">;
    airRisk?: AirRisk;
}

export enum DensityBoxColor {
    Green = "GREEN",
    Red = "RED",
    Unknown = "UNKNOWN",
    Violet = "VIOLET",
    Yellow = "YELLOW",
    Blue = "BLUE",
}

export interface Requirement {
    text: string;
    iconName: IconName;
    className: string;
}

export interface AirwaysCollisionTranslationArgs {
    designators?: string;
}

export enum EvaluationIssueClosingEntity {
    CivilAviationAuthority = "CAA",
    Supervisor = "SUPERVISOR",
}

export interface TrafficCollisionTranslationArgs {
    planIds?: string;
}

export enum AnalysisOptionStatus {
    Canceled = "CANCELED",
    Completed = "COMPLETED",
    Failure = "FAILURE",
    Hold = "HOLD",
    None = "NONE",
    Reverted = "REVERTED",
}

export interface MissionPlanVerificationResponseBodyAnalysisIssue extends MissionPlanAnalysisIssueBase {
    status: AnalysisIssueStatus;
    source: AnalysisIssueSource;
    endAt: string;
}

export interface MissionPlanVerificationResponseBodyEvaluationIssue extends MissionPlanAnalysisIssueBase {
    status: EvaluationIssueStatus;
    closedBy: EvaluationIssueClosingEntity;
    path: string;
}

export enum AnalysisIssueSource {
    Traffic = "TRAFFIC",
    Airspace = "AIRSPACE",
    Sora = "SORA",
    CaaPermit = "CAA_PERMIT",
}

export enum AnalysisIssueStatus {
    Failure = "FAILURE",
    Hold = "HOLD",
    Success = "SUCCESS",
    Warning = "WARNING",
    Info = "INFO",
}

export enum TacticalMitigationPerformanceRequirementProperty {
    VLOS = "VLOS",
    A = "A",
    B = "B",
    C = "C",
    D = "D",
}

export const CERTIFIED_CATEGORY_FINAL_GRC = 8;

export interface FlightTerminationSystem {
    isAvailable: boolean;
    isRequired: boolean;
    issues: string[];
}

export interface MissionPlanTrafficAnalysisOption extends MissionPlanAnalysisOption {
    nearbyMissionsPlanIds: string[];
    collisionMissionsPlanIds: string[];
}

export enum MissionPlanAnalysisOptionStatus {
    Canceled = "CANCELED",
    Error = "ERROR",
    FatalError = "FATAL_ERROR",
    Hold = "HOLD",
    Pending = "PENDING",
    Reverted = "REVERTED",
    Success = "SUCCESS",
}

export interface FlightRules {
    isCheckinApprovalRequired: boolean;
    translationArgs: { designators?: string[] };
    translationId: string;
}

export interface MissionPlanEvaluationOption {
    zoneIssues: Record<string, MissionPlanAnalysisEvaluationIssue[]>;
    issues: MissionPlanAnalysisEvaluationIssue[];
}

export interface MissionPlanAnalysisStatus {
    planId: string;
    planStatus: MissionPlanStatus;
    isPlanSubmittable: boolean;
    status: PlanVerificationStatus;
    isManualVerificationRequired: boolean;
    traffic: MissionPlanTrafficAnalysisOption | undefined;
    airspace: MissionPlanAirspaceAnalysisOption;
    caaPermit: MissionPlanAnalysisOption | undefined;
    sora: (MissionPlanAnalysisOption & { result?: SoraResult }) | undefined;
    version: number;
    evaluation: MissionPlanEvaluationOption;
    applyForSpecificPermit: boolean;
    systemVerificationId: string;
    flightRules: FlightRules[];
    routeId?: string;
}
export interface MissionPlanAnalysisOption {
    status: MissionPlanAnalysisOptionStatus;
    issues: MissionPlanAnalysisIssue[];
}

export enum MissionPlanAnalysisIssueStatus {
    Error = "ERROR",
    FatalError = "FATAL_ERROR",
    Hold = "HOLD",
    Success = "SUCCESS",
    Warning = "WARNING",
    Info = "Info",
}

export interface MissionPlanAirspaceAnalysisOption extends MissionPlanAnalysisOption {
    zoneIssues?: Record<string, MissionPlanAnalysisIssue[]>;
    isAdjacentAreaControlled: boolean;
    isAdjacentAreaUncontrolled: boolean;
    isOperationalAreaControlled: boolean;
    isOperationalAreaUncontrolled: boolean;
}

export enum EvaluationIssueStatus {
    Open = "OPEN",
    Suppressed = "SUPPRESSED",
    Rejected = "REJECTED",
}

export interface MissionPlanAnalysisEvaluationIssue extends MissionPlanAnalysisIssueBase {
    status: EvaluationIssueStatus;
    designators?: string[];
}

export interface MissionPlanAnalysisIssue extends MissionPlanAnalysisIssueBase {
    status: MissionPlanAnalysisIssueStatus;
    designators?: string[];
}
export interface MissionPlanAnalysisIssueBase {
    codename: string;
    translationId: string;
    translationArgs: Record<string, string> | null;
}

export interface PartitionedZones {
    collisionZones: AirspaceElement[];
    additionalZones: AirspaceElement[];
    doesAupExist: boolean;
    airacEndTime?: Date;
}

export enum GroundRiskBufferType {
    Standard = "STANDARD",
    Individual = "INDIVIDUAL",
}

export interface CaaPermitData {
    operationalGeometry: OperationalGeometryData;
    technicalSpecification: TechnicalSpecification;
    soraSettings?: SoraSettings;
}

export interface OperationalGeometryData {
    safetyAreaWidth: number;
    safetyAreaHeight: number;
    groundRiskBuffer?: number;
    groundRiskBufferType: GroundRiskBufferType | null;
    adjacentAreaBufferHorizontal: number;
    adjacentAreaHeight: number;
    flightAreaMaxRadius: number;
    flightAreaMaxDeclaredHeight: number;
    operationAreaMaxRadius: number;
    flightAreaMaxHeight: number;
    operationAreaMaxHeight: number;
    flightAreaEstimatedDistance: number;
    flightAreaMaxWidth: number;
    operationAreaMaxWidth: number;
    operationAreaMaxDeclaredHeight: number;
    flightAreaSize: number;
    operationAreaSize: number;
}

export interface TrafficMissionData {
    id: string;
    flightStartAtMin?: Date;
    flightFinishAtMax?: Date;
    flightType?: MissionType;
    uavName?: string;
    routeId: string;
}
