export const DEFAULT_DEBOUNCE_TIME = 300;
export const TOOLTIP_SHOW_DELAY = 700;
export const VERIFICATION_CODE_MASK = "0 0 0 0 0 0";
export const ONLY_WHITE_SPACES_VALIDATION_PATTERN = /[^\s]/;
export const MAX_TEXT_LENGTH = 255;
export const POST_CODE_MASK = "0 0 - 0 0 0";
export const DEFAULT_COUNTRY_CODE = "POL";
export const DEFAULT_PHONE_COUNTRY_CODE = "PL";
export const PAYMENT_CURRENCY_SYMBOL = "PLN";
export const AVIATION_REPORTING_URL = "https://e2.aviationreporting.eu/reporting";
export const NOT_FOUND_PAGE = "not-found";
export const LEGAL_AGE = 18;
export const NATIONAL_COURT_REGISTRATION_MASK = "0 0 0 0 0 0 0 0 0 0";
export const NATIONAL_COURT_REGISTRATION_LENGTH = 10;
export const DEFAULT_COMPANY_NUMBER_LENGTH = 10;
export const DEFAULT_COMPANY_NUMBER_MASK = "0 0 0 0 0 0 0 0 0 0";
export const PREVIOUS_MIN_USER_AGE = 16;
export const MIN_USER_AGE = 14;
export const MIN_BIRTH_YEAR = 1900;
export const MIN_DATE_OF_BIRTH = new Date(MIN_BIRTH_YEAR, 0, 1);
