import {
    Communication,
    CommunicationFrequency,
    CommunicationLinkType,
    CommunicationType,
    DriveType,
    Equipment,
    EquipmentItem,
    EquipmentParachute,
    EquipmentType,
    FailSafe,
    NavigationAccuracy,
    NavigationAccuracyClass,
    NavigationAccuracyType,
    Operator,
    TechnicalSpecification,
    Tracker,
    Tracking,
    Uav,
    UavClass,
    UavModelDocumentType,
    UavModelDocuments,
    UavSetup,
    UavSetupDocumentType,
    UavSetupDocuments,
} from "@dtm-frontend/shared/uav";
import { PageResponseBody, UavType } from "@dtm-frontend/shared/ui";
import { ISO8601TimeDuration, StringUtils } from "@dtm-frontend/shared/utils";
import { UavCapabilities, UavListItem, UavsWithPages } from "./uav.models";

interface UavItemResponseBody {
    id: string;
    operatorId: string;
    operatorNumber: string;
    ownerName: string;
    model: {
        id: string;
        manufacturer: string;
        name: string;
        imageId?: string;
        type: string;
        uavClasses?: string[];
        ceCompliant: boolean;
        custom: boolean;
        imageUrl?: string;
    };
    serialNumbers: string[];
    shared: boolean;
    swarm: boolean;
    uavClasses?: string[];
}

export type UavListResponseBody = PageResponseBody<UavItemResponseBody>;

export interface UavDetailsResponseBody {
    id: string;
    name: string;
    model: {
        id: string;
        name: string;
        manufacturer: string;
        type: UavType;
        custom: boolean;
        photoId: string | null;
        manual?: UavDocumentBody;
        projectDocumentation?: UavDocumentBody;
        ceCompliant: boolean;
        uavClasses: UavClass[] | undefined;
    };
    operator: Operator;
    serialNumbers: string[];
    uavClasses: UavClass[] | undefined;
    swarm: boolean;
    setups: UavSetupResponseBody[];
    ownerName: string;
    shared: boolean;
}

interface UavDocumentBody {
    id: string;
    name: string;
    size: number;
    editable: boolean;
}

export interface UavSetupResponseBody {
    id: string;
    name: string;
    primary: boolean;
    technicalSpecification: TechnicalSpecificationResponseBody;
    communications: CommunicationResponseBody[];
    documents?: Partial<Record<UavSetupDocumentType, SetupDocumentBody[]>>;
    trackings: TrackingResponseBody[];
    equipment: {
        parachute: ParachuteEquipmentResponseBody | null;
        items: EquipmentItemResponseBody[];
    };
}

interface SetupDocumentBody {
    id: string;
    name: string;
    size: number;
    mediaType: string;
    type: UavSetupDocumentType;
}

interface TechnicalSpecificationResponseBody {
    numberOfEngines?: number;
    driveType: DriveType;
    minRecommendedAmbientTemperature?: number;
    maxRecommendedAmbientTemperature?: number;
    takeOffMass: number;
    maxTakeOffMass: number;
    maxDroneWidth: number;
    maxFlightTime: ISO8601TimeDuration;
    flightSpeedLimit?: boolean;
    maxFlightSpeed: number;
    minFlightSpeed?: number;
    maxClimbSpeed?: number;
    maxDescentSpeed?: number;
    maxWind?: number;
    maxFlightAltitude?: number;
    rainFlightPossibility?: boolean;
    failSafe?: FailSafe[];
    geofencing?: boolean;
    detectAndAvoid?: boolean;
    proximitySensors?: boolean;
    moduleRedundancy?: boolean;
    geocage?: boolean;
    emergencyMotorStop?: boolean;
}

interface CommunicationResponseBody {
    id: string;
    type: CommunicationType;
    transmissionLinkRange: number;
    linkType?: Exclude<CommunicationLinkType, CommunicationLinkType.Unknown>;
    linkDelay?: number;
    modelOrigin: boolean;
    frequencies?: CommunicationFrequency[];
}

interface TrackingResponseBody {
    id?: string;
    identifier: string;
    weight: number;
    trackerId: string;
    flightNavigationAccuracy: NavigationAccuracyClass;
    takeoffAndLandingNavigationAccuracy: NavigationAccuracyClass;
}

interface ParachuteEquipmentResponseBody {
    id: string;
    name: string;
    weight: number;
    minHeight: number;
    ceCertificate: boolean;
    modelOrigin: boolean;
    descentSpeed: number;
    maxWindSpeed: number;
    minOperatingTemperature: number;
    maxOperatingTemperature: number;
    astmF332218Compliant: boolean;
}

interface EquipmentItemResponseBody {
    id: string;
    name: string;
    type: EquipmentType;
    weight: number;
}

export interface CapabilitiesResponseBody {
    navigationAccuracyClasses: {
        [k in NavigationAccuracyClass]: {
            flightNavigationAccuracyHorizontal: string;
            flightNavigationAccuracyVertical: string;
            takeoffAndLandingNavigationAccuracyHorizontal: string;
            takeoffAndLandingNavigationAccuracyVertical: string;
        };
    };
    trackers: Tracker[];
}

export function convertUavListResponseBodyToUavsWithPages(response: UavListResponseBody): UavsWithPages {
    return {
        content: response.content.map(
            (uavItemResponseBody): UavListItem => ({
                id: uavItemResponseBody.id,
                operatorId: uavItemResponseBody.operatorId,
                operatorNumber: uavItemResponseBody.operatorNumber,
                ownerName: uavItemResponseBody.ownerName,
                manufacturerAndModel: `${uavItemResponseBody.model.manufacturer} ${uavItemResponseBody.model.name}`,
                type: uavItemResponseBody.model.type as UavType,
                serialNumbers: uavItemResponseBody.serialNumbers,
                isCustom: uavItemResponseBody.model.custom,
                isSwarm: uavItemResponseBody.swarm,
                uavClasses: (uavItemResponseBody.uavClasses ?? []) as UavClass[],
            })
        ),
        pagination: {
            pageNumber: response.number,
            pageSize: response.size,
            totalElements: response.totalElements,
        },
    };
}

function convertTechnicalSpecificationResponseBodyToTechnicalSpecification(
    techSpec: TechnicalSpecificationResponseBody
): TechnicalSpecification {
    return {
        numberOfEngines: techSpec.numberOfEngines ?? null,
        driveType: techSpec.driveType,
        minRecommendedAmbientTemperature: techSpec.minRecommendedAmbientTemperature ?? null,
        maxRecommendedAmbientTemperature: techSpec.maxRecommendedAmbientTemperature ?? null,
        takeOffMass: techSpec.takeOffMass,
        maxTakeOffMass: techSpec.maxTakeOffMass,
        maxDroneWidth: techSpec.maxDroneWidth,
        maxFlightTime: techSpec.maxFlightTime,
        hasFlightSpeedLimit: techSpec.flightSpeedLimit ?? false,
        maxFlightSpeed: techSpec.maxFlightSpeed,
        minFlightSpeed: techSpec.minFlightSpeed,
        maxClimbSpeed: techSpec.maxClimbSpeed ?? null,
        maxDescentSpeed: techSpec.maxDescentSpeed ?? null,
        maxWind: techSpec.maxWind ?? null,
        maxFlightAltitude: techSpec.maxFlightAltitude ?? null,
        hasRainFlightPossibility: techSpec.rainFlightPossibility ?? false,
        failSafe: techSpec.failSafe ?? [],
        hasGeofencing: techSpec.geofencing ?? false,
        hasDetectAndAvoid: techSpec.detectAndAvoid ?? false,
        hasProximitySensors: techSpec.proximitySensors ?? false,
        hasModuleRedundancy: techSpec.moduleRedundancy ?? false,
        hasGeocage: techSpec.geocage ?? false,
        hasEmergencyMotorStop: techSpec.emergencyMotorStop ?? false,
    };
}

function convertCommunicationResponseBodyToCommunication(communicationResponse: CommunicationResponseBody): Communication {
    return {
        id: communicationResponse.id,
        type: communicationResponse.type,
        transmissionLinkRange: communicationResponse.transmissionLinkRange,
        linkType: communicationResponse.linkType ?? CommunicationLinkType.Unknown,
        linkDelay: communicationResponse.linkDelay ?? null,
        isEmbedded: communicationResponse.modelOrigin,
        frequencies: communicationResponse.frequencies ?? [],
    };
}

function prepareSetupDocuments(documents: Partial<Record<UavSetupDocumentType, SetupDocumentBody[]>> = {}): UavSetupDocuments {
    const result: UavSetupDocuments = {};

    for (const [documentType, documentFiles] of Object.entries(documents)) {
        result[documentType as UavSetupDocumentType] = (documentFiles ?? []).map((document) => ({
            id: document.id,
            name: document.name,
            size: document.size,
            isEditable: true,
        }));
    }

    return result;
}

function convertTrackingResponseBodyToTracking(trackingResponse: TrackingResponseBody): Tracking {
    return {
        id: trackingResponse.id,
        identifier: trackingResponse.identifier,
        weight: trackingResponse.weight,
        trackerId: trackingResponse.trackerId,
        flightNavigationAccuracy: trackingResponse.flightNavigationAccuracy,
        takeoffAndLandingNavigationAccuracy: trackingResponse.takeoffAndLandingNavigationAccuracy,
        isEmbedded: trackingResponse.weight === 0,
    };
}

function convertSetupEquipmentResponseBodyToEquipment(equipment: {
    parachute: ParachuteEquipmentResponseBody | null;
    items: EquipmentItemResponseBody[];
}): Equipment {
    const camera: EquipmentItem[] = [];
    const parachute: EquipmentParachute[] = [];
    const navigationLighting: EquipmentItem[] = [];
    const nightLighting: EquipmentItem[] = [];
    const strobeLighting: EquipmentItem[] = [];
    const propellersGuards: EquipmentItem[] = [];
    const fts: EquipmentItem[] = [];

    if (equipment.parachute) {
        parachute.push({
            id: equipment.parachute.id,
            name: equipment.parachute.name,
            weight: equipment.parachute.weight,
            isEmbedded: equipment.parachute.modelOrigin,
            minHeight: equipment.parachute.minHeight,
            hasCeCertificate: equipment.parachute.ceCertificate,
            descentSpeed: equipment.parachute.descentSpeed,
            maxWindSpeed: equipment.parachute.maxWindSpeed,
            minOperatingTemperature: equipment.parachute.minOperatingTemperature,
            maxOperatingTemperature: equipment.parachute.maxOperatingTemperature,
            isAstmF332218Compliant: equipment.parachute.astmF332218Compliant,
        });
    }

    for (const equipmentItem of equipment.items) {
        let equipmentCollection: EquipmentItem[] = [];

        switch (equipmentItem.type) {
            case EquipmentType.Camera:
                equipmentCollection = camera;
                break;
            case EquipmentType.PropellersGuards:
                equipmentCollection = propellersGuards;
                break;
            case EquipmentType.NavigationLighting:
                equipmentCollection = navigationLighting;
                break;
            case EquipmentType.StrobeLighting:
                equipmentCollection = strobeLighting;
                break;
            case EquipmentType.NightLighting:
                equipmentCollection = nightLighting;
                break;
            case EquipmentType.Fts:
                equipmentCollection = fts;
                break;
            case EquipmentType.Parachute:
            default:
                break;
        }

        equipmentCollection.push({
            id: equipmentItem.id,
            name: equipmentItem.name,
            weight: equipmentItem.weight,
            isEmbedded: equipmentItem.weight === 0,
        });
    }

    return {
        camera,
        parachute,
        navigationLighting,
        nightLighting,
        strobeLighting,
        propellersGuards,
        fts,
    };
}

function convertUavSetupResponseBodyToUavSetup(setupResponse: UavSetupResponseBody): UavSetup {
    return {
        id: setupResponse.id,
        name: setupResponse.name,
        isPrimary: setupResponse.primary,
        technicalSpecification: convertTechnicalSpecificationResponseBodyToTechnicalSpecification(setupResponse.technicalSpecification),
        communications: setupResponse.communications.map((communicationResponse) =>
            convertCommunicationResponseBodyToCommunication(communicationResponse)
        ),
        documents: prepareSetupDocuments(setupResponse.documents),
        trackings: setupResponse.trackings.map((trackingResponse) => convertTrackingResponseBodyToTracking(trackingResponse)),
        equipment: convertSetupEquipmentResponseBodyToEquipment(setupResponse.equipment),
    };
}

function prepareUavDocuments(manual: UavDocumentBody | undefined, projectDocumentation: UavDocumentBody | undefined): UavModelDocuments {
    const result: UavModelDocuments = {};

    if (manual) {
        result[UavModelDocumentType.Manual] = {
            id: manual.id,
            name: manual.name,
            size: manual.size,
            isEditable: manual.editable,
        };
    }

    if (projectDocumentation) {
        result[UavModelDocumentType.ProjectDocumentation] = {
            id: projectDocumentation.id,
            name: projectDocumentation.name,
            size: projectDocumentation.size,
            isEditable: projectDocumentation.editable,
        };
    }

    return result;
}

export function convertUavDetailsResponseBodyToUav(getModelImageEndpoint: string, response: UavDetailsResponseBody): Uav {
    return {
        id: response.id,
        name: response.name,
        model: {
            id: response.model.id,
            name: response.model.name,
            manufacturer: response.model.manufacturer,
            type: response.model.type,
            isCustom: response.model.custom,
            imageId: response.model.photoId ?? undefined,
            imageUrl: response.model.photoId
                ? StringUtils.replaceInTemplate(getModelImageEndpoint, { photoId: response.model.photoId })
                : undefined,
        },
        operator: response.operator,
        serialNumbers: response.serialNumbers.sort(),
        uavClasses: response.uavClasses ?? [],
        isSwarm: response.swarm,
        setups: response.setups.map((setupResponse) => convertUavSetupResponseBodyToUavSetup(setupResponse)),
        ownerName: response.ownerName,
        isShared: response.shared,
        isCustom: response.model.custom,
        isCeCompliant: response.model.ceCompliant,
        documents: prepareUavDocuments(response.model.manual, response.model.projectDocumentation),
    };
}

export function convertCapabilitiesResponseBodyToCapabilities(response: CapabilitiesResponseBody): UavCapabilities {
    const navigationAccuracyItems: NavigationAccuracy[] = (
        Object.keys(response.navigationAccuracyClasses) as NavigationAccuracyClass[]
    ).reduce(
        (result: NavigationAccuracy[], classValue) => [
            ...result,
            {
                classValue: classValue,
                type: NavigationAccuracyType.HorizontalFlight,
                horizontal: response.navigationAccuracyClasses[classValue].flightNavigationAccuracyHorizontal,
                vertical: response.navigationAccuracyClasses[classValue].flightNavigationAccuracyVertical,
            },
            {
                classValue: classValue,
                type: NavigationAccuracyType.TakeoffAndLanding,
                horizontal: response.navigationAccuracyClasses[classValue].takeoffAndLandingNavigationAccuracyHorizontal,
                vertical: response.navigationAccuracyClasses[classValue].takeoffAndLandingNavigationAccuracyVertical,
            },
        ],
        []
    );

    return {
        trackers: response.trackers,
        navigationAccuracyItems,
    };
}
