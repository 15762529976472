<mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControl]="areGeoZonesEnabledControl">
    <div class="toggle-container heading">
        <dtm-ui-icon name="cylinder-line"></dtm-ui-icon>
        <p>
            {{ "dtmMapCesium.geographicalZones.geographicalZonesTitle" | transloco }}
            <dtm-ui-icon
                name="information-fill"
                class="popover-icon"
                (click)="$event.preventDefault(); openZoneLegendDialog()"
            ></dtm-ui-icon>
        </p>
    </div>
</mat-slide-toggle>
<dtm-ui-expandable-panel [isExpanded]="false" [hasHeaderSeparator]="false">
    <p class="panel-header" headerSlot>{{ "dtmMapCesium.geographicalZones.displayingZonesDetailsTitle" | transloco }}</p>
    <form [formGroup]="geoZoneSettingFormGroup">
        <div class="section">
            <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControl]="isDraPEnabledZoneControl">
                <span class="heading">{{ "dtmMapCesium.geographicalZones.drapZonesLabels.title" | transloco }}</span>
            </mat-slide-toggle>
            <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControl]="isDraPStaticEnabledZoneControl">
                <p class="toggle-content">
                    <dtm-ui-icon name="complex-cylinder-fill" class="dra-p"></dtm-ui-icon>
                    <span class="label">{{ "dtmMapCesium.geographicalZones.drapZonesLabels.constant" | transloco }}</span>
                </p>
            </mat-slide-toggle>
            <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControl]="isDraPElasticEnabledZoneControl">
                <p class="toggle-content">
                    <dtm-ui-icon name="cylinder-dash" class="dra-p"></dtm-ui-icon>
                    <dtm-ui-icon name="complex-cylinder-dash-fill-top-down" class="dra-p"></dtm-ui-icon>
                    <span class="label">{{ "dtmMapCesium.geographicalZones.drapZonesLabels.elastic" | transloco }}</span>
                </p>
            </mat-slide-toggle>
        </div>

        <div class="section">
            <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControl]="isDraREnabledZoneControl">
                <span class="heading">{{ "dtmMapCesium.geographicalZones.drarZonesLabels.title" | transloco }}</span>
            </mat-slide-toggle>
            <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControl]="isDraRStaticEnabledZoneControl">
                <p class="toggle-content">
                    <dtm-ui-icon name="complex-cylinder-fill" class="dra-r"></dtm-ui-icon>
                    <span class="label">{{ "dtmMapCesium.geographicalZones.drarZonesLabels.constant" | transloco }}</span>
                </p>
            </mat-slide-toggle>
            <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControl]="isDraRElasticEnabledZoneControl">
                <p class="toggle-content">
                    <dtm-ui-icon name="cylinder-dash" class="dra-r"></dtm-ui-icon>
                    <dtm-ui-icon name="complex-cylinder-dash-fill-top-down" class="dra-r"></dtm-ui-icon>
                    <span class="label">{{ "dtmMapCesium.geographicalZones.drarZonesLabels.elastic" | transloco }}</span>
                </p>
            </mat-slide-toggle>
            <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControl]="isDraRAirportEnabledZoneControl">
                <p class="toggle-content">
                    <dtm-ui-icon name="complex-cylinder-fill-background" class="dra-r-airport"></dtm-ui-icon>
                    <span class="label">{{ "dtmMapCesium.geographicalZones.drarZonesLabels.airport" | transloco }}</span>
                    <dtm-ui-popover (click)="$event.preventDefault()">
                        <div [innerHTML]="'dtmMapCesium.geographicalZones.drarZonesTooltips.airport' | transloco"></div>
                    </dtm-ui-popover>
                </p>
            </mat-slide-toggle>
            <div class="drar-container">
                <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControl]="isDraRh6kmEnabledZoneControl">
                    <p class="toggle-content">
                        <dtm-ui-icon name="complex-cylinder-fill" class="dra-rh-6km"></dtm-ui-icon>
                        <span class="label">{{ "dtmMapCesium.geographicalZones.drarZonesLabels.wideRH" | transloco }}</span>
                        <dtm-ui-popover (click)="$event.preventDefault()">
                            <div [innerHTML]="'dtmMapCesium.geographicalZones.drarZonesTooltips.wideRH' | transloco"></div>
                        </dtm-ui-popover>
                    </p>
                </mat-slide-toggle>
                <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControl]="isDraRhEnabledZoneControl">
                    <p class="toggle-content">
                        <dtm-ui-icon name="complex-cylinder-fill" class="dra-rh"></dtm-ui-icon>
                        <span class="label">{{ "dtmMapCesium.geographicalZones.drarZonesLabels.rh" | transloco }}</span>
                        <dtm-ui-popover (click)="$event.preventDefault()">
                            <div [innerHTML]="'dtmMapCesium.geographicalZones.drarZonesTooltips.rh' | transloco"></div>
                        </dtm-ui-popover>
                    </p>
                </mat-slide-toggle>
                <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControl]="isDraRmEnabledZoneControl">
                    <p class="toggle-content">
                        <dtm-ui-icon name="complex-cylinder-fill" class="dra-rm"></dtm-ui-icon>
                        <span class="label">{{ "dtmMapCesium.geographicalZones.drarZonesLabels.rm" | transloco }}</span>
                        <dtm-ui-popover (click)="$event.preventDefault()">
                            <div [innerHTML]="'dtmMapCesium.geographicalZones.drarZonesTooltips.rm' | transloco"></div>
                        </dtm-ui-popover>
                    </p>
                </mat-slide-toggle>
                <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControl]="isDraRlEnabledZoneControl">
                    <p class="toggle-content">
                        <dtm-ui-icon name="complex-cylinder-fill" class="dra-rl"></dtm-ui-icon>
                        <span class="label">{{ "dtmMapCesium.geographicalZones.drarZonesLabels.rl" | transloco }}</span>
                        <dtm-ui-popover (click)="$event.preventDefault()">
                            <div [innerHTML]="'dtmMapCesium.geographicalZones.drarZonesTooltips.rl' | transloco"></div>
                        </dtm-ui-popover>
                    </p>
                </mat-slide-toggle>
            </div>
        </div>
        <div class="section">
            <mat-slide-toggle labelPosition="before" class="spread allow-wrap" [formControl]="isDraIEnabledZoneControl">
                <p class="toggle-content">
                    <dtm-ui-icon name="cylinder-line" class="dra-i"></dtm-ui-icon>
                    <span class="heading">{{ "dtmMapCesium.geographicalZones.draiZonesLabel" | transloco }}</span>
                </p>
            </mat-slide-toggle>
        </div>
    </form>
</dtm-ui-expandable-panel>
