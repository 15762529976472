<mat-slide-toggle labelPosition="before" class="spread allow-wrap" [disabled]="true">
    <div class="toggle-container heading">
        <dtm-ui-icon name="global-poland"></dtm-ui-icon>
        <p>
            {{ "dtmMapCesium.globalSpatialInfo.titleLabel" | transloco }}
        </p>
    </div>
</mat-slide-toggle>
<dtm-ui-expandable-panel [isExpanded]="false" [hasHeaderSeparator]="false">
    <p headerSlot>{{ "dtmMapCesium.displayDetailsLabel" | transloco }}</p>
    <mat-slide-toggle labelPosition="before" class="spread allow-wrap">
        <p class="toggle-content">
            <dtm-ui-icon name="hems"></dtm-ui-icon>
            <span class="label">{{ "dtmMapCesium.globalSpatialInfo.hemsHospitalLandingsSitesLabel" | transloco }}</span>
        </p>
    </mat-slide-toggle>

    <mat-slide-toggle labelPosition="before" class="spread allow-wrap">
        <p class="toggle-content">
            <dtm-ui-icon name="helicopter-lpr-fill"></dtm-ui-icon>
            <span class="label">{{ "dtmMapCesium.globalSpatialInfo.hemsBasesLabel" | transloco }}</span>
        </p>
    </mat-slide-toggle>

    <mat-slide-toggle labelPosition="before" class="spread allow-wrap">
        <p class="toggle-content">
            <dtm-ui-icon name="plane-airport-fill"></dtm-ui-icon>
            <span class="label">{{ "dtmMapCesium.globalSpatialInfo.landingSitesAndAirportsLabel" | transloco }}</span>
        </p>
    </mat-slide-toggle>

    <mat-slide-toggle labelPosition="before" class="spread allow-wrap">
        <p class="toggle-content">
            <dtm-ui-icon name="tree-fill"></dtm-ui-icon>
            <span class="label">{{ "dtmMapCesium.globalSpatialInfo.nationalParksBordersLabel" | transloco }}</span>
        </p>
    </mat-slide-toggle>
</dtm-ui-expandable-panel>
