import { ChangeDetectionStrategy, Component, Inject, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable, Subject } from "rxjs";

const MIN_REASON_LENGTH = 3;
const MAX_REASON_LENGTH = 255;

export interface DialogWithReasonConfigData {
    titleText: string;
    reasonFormLabel?: string;
    introHtml?: string;
    confirmButtonLabel?: string;
    isReasonProcessing$: Observable<boolean>;
}

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-dialog-with-reason",
    templateUrl: "./dialog-with-reason.component.html",
    styleUrls: ["./dialog-with-reason.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogWithReasonComponent implements OnInit {
    private readonly setReasonSubject = new Subject<string>();
    public readonly setReason$ = this.setReasonSubject.asObservable();

    protected reasonControl = new FormControl("", {
        validators: [Validators.required, Validators.minLength(MIN_REASON_LENGTH), Validators.maxLength(MAX_REASON_LENGTH)],
        nonNullable: true,
    });

    protected MAX_REASON_LENGTH = MAX_REASON_LENGTH;

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogWithReasonConfigData) {}

    public ngOnInit() {
        this.data.isReasonProcessing$.pipe(untilDestroyed(this)).subscribe((isProcessing) => {
            if (isProcessing) {
                this.reasonControl.disable();

                return;
            }

            this.reasonControl.enable();
        });
    }

    protected confirm() {
        this.reasonControl.markAsTouched();

        if (this.reasonControl.invalid) {
            return;
        }

        this.setReasonSubject.next(this.reasonControl.value);
    }
}
