import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AdminUsersRoutingModule } from "@dtm-frontend/caa-admin-lib/admin-users";
import { AreaMapRoutingModule } from "@dtm-frontend/caa-admin-lib/area-map";
import { AuditRoutingModule } from "@dtm-frontend/caa-admin-lib/audit";
import { CentersRoutingModule } from "@dtm-frontend/caa-admin-lib/centers";
import { ConversationRoutingModule } from "@dtm-frontend/caa-admin-lib/conversation";
import { DashboardRoutingModule } from "@dtm-frontend/caa-admin-lib/dashboard";
import { ExamTrainingResultsRoutingModule } from "@dtm-frontend/caa-admin-lib/exam-training-results";
import { OperationScenarioStatementsRoutingModule } from "@dtm-frontend/caa-admin-lib/operation-scenario-statements";
import { OperatorRoutingModule } from "@dtm-frontend/caa-admin-lib/operator";
import { PermitsRoutingModule } from "@dtm-frontend/caa-admin-lib/permits";
import { UavRoutingModule } from "@dtm-frontend/caa-admin-lib/uav";
import { UserProfileRoutingModule } from "@dtm-frontend/caa-admin-lib/user-profile";
import { UtmUsersRoutingModule } from "@dtm-frontend/caa-admin-lib/utm-users";
import { DTM_AUTH_GUARD_REQUIRED_ROLES, DtmAuthGuard, NOT_AUTHORIZED_ROUTE } from "@dtm-frontend/shared/auth";
import { MissionSearchRoutingModule } from "@dtm-frontend/shared/mission";
import { NotAuthorizedComponent, PageNotFoundComponent } from "@dtm-frontend/shared/ui";
import { NOT_FOUND_ROUTE } from "@dtm-frontend/shared/utils";

const routes: Routes = [
    {
        path: "",
        pathMatch: "full",
        redirectTo: "/dashboard",
    },
    {
        path: NOT_AUTHORIZED_ROUTE,
        component: NotAuthorizedComponent,
        canActivate: [DtmAuthGuard],
    },
    {
        path: NOT_FOUND_ROUTE,
        component: PageNotFoundComponent,
        canActivate: [DtmAuthGuard],
    },
    { path: "**", redirectTo: `/${NOT_FOUND_ROUTE}` },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes),
        OperatorRoutingModule,
        OperationScenarioStatementsRoutingModule,
        ConversationRoutingModule,
        DashboardRoutingModule,
        UserProfileRoutingModule,
        AdminUsersRoutingModule,
        PermitsRoutingModule,
        UtmUsersRoutingModule,
        CentersRoutingModule,
        ExamTrainingResultsRoutingModule,
        AreaMapRoutingModule,
        AuditRoutingModule,
        MissionSearchRoutingModule,
        UavRoutingModule,
    ],
    exports: [RouterModule],
    providers: [
        {
            provide: DTM_AUTH_GUARD_REQUIRED_ROLES,
            useValue: [],
        },
    ],
})
export class AppRoutingModule {}
