import { OperatorType, UavModel, UavType } from "@dtm-frontend/shared/ui";
import { ISO8601TimeDuration } from "@dtm-frontend/shared/utils";

export enum CommunicationType {
    RADIO = "RADIO",
    GSM = "GSM",
    SATELLITE = "SATELLITE",
}

export enum CommunicationLinkType {
    C2 = "C2",
    C3 = "C3",
    Unknown = "Unknown",
}

export enum FailSafe {
    ReturnToHome = "RETURN_TO_HOME",
    ReturnToLaunch = "RETURN_TO_LAUNCH",
    AutoLand = "AUTO_LAND",
    Hover = "HOVER",
    Drop = "DROP",
    ParachuteLanding = "PARACHUTE_LANDING",
}

export enum DriveType {
    ELECTRIC = "ELECTRIC",
    COMBUSTION = "COMBUSTION",
    HYBRID = "HYBRID",
    JET = "JET",
    SOLAR = "SOLAR",
}

export enum TrackerType {
    ADSB = "ADSB",
    HOD = "HOD",
}

export enum NavigationAccuracyClass {
    Class1 = "CLASS_1",
    Class2 = "CLASS_2",
    Class3 = "CLASS_3",
}

export enum NavigationAccuracyType {
    HorizontalFlight = "HORIZONTAL_FLIGHT",
    TakeoffAndLanding = "TAKEOFF_AND_LANDING",
}

export enum EquipmentType {
    Camera = "CAMERA",
    Parachute = "PARACHUTE",
    PropellersGuards = "PROPELLERS_GUARDS",
    NavigationLighting = "NAVIGATION_LIGHTING",
    StrobeLighting = "STROBE_LIGHTING",
    NightLighting = "NIGHT_LIGHTING",
    Fts = "FTS",
}

export enum UavSetupDocumentType {
    ParachuteManual = "PARACHUTE_MANUAL",
    ParachutePhoto = "PARACHUTE_PHOTO",
    CheckList = "CHECK_LIST",
    TechnicalSupportProgram = "TECHNICAL_SUPPORT_PROGRAM",
    Fts = "FTS",
    Dvr = "DVR",
    TestsResults = "TESTS_RESULTS",
}

export enum UavModelDocumentType {
    Manual = "MANUAL",
    ProjectDocumentation = "PROJECT_DOCUMENTATION",
}

export enum UavClass {
    C0 = "C0",
    C1 = "C1",
    C2 = "C2",
    C3 = "C3",
    C4 = "C4",
    C5 = "C5",
    C6 = "C6",
}

export const MAXIMUM_KILOMETERS_PER_HOUR_FRACTION_DIGITS = 3;

export interface TechnicalSpecification {
    numberOfEngines: number | null;
    driveType: DriveType;
    minRecommendedAmbientTemperature: number | null;
    maxRecommendedAmbientTemperature: number | null;
    takeOffMass: number;
    maxTakeOffMass: number;
    maxDroneWidth: number;
    maxFlightTime: ISO8601TimeDuration;
    hasFlightSpeedLimit: boolean;
    maxFlightSpeed: number;
    minFlightSpeed?: number | null;
    maxClimbSpeed: number | null;
    maxDescentSpeed: number | null;
    maxWind: number | null;
    maxFlightAltitude: number | null;
    hasRainFlightPossibility: boolean;
    failSafe: FailSafe[];
    hasGeofencing: boolean;
    hasDetectAndAvoid: boolean;
    hasProximitySensors: boolean;
    hasModuleRedundancy: boolean;
    hasGeocage: boolean;
    hasEmergencyMotorStop: boolean;
}

export interface Communication {
    id?: string;
    type: CommunicationType;
    transmissionLinkRange: number;
    linkType: CommunicationLinkType;
    linkDelay: number | null;
    isEmbedded: boolean;
    frequencies: CommunicationFrequency[];
}

export interface CommunicationFrequency {
    minGhz: number;
    maxGhz: number;
}

export interface Tracker {
    id: string;
    type: TrackerType;
    name: string;
}

export interface Tracking {
    id?: string;
    identifier: string;
    weight: number;
    trackerId: string;
    flightNavigationAccuracy: NavigationAccuracyClass;
    takeoffAndLandingNavigationAccuracy: NavigationAccuracyClass;
    isEmbedded: boolean;
}

export interface NavigationAccuracy {
    classValue: NavigationAccuracyClass;
    type: NavigationAccuracyType;
    horizontal: string;
    vertical: string;
}

export interface EquipmentItem {
    id?: string;
    name?: string;
    weight: number;
    isEmbedded: boolean;
}

export interface EquipmentParachute extends EquipmentItem {
    minHeight: number;
    hasCeCertificate: boolean;
    descentSpeed: number;
    maxWindSpeed: number;
    minOperatingTemperature: number;
    maxOperatingTemperature: number;
    isAstmF332218Compliant: boolean;
}

export interface EquipmentProperties {
    type: EquipmentType;
    item: EquipmentItem;
}

export interface ParachuteProperties {
    type: EquipmentType.Parachute;
    item: EquipmentParachute;
}

export interface Equipment {
    camera: EquipmentItem[];
    parachute: EquipmentParachute[];
    propellersGuards: EquipmentItem[];
    navigationLighting: EquipmentItem[];
    strobeLighting: EquipmentItem[];
    nightLighting: EquipmentItem[];
    fts: EquipmentItem[];
}

export interface UavDocumentFile {
    id: string;
    name: string;
    size: number;
    isEditable: boolean;
}

export type UavModelDocuments = Partial<Record<UavModelDocumentType, UavDocumentFile>>;
export type UavSetupDocuments = Partial<Record<UavSetupDocumentType, UavDocumentFile[]>>;

export interface UavProperties {
    technicalSpecification: TechnicalSpecification;
    communications: Communication[];
    trackings?: Tracking[];
    equipment: Equipment;
    documents: UavSetupDocuments;
}

export interface UavSetup extends Required<UavProperties> {
    id: string;
    name: string;
    isPrimary: boolean;
}

export interface Operator {
    id: string;
    name: string;
    type: OperatorType;
}

export interface Uav {
    id: string;
    name: string;
    model: UavModel;
    operator: Operator;
    serialNumbers: string[];
    uavClasses: UavClass[];
    setups: UavSetup[];
    ownerName: string;
    isShared: boolean;
    isSwarm: boolean;
    isCustom: boolean;
    isCeCompliant: boolean;
    documents: UavModelDocuments;
}

export interface UavInfo
    extends Pick<Uav, "name" | "serialNumbers" | "uavClasses" | "ownerName" | "isShared" | "isCustom" | "isCeCompliant" | "isSwarm"> {
    manufacturerName: string;
    modelName: string;
    modelType: UavType;
    imageUrl?: string;
}
