import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { ActivatedRoute, Router } from "@angular/router";
import { ErrorMode, MIDDLE_PAGE_SIZE_VALUE, PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { Observable, combineLatest, map } from "rxjs";
import { UavFilters, UavListFilterParams } from "../../services/uav.models";
import { UavActions } from "../../state/uav.actions";
import { UavState } from "../../state/uav.state";

interface UavContainerComponentState {
    [PAGE_NUMBER_QUERY_PARAM]: number;
    [PAGE_SIZE_QUERY_PARAM]: number;
    serialNumber: string | undefined;
    operatorNumber: string | undefined;
}

@UntilDestroy()
@Component({
    templateUrl: "./uav-container.component.html",
    styleUrls: ["./uav-container.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UavContainerComponent {
    protected readonly isProcessing$ = this.store.select(UavState.isProcessing);
    protected readonly uavsError$ = this.store.select(UavState.uavsError);
    protected readonly uavs$ = this.store.select(UavState.uavs);
    protected readonly pagination$ = this.store.select(UavState.uavsPagination);
    protected readonly uavFilters$: Observable<UavFilters> = combineLatest([
        this.localStore.selectByKey("serialNumber"),
        this.localStore.selectByKey("operatorNumber"),
    ]).pipe(map(([serialNumber, operatorNumber]) => ({ serialNumber: serialNumber ?? "", operatorNumber: operatorNumber ?? "" })));

    protected readonly ErrorMode = ErrorMode;
    @ViewChild("uavList", { read: ElementRef }) private uavList: ElementRef<HTMLHtmlElement> | undefined;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly localStore: LocalComponentStore<UavContainerComponentState>,
        private readonly store: Store
    ) {
        this.localStore.setState({
            [PAGE_NUMBER_QUERY_PARAM]: this.route.snapshot.queryParams[PAGE_NUMBER_QUERY_PARAM] ?? 0,
            [PAGE_SIZE_QUERY_PARAM]: this.route.snapshot.queryParams[PAGE_SIZE_QUERY_PARAM] ?? MIDDLE_PAGE_SIZE_VALUE,
            serialNumber: this.route.snapshot.queryParams.serialNumber ?? undefined,
            operatorNumber: this.route.snapshot.queryParams.operatorNumber ?? undefined,
        });
        this.listenOnQueryParamsChanges();
        this.refreshUavList();
    }

    protected refreshUavList(): void {
        const localState = this.localStore.get();
        const params: UavListFilterParams = {
            [PAGE_NUMBER_QUERY_PARAM]: localState[PAGE_NUMBER_QUERY_PARAM],
            [PAGE_SIZE_QUERY_PARAM]: localState[PAGE_SIZE_QUERY_PARAM],
        };

        if (localState.serialNumber?.length) {
            params.serialNumber = localState.serialNumber;
        }

        if (localState.operatorNumber?.length) {
            params.operatorNumber = localState.operatorNumber;
        }

        this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams: params,
            replaceUrl: true,
        });
    }

    protected pageChange(pageEvent: PageEvent): void {
        this.localStore.patchState((currentState) => ({
            ...currentState,
            [PAGE_NUMBER_QUERY_PARAM]: pageEvent.pageIndex,
            [PAGE_SIZE_QUERY_PARAM]: pageEvent.pageSize,
        }));

        this.refreshUavList();
        this.scrollToTop();
    }

    protected updateFiltersState(filters: UavFilters): void {
        this.localStore.patchState((currentState) => ({ ...currentState, ...filters, [PAGE_NUMBER_QUERY_PARAM]: 0 }));
        this.refreshUavList();
    }

    private listenOnQueryParamsChanges(): void {
        this.route.queryParams.pipe(untilDestroyed(this)).subscribe((queryParams: UavListFilterParams) => {
            if (!Object.keys(queryParams).length) {
                return;
            }
            this.getUavList(queryParams);
        });
    }

    private getUavList(params: UavListFilterParams): void {
        this.store.dispatch(new UavActions.GetUavs(params));
    }

    private scrollToTop() {
        this.uavList?.nativeElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }
}
