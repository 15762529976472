import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatLegacyOption as MatOption } from "@angular/material/legacy-core";
import { SELECT_OPTION_COMPONENT } from "../select-field.component";

@Component({
    selector: "dtm-ui-select-option",
    templateUrl: "./select-option.component.html",
    styleUrls: ["./select-option.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        "[class.mat-selected]": "false",
        "[class.mat-option-multiple]": "false",
        "[class.mat-option]": "false",
        "[class.mat-focus-indicator]": "false",
        "[class.mat-active]": "active",
        "[class.mat-option-disabled]": "false",
        "[class.select-option-disabled]": "disabled",
    },
    providers: [{ provide: SELECT_OPTION_COMPONENT, useExisting: SelectOptionComponent }],
})
export class SelectOptionComponent extends MatOption {
    public parentId = this.id;

    public get viewValue(): string {
        // eslint-disable-next-line no-underscore-dangle
        return (this._getHostElement().querySelector(".label")?.textContent || "").trim();
    }
}
