import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatBadgeModule } from "@angular/material/badge";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyChipsModule as MatChipsModule } from "@angular/material/legacy-chips";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyPaginatorModule as MatPaginatorModule } from "@angular/material/legacy-paginator";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { SharedAuthModule } from "@dtm-frontend/shared/auth";
import { FILES_UPLOAD_API_PROVIDER, SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { SharedModule } from "../shared/shared.module";
import { ConversationContainerComponent } from "./components/conversation-container/conversation-container.component";
import { FiltersContainerComponent } from "./components/conversation-container/filters-container/filters-container.component";
import { ThreadsListAssigneeChangeComponent } from "./components/conversation-container/threads-list-assignee-change/threads-list-assignee-change.component";
import { ThreadsListComponent } from "./components/conversation-container/threads-list/threads-list.component";
import { MessagesAssigneeSelectComponent } from "./components/messages-container/messages-assignee-select/messages-assignee-select.component";
import { MessagesInterlocutorProfileComponent } from "./components/messages-container/messages-interlocutor-profile/messages-interlocutor-profile.component";
import { MessagesComponent } from "./components/messages-container/messages.component";
import { ThreadCategoryChangeComponent } from "./components/messages-container/thread-category-change/thread-category-change.component";
import { NewThreadComponent } from "./components/shared/new-thread/new-thread.component";
import { ConversationApiService } from "./services/api/conversation-api.service";
import { ConversationsFilesApiService } from "./services/api/conversations-files-api.service";
import { ConversationState } from "./state/conversation.state";

@NgModule({
    declarations: [
        ConversationContainerComponent,
        NewThreadComponent,
        ThreadsListComponent,
        MessagesComponent,
        MessagesAssigneeSelectComponent,
        FiltersContainerComponent,
        ThreadsListAssigneeChangeComponent,
        ThreadCategoryChangeComponent,
        MessagesInterlocutorProfileComponent,
    ],
    imports: [
        CommonModule,
        NgxsModule.forFeature([ConversationState]),
        LetModule,
        PushModule,
        ReactiveFormsModule,
        MatIconModule,
        MatDividerModule,
        SharedI18nModule,
        SharedModule,
        MatCardModule,
        MatTooltipModule,
        MatExpansionModule,
        MatDialogModule,
        MatFormFieldModule,
        SharedUiModule,
        MatBottomSheetModule,
        MatSelectModule,
        MatInputModule,
        MatPaginatorModule,
        RouterModule,
        SharedAuthModule,
        MatProgressSpinnerModule,
        MatSidenavModule,
        MatBadgeModule,
        MatChipsModule,
        MatMenuModule,
        MatTableModule,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmAdminLibConversation",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: ConversationApiService,
            useValue: undefined,
        },
        { provide: FILES_UPLOAD_API_PROVIDER, useClass: ConversationsFilesApiService },
    ],
    exports: [
        ConversationContainerComponent,
        NewThreadComponent,
        ThreadsListComponent,
        MessagesAssigneeSelectComponent,
        ThreadsListAssigneeChangeComponent,
        ThreadCategoryChangeComponent,
        MessagesInterlocutorProfileComponent,
    ],
})
export class ConversationModule {
    public static forRoot(): ModuleWithProviders<ConversationModule> {
        return {
            ngModule: ConversationModule,
            providers: [
                {
                    provide: ConversationApiService,
                    useClass: ConversationApiService,
                },
            ],
        };
    }

    public static forTest(): ModuleWithProviders<ConversationModule> {
        return {
            ngModule: ConversationModule,
            providers: [
                {
                    provide: ConversationApiService,
                    useValue: null,
                },
            ],
        };
    }
}
