import { ConnectedPosition } from "@angular/cdk/overlay";
import {
    AfterContentInit,
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    forwardRef,
    InjectionToken,
    Input,
    Output,
    QueryList,
    ViewChild,
} from "@angular/core";
import { _getOptionScrollPosition } from "@angular/material/core";
import { MAT_LEGACY_OPTION_PARENT_COMPONENT as MAT_OPTION_PARENT_COMPONENT } from "@angular/material/legacy-core";
import { MatLegacySelect } from "@angular/material/legacy-select";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { debounceTime, startWith, Subject, takeUntil } from "rxjs";
import { CustomOptionComponent } from "./custom-option/custom-option.component";
import { SelectOptionComponent } from "./select-option/select-option.component";

export const SELECT_OPTION_COMPONENT = new InjectionToken("MatOptionComponent");

@UntilDestroy()
@Component({
    selector: "dtm-ui-select-field",
    templateUrl: "./select-field.component.html",
    styleUrls: ["./select-field.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        "[class.mat-select]": "false",
        "[class.select-field-disabled]": "disabled",
        "[class.select-field-invalid]": "errorState",
        "[class.select-field-required]": "required",
        "[class.select-field-empty]": "empty",
        "[class.select-field-multiple]": "multiple",
    },
    providers: [{ provide: MAT_OPTION_PARENT_COMPONENT, useExisting: forwardRef(() => SelectFieldComponent) }],
})
export class SelectFieldComponent extends MatLegacySelect implements AfterViewInit, AfterContentInit {
    @ViewChild("trigger", { read: ElementRef }) public trigger!: ElementRef;

    @ContentChildren(SELECT_OPTION_COMPONENT, { descendants: true }) public options!: QueryList<
        SelectOptionComponent | CustomOptionComponent
    >;

    @Input() public isClearable = true;
    @Input() public maxPanelHeight = "240px";
    @Input() public shouldApplyOffsetTop = false;
    @Output() public panelClose = new EventEmitter<void>();

    public _positions: ConnectedPosition[] = [
        {
            originX: "start",
            originY: "bottom",
            overlayX: "start",
            overlayY: "top",
            offsetY: 24,
            offsetX: -10,
        },
        {
            originX: "center",
            originY: "bottom",
            overlayX: "center",
            overlayY: "top",
            offsetY: 24,
        },
        {
            originX: "start",
            originY: "top",
            overlayX: "start",
            overlayY: "bottom",
            offsetY: -24,
        },
    ];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public get activeItem() {
        // eslint-disable-next-line no-underscore-dangle
        return this._keyManager?.activeItem;
    }

    private onChange$: Subject<any> | undefined;

    public ngAfterViewInit() {
        this.options.forEach((option) => {
            option.parentId = this.id;
        });
    }

    public detachPanel() {
        this.close();
        this.panelClose.emit();
    }

    public get selectedValuesLabel(): string {
        return Array.isArray(this.selected) ? this.selected.map((item) => item.viewValue).join(", ") : "";
    }

    public clearValue(event: Event) {
        event.stopPropagation();

        if (Array.isArray(this.value)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const newValue: any[] = [];

            this.options.forEach((option) => {
                if (option.disabled && this.value.includes(option.value)) {
                    newValue.push(option.value);
                }
            });

            this.value = newValue.length ? newValue : null;
        } else {
            this.value = null;
        }
    }

    public selectAll() {
        this.options.forEach((option) => {
            if (!option.disabled) {
                option.select();
            }
        });
    }

    // NOTE: this is workaround for issue DTM-2129
    // Issue only occurs when in ng-content we will provide template with some structural directives (ex. *ngIf)
    // TODO: DTM-2129
    public ngAfterContentInit() {
        super.ngAfterContentInit();
        // eslint-disable-next-line no-underscore-dangle
        const changesSubscription = this.options.changes.pipe(takeUntil(this._destroy), startWith(null)).subscribe(() => {
            setTimeout(() => {
                if (changesSubscription.closed) {
                    return;
                }
                this.options.forEach((option) => {
                    option.parentId = this.id;
                });
                super["_resetOptions"]();
                super["_initializeSelection"]();
            });
        });
    }

    public registerOnChange(fn: (value: unknown) => void): void {
        this.onChange$?.complete();
        this.onChange$ = new Subject<unknown>();

        // NOTE: debounceTime(0) prevents 'select all' to trigger multiple updates
        this.onChange$.pipe(debounceTime(0), untilDestroyed(this)).subscribe(fn);

        // eslint-disable-next-line no-underscore-dangle
        this._onChange = (value) => this.onChange$?.next(value);
    }

    protected _scrollOptionIntoView(index: number): void {
        // NOTE: implementation copied from MatSelect (not legacy)
        const option = this.options.toArray()[index];

        if (option) {
            const panel: HTMLElement = this.panel.nativeElement;
            // eslint-disable-next-line no-underscore-dangle
            const element = option._getHostElement();

            if (index === 0) {
                panel.scrollTop = 0;
            } else {
                let positionOffset = 0;
                if (this.shouldApplyOffsetTop) {
                    // eslint-disable-next-line no-underscore-dangle
                    const firstOptionElementOffsetTop = this.options.first?._getHostElement()?.offsetTop ?? 0;
                    positionOffset = Math.max(0, panel.scrollTop + firstOptionElementOffsetTop - element.offsetTop);
                }

                panel.scrollTop =
                    _getOptionScrollPosition(element.offsetTop, element.offsetHeight, panel.scrollTop, panel.offsetHeight) - positionOffset;
            }
        }
    }

    // NOTE: implementation needed to calculate panel scroll position on panel open
    protected _positioningSettled() {
        // eslint-disable-next-line no-underscore-dangle
        this._scrollOptionIntoView(this._keyManager.activeItemIndex || 0);
    }

    protected get privateHack() {
        // NOTE: this is needed to allow template to access private properties of MatLegacySelect
        return {
            // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/no-explicit-any
            _triggerRect: (this as any)._triggerRect,
        };
    }
}
