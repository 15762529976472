import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { AircraftEntity, FlightOperator } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { AcEntity, ActionType, Cartesian3, CesiumService } from "@pansa/ngx-cesium";
import { combineLatestWith } from "rxjs";
import { map, tap } from "rxjs/operators";
import { MapUtils } from "../../index";

interface FlightTrackerDetailsComponentState {
    aircraftEntity: AircraftEntity | undefined;
    isOperatorVisible: boolean;
}

type OperatorUpdateAcEntity = AcEntity & { entity?: { position?: Cartesian3 }; actionType: ActionType; id: string };
const OPERATOR_ENTITY_ID = "operator";

@UntilDestroy()
@Component({
    selector: "dtm-map-flight-tracker-aircraft-details",
    templateUrl: "./flight-tracker-aircraft-details.component.html",
    styleUrls: ["./flight-tracker-aircraft-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class FlightTrackerAircraftDetailsComponent {
    @Input() public set aircraftEntity(value: AircraftEntity | undefined) {
        this.localStore.patchState({ aircraftEntity: value });
    }
    @Output() public readonly detailsClose = new EventEmitter<void>();

    protected readonly aircraftEntity$ = this.localStore.selectByKey("aircraftEntity");
    protected readonly isOperatorVisible$ = this.localStore.selectByKey("isOperatorVisible");
    protected readonly operatorData$ = this.aircraftEntity$.pipe(
        combineLatestWith(this.isOperatorVisible$),
        map(([aircraftEntity, isOperatorVisible]) =>
            this.transformRemoteIdDataToOperatorEntity(isOperatorVisible ? aircraftEntity?.operator : undefined)
        ),
        tap(() => {
            this.cesiumService.getScene().requestRender();
        })
    );

    constructor(
        private readonly localStore: LocalComponentStore<FlightTrackerDetailsComponentState>,
        private readonly cesiumService: CesiumService
    ) {
        this.localStore.setState({ aircraftEntity: undefined, isOperatorVisible: false });
    }

    protected getAccuracy(accuracy: AircraftEntity["position"]["accuracy"]): string | undefined {
        if (!accuracy.latitude || !accuracy.longitude) {
            return;
        }

        return Math.max(accuracy.latitude, accuracy.longitude).toFixed(0);
    }

    protected closeDetails(): void {
        this.detailsClose.emit();
        this.localStore.setState({ aircraftEntity: undefined, isOperatorVisible: false });
    }

    protected toggleOperatorVisibility(): void {
        this.localStore.patchState(({ isOperatorVisible }) => ({ isOperatorVisible: !isOperatorVisible }));
    }

    private transformRemoteIdDataToOperatorEntity(operator?: FlightOperator): OperatorUpdateAcEntity {
        if (!operator) {
            return {
                actionType: ActionType.DELETE,
                id: OPERATOR_ENTITY_ID,
            };
        }

        return {
            actionType: ActionType.ADD_UPDATE,
            id: OPERATOR_ENTITY_ID,
            entity: {
                position: MapUtils.convertSerializableCartographicToCartesian3({
                    latitude: operator.latitude,
                    longitude: operator.longitude,
                }),
            },
        };
    }
}
