<ng-container *ngIf="profile$ | ngrxPush as profile">
    <button
        [ngSwitch]="profile.status"
        type="button"
        [matMenuTriggerFor]="status"
        [disabled]="profile.status === OperatorStatus.Deregistered"
    >
        <span *ngSwitchCase="OperatorStatus.Approved" class="chip success">
            <ng-template [ngTemplateOutlet]="approved"></ng-template>
        </span>
        <span *ngSwitchCase="OperatorStatus.Suspended" class="chip primary">
            <ng-template [ngTemplateOutlet]="suspended"></ng-template>
        </span>
        <span *ngSwitchCase="OperatorStatus.PendingApproval" class="chip warning">
            <ng-template [ngTemplateOutlet]="pendingApproval"></ng-template>
        </span>
        <span *ngSwitchCase="OperatorStatus.ApprovalInProgress" class="chip secondary">
            <ng-template [ngTemplateOutlet]="approvalInProgress"></ng-template>
        </span>
        <span *ngSwitchCase="OperatorStatus.Deregistered" class="chip error">
            <ng-template [ngTemplateOutlet]="deregistered"></ng-template>
        </span>
    </button>

    <mat-menu #status="matMenu">
        <ng-container *ngFor="let operatorStatus of OperatorStatus | keyvalue : keepOperatorStatusOrder">
            <button
                *ngIf="operatorStatus.value !== profile.status"
                type="button"
                mat-menu-item
                (click)="changeOperatorStatus(profile, operatorStatus.value)"
            >
                <ng-container [ngSwitch]="operatorStatus.value">
                    <ng-container *ngSwitchCase="OperatorStatus.Approved" [ngTemplateOutlet]="approved"> </ng-container>
                    <ng-container *ngSwitchCase="OperatorStatus.Suspended" [ngTemplateOutlet]="suspended"> </ng-container>
                    <ng-container *ngSwitchCase="OperatorStatus.PendingApproval" [ngTemplateOutlet]="pendingApproval"> </ng-container>
                    <ng-container *ngSwitchCase="OperatorStatus.ApprovalInProgress" [ngTemplateOutlet]="approvalInProgress"> </ng-container>
                    <ng-container *ngSwitchCase="OperatorStatus.Deregistered" [ngTemplateOutlet]="deregistered"> </ng-container>
                </ng-container>
            </button>
        </ng-container>
    </mat-menu>
</ng-container>

<ng-template #approved>
    <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
    {{ "dtmAdminLibShared.operatorProfile.operatorStatus.approved" | transloco }}
</ng-template>
<ng-template #suspended>
    <dtm-ui-icon name="pause-circle"></dtm-ui-icon>
    {{ "dtmAdminLibShared.operatorProfile.operatorStatus.suspended" | transloco }}
</ng-template>
<ng-template #pendingApproval>
    <dtm-ui-icon name="rest-time"></dtm-ui-icon>
    {{ "dtmAdminLibShared.operatorProfile.operatorStatus.pendingApproval" | transloco }}
</ng-template>
<ng-template #approvalInProgress>
    <dtm-ui-icon name="time"></dtm-ui-icon>
    {{ "dtmAdminLibShared.operatorProfile.operatorStatus.approvalInProgress" | transloco }}
</ng-template>
<ng-template #deregistered>
    <dtm-ui-icon name="close-circle"></dtm-ui-icon>
    {{ "dtmAdminLibShared.operatorProfile.operatorStatus.deregistered" | transloco }}
</ng-template>
