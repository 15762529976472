import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { Page, StoredAvatarPictures } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { ConversationAssignee, NewThreadAssignment, Thread } from "../../../services/conversation.models";

interface ThreadsListComponentState {
    treadsList: Thread[];
    assignees: Map<string, ConversationAssignee> | undefined;
    processingThreadsAssignments: string[];
    areFiltersApplied: boolean;
    storedAvatarPictures: StoredAvatarPictures;
    pagination: Page | undefined;
}

@Component({
    selector: "dtm-admin-lib-threads-list[threadsList][assignees]",
    templateUrl: "./threads-list.component.html",
    styleUrls: ["./threads-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ThreadsListComponent {
    @Input() public set threadsList(value: Thread[] | undefined) {
        this.localStore.patchState({ treadsList: value ?? [] });
    }

    @Input() public set assignees(value: Map<string, ConversationAssignee> | undefined) {
        this.localStore.patchState({ assignees: value });
    }

    @Input() public set areFiltersApplied(value: boolean | undefined) {
        this.localStore.patchState({ areFiltersApplied: value });
    }

    @Input() public set processingThreadsAssignments(value: string[] | undefined) {
        this.localStore.patchState({ processingThreadsAssignments: value ?? [] });
    }

    @Input() public set storedAvatarPictures(value: StoredAvatarPictures | undefined) {
        this.localStore.patchState({ storedAvatarPictures: value ?? {} });
    }

    @Input() public set pagination(value: Page | undefined) {
        this.localStore.patchState({ pagination: value });
    }

    @Output() public assigneeAssign: EventEmitter<NewThreadAssignment> = new EventEmitter();
    @Output() public pageChange: EventEmitter<PageEvent> = new EventEmitter();

    public readonly threadList$ = this.localStore.selectByKey("treadsList");
    public readonly assignees$ = this.localStore.selectByKey("assignees");
    public readonly areFiltersApplied$ = this.localStore.selectByKey("areFiltersApplied");
    public readonly processingThreadsAssignments$ = this.localStore.selectByKey("processingThreadsAssignments");
    public readonly storedAvatarPictures$ = this.localStore.selectByKey("storedAvatarPictures");
    public readonly pagination$ = this.localStore.selectByKey("pagination");
    protected readonly displayedColumns = ["icon", "owner", "operator", "subject", "deliveryDate", "assignment"];

    constructor(private localStore: LocalComponentStore<ThreadsListComponentState>) {
        localStore.setState({
            treadsList: [],
            assignees: undefined,
            processingThreadsAssignments: [],
            areFiltersApplied: false,
            storedAvatarPictures: {},
            pagination: undefined,
        });
    }
}
