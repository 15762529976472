import { ContentChildren, Directive, forwardRef, QueryList } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { MAT_LEGACY_RADIO_GROUP } from "@angular/material/legacy-radio";
import { _MatRadioGroupBase } from "@angular/material/radio";
import { RadioFieldComponent } from "./radio-field.component";

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: "dtm-ui-radio-group",
    host: { role: "radiogroup" },
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioGroupDirective),
            multi: true,
        },
        { provide: MAT_LEGACY_RADIO_GROUP, useExisting: RadioGroupDirective },
    ],
})
export class RadioGroupDirective extends _MatRadioGroupBase<RadioFieldComponent> {
    @ContentChildren(forwardRef(() => RadioFieldComponent), { descendants: true }) public _radios!: QueryList<RadioFieldComponent>;
}
