import { ChangeDetectionStrategy, Component, forwardRef } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import { FinalRiskAreaModel, OperationalAirspaceClassType, OperationalArc } from "@dtm-frontend/shared/ui";
import { AnimationUtils, FunctionUtils, ONLY_WHITE_SPACES_VALIDATION_PATTERN } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { operationArcTypes } from "../../../models/permits.models";

const OTHER_AIRSPACE_NAME_MAX_LENGTH = 150;

interface FinalRiskAreaForm {
    operationArea: FormControl<OperationalArc | null>;
    adjacentArea: FormControl<OperationalArc | null>;
    operationalAirSpaces: FormControl<OperationalAirspaceClassType[] | null>;
    otherAirspace: FormControl<string | null>;
}

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-final-risk-class-area",
    templateUrl: "./final-risk-class-area-form.component.html",
    styleUrls: ["../../styles/shared-permit-styles.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FinalRiskClassAreaFormComponent), multi: true },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => FinalRiskClassAreaFormComponent),
            multi: true,
        },
    ],
    animations: [AnimationUtils.slideInAnimation()],
})
export class FinalRiskClassAreaFormComponent implements ControlValueAccessor, Validator {
    protected readonly finalRiskAreaForm = new FormGroup<FinalRiskAreaForm>({
        operationArea: new FormControl(null, Validators.required),
        adjacentArea: new FormControl(null, Validators.required),
        operationalAirSpaces: new FormControl([], { validators: Validators.required }),
        otherAirspace: new FormControl(
            { value: null, disabled: true },
            {
                validators: [
                    Validators.required,
                    Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
                    Validators.maxLength(OTHER_AIRSPACE_NAME_MAX_LENGTH),
                ],
            }
        ),
    });
    protected readonly OperationalAirspaceClassType = OperationalAirspaceClassType;
    protected readonly operationArcTypes = operationArcTypes;

    private propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: FinalRiskAreaModel) => void = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    constructor() {
        this.finalRiskAreaForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            this.propagateChange(this.finalRiskAreaForm.getRawValue() as FinalRiskAreaModel);
        });
        this.finalRiskAreaForm.controls.operationalAirSpaces.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            this.manageOtherAirspaceNameControl(value);
        });
    }

    public registerOnChange(fn: (value: FinalRiskAreaModel) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public writeValue(value: FinalRiskAreaModel): void {
        if (value) {
            this.finalRiskAreaForm.reset(
                {
                    operationArea: value.operationArea,
                    adjacentArea: value.adjacentArea,
                    operationalAirSpaces: value.operationalAirSpaces,
                    otherAirspace: value.otherAirspace,
                },
                { emitEvent: false }
            );
            if (value.operationalAirSpaces.includes(OperationalAirspaceClassType.Other)) {
                this.finalRiskAreaForm.controls.otherAirspace.setValue(value.otherAirspace ?? null);
                this.finalRiskAreaForm.controls.otherAirspace.enable();
            }
        } else {
            this.finalRiskAreaForm.reset();
        }
    }

    public validate(): ValidationErrors | null {
        if (this.finalRiskAreaForm.valid) {
            return null;
        }

        return this.finalRiskAreaForm.invalid ? { invalidFinalRiskArea: true } : null;
    }

    protected manageOtherAirspaceNameControl(value: OperationalAirspaceClassType[] | null): void {
        if (!value || !value.includes(OperationalAirspaceClassType.Other)) {
            this.finalRiskAreaForm.controls.otherAirspace.disable();
            this.finalRiskAreaForm.controls.otherAirspace.reset();

            return;
        }

        if (value.includes(OperationalAirspaceClassType.Other)) {
            this.finalRiskAreaForm.controls.otherAirspace.enable();

            return;
        }
    }
}
