import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { TranslocoService } from "@jsverse/transloco";
import { ToastrService } from "ngx-toastr";

@Component({
    templateUrl: "./serial-numbers-dialog.component.html",
    styleUrls: ["./serial-numbers-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SerialNumbersDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) protected readonly data: { serialNumbers: string[] },
        private readonly toastService: ToastrService,
        private readonly transloco: TranslocoService
    ) {}

    protected joinWithComma(serialNumbers: string[]) {
        return serialNumbers.join(", ");
    }

    protected handleValueCopy(isSuccess: boolean) {
        if (isSuccess) {
            this.toastService.success(this.transloco.translate("dtmUi.copyToClipboard.successMessage"));
        } else {
            this.toastService.error(this.transloco.translate("dtmUi.copyToClipboard.errorMessage"));
        }
    }
}
