import { ContextOperator, GlobalFeatures, GlobalOperatorPermissions, OperatorType } from "@dtm-frontend/shared/ui";
import { getDefaultLang, mapLocaleToLang } from "@dtm-frontend/shared/ui/i18n";
import { GlobalCapabilities, GlobalCapabilitiesPilot } from "../models/operator-context.models";

enum GlobalFeaturesResponseBody {
    Payments = "PAYMENTS",
    OperationsManual = "INOP",
    Members = "MEMBERS",
    SpecificPermitApplication = "SPECIFIC_PERMIT_APPLICATION",
    MissionPlanner = "MISSION_PLANNER",
    Statements = "STATEMENTS",
    UseSpecificPermit = "USE_SPECIFIC_PERMIT",
    UtmSupervisorIntegration = "UTM_SUPERVISOR_INTEGRATION",
    WeatherForecast = "WEATHER_FORECAST",
    AviationLav2025 = "AVIATION_LAV_2025",
}

interface GlobalCapabilitiesUserResponseBody {
    firstName: string;
    lastName: string;
    avatarUrl: string;
    nationalNodeAuthenticated: boolean;
    requiredRegisterNationalNodeAuthenticatedUser: boolean;
    languageTag: string;
}

interface GlobalCapabilitiesOperatorResponseBody {
    id: string;
    name: string;
    type: OperatorType;
    avatarUrl: string | undefined;
    features?: GlobalFeaturesResponseBody[];
    permissions?: GlobalOperatorPermissions[];
}

interface GlobalCapabilitiesPilotResponseBody {
    id: string;
    operatorId: string;
    firstName: string;
    lastName: string;
    avatarUrl?: string;
    features: GlobalFeaturesResponseBody[];
}

export interface GlobalCapabilitiesResponseBody {
    operators: GlobalCapabilitiesOperatorResponseBody[];
    pilot?: GlobalCapabilitiesPilotResponseBody;
    allowedToRegisterOperator: boolean;
    allowedToRegisterPilot: boolean;
    user: GlobalCapabilitiesUserResponseBody;
    elearningPortalUrl: string;
    requiredToAcceptTermsOfService: boolean;
    features?: GlobalFeaturesResponseBody[];
}

function convertGlobalFeaturesResponseBodyToGlobalFeatures(featureResponse?: GlobalFeaturesResponseBody[]): GlobalFeatures[] {
    return (
        featureResponse?.map((feature) => {
            switch (feature) {
                case GlobalFeaturesResponseBody.Payments:
                    return GlobalFeatures.Payments;
                case GlobalFeaturesResponseBody.OperationsManual:
                    return GlobalFeatures.OperationsManual;
                case GlobalFeaturesResponseBody.Members:
                    return GlobalFeatures.Members;
                case GlobalFeaturesResponseBody.SpecificPermitApplication:
                    return GlobalFeatures.SpecificPermitApplication;
                case GlobalFeaturesResponseBody.MissionPlanner:
                    return GlobalFeatures.MissionPlanner;
                case GlobalFeaturesResponseBody.Statements:
                    return GlobalFeatures.Statements;
                case GlobalFeaturesResponseBody.UseSpecificPermit:
                    return GlobalFeatures.UseSpecificPermit;
                case GlobalFeaturesResponseBody.UtmSupervisorIntegration:
                    return GlobalFeatures.UtmSupervisorIntegration;
                case GlobalFeaturesResponseBody.WeatherForecast:
                    return GlobalFeatures.WeatherForecast;
                case GlobalFeaturesResponseBody.AviationLav2025:
                    return GlobalFeatures.AviationLav2025;
            }
        }) ?? []
    );
}
export function convertGlobalCapabilitiesResponseBodyToContextOperators(response: GlobalCapabilitiesResponseBody): ContextOperator[] {
    if (!response.operators) {
        return [];
    }

    const timeStamp = Date.now();

    return response.operators.map((operator: GlobalCapabilitiesOperatorResponseBody): ContextOperator => {
        let avatarUrl = operator.avatarUrl && `${operator.avatarUrl}?ts=${timeStamp}`;

        if (operator.type === OperatorType.Personal) {
            avatarUrl = response.pilot?.avatarUrl && `${response.pilot?.avatarUrl}?ts=${timeStamp}`;
        }

        return {
            id: operator.id,
            name: operator.name,
            type: operator.type,
            avatarUrl,
            features: convertGlobalFeaturesResponseBodyToGlobalFeatures(operator.features),
            permissions: operator.permissions ?? [],
        };
    });
}

function convertGlobalCapabilitiesPilotResponseBodyToGlobalCapabilitiesPilot(
    pilotResponse?: GlobalCapabilitiesPilotResponseBody
): GlobalCapabilitiesPilot | undefined {
    if (!pilotResponse) {
        return;
    }

    return {
        id: pilotResponse.id,
        operatorId: pilotResponse.operatorId,
        firstName: pilotResponse.firstName,
        lastName: pilotResponse.lastName,
        avatarUrl: pilotResponse.avatarUrl,
    };
}

export function convertGlobalCapabilitiesResponseBodyToGlobalCapabilities(response: GlobalCapabilitiesResponseBody): GlobalCapabilities {
    return {
        operators: convertGlobalCapabilitiesResponseBodyToContextOperators(response),
        pilot: convertGlobalCapabilitiesPilotResponseBodyToGlobalCapabilitiesPilot(response.pilot),
        isAllowedToRegisterOperator: response.allowedToRegisterOperator,
        isAllowedToRegisterPilot: response.allowedToRegisterPilot,
        isRequiredToAcceptTermsOfService:
            response.requiredToAcceptTermsOfService && !response.user.requiredRegisterNationalNodeAuthenticatedUser,
        user: {
            isRequiredRegisterNationalNodeAuthenticatedUser: response.user.requiredRegisterNationalNodeAuthenticatedUser,
            isNationalNodeAuthenticated: response.user.nationalNodeAuthenticated,
            avatarUrl: response.user.avatarUrl,
            firstName: response.user.firstName,
            lastName: response.user.lastName,
        },
        elearningPortalUrl: response.elearningPortalUrl,
        languageTag: mapLocaleToLang(response.user.languageTag) ?? getDefaultLang(),
        features: convertGlobalFeaturesResponseBodyToGlobalFeatures(response.features),
    };
}
