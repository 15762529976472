import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input, forwardRef } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import { MissionType } from "@dtm-frontend/shared/mission";
import {
    AirRiskMitigationModel,
    AirRiskMitigationType,
    Competency,
    ContainmentLevelReachedType,
    TacticalAirRiskMitigationType,
} from "@dtm-frontend/shared/ui";
import { FunctionUtils, LocalComponentStore, ONLY_WHITE_SPACES_VALIDATION_PATTERN } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

interface AirRiskMitigationForm {
    strategicMitigationMeasure: FormControl<AirRiskMitigationType | null>;
    tacticalAirRiskMitigationMeasure: FormControl<TacticalAirRiskMitigationType | null>;
    tacticalAirRiskMitigationRequirements: FormControl<string | null>;
    containmentReachedLevel: FormControl<ContainmentLevelReachedType | null>;
    competencies: FormControl<Competency[]>;
    additionalCompetencies: FormControl<string[]>;
    competenciesDescription: FormControl<string>;
    staffCompetencies: FormControl<string[]>;
    staffCompetenciesDescription: FormControl<string>;
}

interface AirRiskMitigationFormComponentState {
    shouldShowCompetenciesAsDescription: boolean;
    competencies: Competency[];
}

export const MAX_TACTICAL_AIR_RISK_MITIGATION_REQUIREMENTS_LENGTH = 500;
export const MAX_COMPETENCIES_DESCRIPTION_LENGTH = 1000;

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-air-risk-mitigation",
    templateUrl: "./air-risk-mitigation-form.component.html",
    styleUrls: ["./air-risk-mitigation-form.component.scss", "../../styles/shared-permit-styles.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AirRiskMitigationFormComponent), multi: true },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => AirRiskMitigationFormComponent),
            multi: true,
        },
    ],
})
export class AirRiskMitigationFormComponent implements ControlValueAccessor, Validator {
    @Input() public set competencies(value: Competency[] | undefined) {
        this.localStore.patchState({ competencies: value ?? [] });
    }

    @Input() public set shouldShowCompetenciesAsDescription(value: BooleanInput) {
        const shouldShowCompetenciesAsDescription = coerceBooleanProperty(value);

        this.localStore.patchState({ shouldShowCompetenciesAsDescription });

        if (shouldShowCompetenciesAsDescription) {
            this.airRiskMitigationForm.controls.competencies.removeValidators(Validators.required);
        } else {
            this.airRiskMitigationForm.controls.competencies.addValidators(Validators.required);
        }
        this.airRiskMitigationForm.controls.competencies.updateValueAndValidity();
    }

    protected readonly AirRiskMitigationType = AirRiskMitigationType;
    protected readonly MissionType = MissionType;
    protected readonly TacticalAirRiskMitigationType = TacticalAirRiskMitigationType;
    protected readonly MAX_TACTICAL_AIR_RISK_MITIGATION_REQUIREMENTS_LENGTH = MAX_TACTICAL_AIR_RISK_MITIGATION_REQUIREMENTS_LENGTH;
    protected readonly MAX_COMPETENCIES_DESCRIPTION_LENGTH = MAX_COMPETENCIES_DESCRIPTION_LENGTH;
    protected readonly competencies$ = this.localStore.selectByKey("competencies");
    protected readonly shouldShowCompetenciesAsDescription$ = this.localStore.selectByKey("shouldShowCompetenciesAsDescription");
    protected readonly bvlosTacticalRiskMitigationMeasures = Object.values(TacticalAirRiskMitigationType).filter(
        (value) => value !== TacticalAirRiskMitigationType.VlosSeeAndAvoid
    );
    protected readonly airRiskMitigationForm = new FormGroup<AirRiskMitigationForm>({
        strategicMitigationMeasure: new FormControl(null, Validators.required),
        tacticalAirRiskMitigationMeasure: new FormControl(null, Validators.required),
        tacticalAirRiskMitigationRequirements: new FormControl(null, [
            Validators.maxLength(MAX_TACTICAL_AIR_RISK_MITIGATION_REQUIREMENTS_LENGTH),
            Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
        ]),
        containmentReachedLevel: new FormControl(null, Validators.required),
        competencies: new FormControl([], { nonNullable: true, validators: Validators.required }),
        additionalCompetencies: new FormControl([], { nonNullable: true }),
        competenciesDescription: new FormControl("", {
            nonNullable: true,
            validators: Validators.maxLength(MAX_COMPETENCIES_DESCRIPTION_LENGTH),
        }),
        staffCompetencies: new FormControl([], { nonNullable: true }),
        staffCompetenciesDescription: new FormControl("", {
            nonNullable: true,
            validators: Validators.maxLength(MAX_COMPETENCIES_DESCRIPTION_LENGTH),
        }),
    });

    constructor(private readonly localStore: LocalComponentStore<AirRiskMitigationFormComponentState>) {
        this.localStore.setState({
            shouldShowCompetenciesAsDescription: false,
            competencies: [],
        });

        this.airRiskMitigationForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            this.propagateChange(this.prepareResult(this.airRiskMitigationForm.getRawValue() as AirRiskMitigationModel));
        });
    }

    private propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: AirRiskMitigationModel) => void = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    public registerOnChange(fn: (value: AirRiskMitigationModel) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public writeValue(value: AirRiskMitigationModel): void {
        if (value) {
            this.airRiskMitigationForm.reset(
                {
                    strategicMitigationMeasure: value.strategicMitigationMeasure,
                    containmentReachedLevel: value.containmentReachedLevel,
                    tacticalAirRiskMitigationMeasure: value.tacticalAirRiskMitigationMeasure,
                    tacticalAirRiskMitigationRequirements: value.tacticalAirRiskMitigationRequirements,
                    competencies: value.competencies ?? [],
                    additionalCompetencies: value.additionalCompetencies.length ? value.additionalCompetencies : [],
                    competenciesDescription: value.competenciesDescription ?? "",
                    staffCompetencies: value.staffCompetencies.length ? value.staffCompetencies : [],
                    staffCompetenciesDescription: value.staffCompetenciesDescription ?? "",
                },
                { emitEvent: false }
            );
        } else {
            this.airRiskMitigationForm.reset();
        }
    }

    public validate(): ValidationErrors | null {
        if (this.airRiskMitigationForm.valid) {
            return null;
        }

        return { airRiskMitigationForm: true };
    }

    private prepareResult(value: AirRiskMitigationModel): AirRiskMitigationModel {
        return {
            ...value,
            additionalCompetencies: value.additionalCompetencies.filter(FunctionUtils.isTruthy),
            staffCompetencies: value.staffCompetencies.filter(FunctionUtils.isTruthy),
        };
    }
}
