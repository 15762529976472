<div class="form-wrapper" [formGroup]="additionalAccidentForm">
    <dtm-admin-lib-confirm-tile formControlName="hasInsurance">
        <label>{{ "dtmAdminLibPermits.additionalAccidentType.insuranceLabel" | transloco }}</label>
        <div
            class="field-error"
            *dtmUiFieldHasError="additionalAccidentForm.controls.hasInsurance; name: ['required']"
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>
    </dtm-admin-lib-confirm-tile>
    <dtm-ui-input-field>
        <label>{{ "dtmAdminLibPermits.additionalAccidentType.operationsManualReferenceLabel" | transloco }}</label>
        <input matInput formControlName="operationsManualReference" type="text" />
        <div
            class="field-error"
            *dtmUiFieldHasError="additionalAccidentForm.controls.operationsManualReference; name: ['required', 'pattern']"
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>
        <div
            class="field-error"
            *dtmUiFieldHasError="additionalAccidentForm.controls.operationsManualReference; name: 'maxlength'; error as error"
        >
            {{ "dtmAdminLibPermits.additionalAccidentType.maxLengthErrorLabel" | transloco : { maxLength: error.requiredLength } }}
        </div>
    </dtm-ui-input-field>
    <div class="additional-limitations">
        <label class="label">{{ "dtmAdminLibPermits.additionalAccidentType.additionalLimitationsLabel" | transloco }}</label>
        <dtm-ui-quill-editor
            [formControl]="additionalAccidentForm.controls.additionalLimitations"
            [hasError]="!!additionalAccidentForm.controls.additionalLimitations.errors"
        >
        </dtm-ui-quill-editor>
    </div>
</div>
<ng-template #requiredErrorTemplate>
    {{ "dtmAdminLibPermits.additionalAccidentType.requiredField" | transloco }}
</ng-template>
