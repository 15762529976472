import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface UavListTileComponentState {
    operatorNumber: string | undefined;
}

@Component({
    selector: "dtm-admin-lib-uav-list-tile",
    templateUrl: "./uav-list-tile.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UavListTileComponent {
    @Input({ required: true }) public set operatorNumber(value: string | undefined) {
        this.localStore.patchState({ operatorNumber: value });
    }

    protected readonly operatorNumber$ = this.localStore.selectByKey("operatorNumber");

    constructor(private readonly localStore: LocalComponentStore<UavListTileComponentState>, private readonly router: Router) {
        localStore.setState({ operatorNumber: undefined });
    }

    protected redirectToUavList(operatorNumber: string) {
        this.router.navigate(["uav", "list"], { queryParams: { operatorNumber } });
    }
}
