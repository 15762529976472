import { ChangeDetectionStrategy, Component, forwardRef } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import { OperationalVolumeHeightModel } from "@dtm-frontend/shared/ui";
import { FunctionUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

const MIN_AREA_HEIGHT_VALUE = 1;
const MAX_AREA_HEIGHT_VALUE = 20000;

interface OperationalVolumeHeightForm {
    operationalVolumeHeightLimit: FormControl<number | null>;
}

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-operation-area-height-form",
    templateUrl: "./operation-area-height-form.component.html",
    styleUrls: ["../../styles/shared-permit-styles.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => OperationAreaHeightFormComponent), multi: true },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => OperationAreaHeightFormComponent),
            multi: true,
        },
    ],
})
export class OperationAreaHeightFormComponent implements ControlValueAccessor, Validator {
    protected readonly operationalVolumeHeightForm = new FormGroup<OperationalVolumeHeightForm>({
        operationalVolumeHeightLimit: new FormControl(null, {
            validators: [Validators.required, Validators.min(MIN_AREA_HEIGHT_VALUE), Validators.max(MAX_AREA_HEIGHT_VALUE)],
        }),
    });
    protected readonly MAX_AREA_HEIGHT_VALUE = MAX_AREA_HEIGHT_VALUE;
    protected readonly MIN_AREA_HEIGHT_VALUE = MIN_AREA_HEIGHT_VALUE;

    private propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: OperationalVolumeHeightModel) => void = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    constructor() {
        this.operationalVolumeHeightForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            this.propagateChange(this.operationalVolumeHeightForm.getRawValue() as OperationalVolumeHeightModel);
        });
    }

    public registerOnChange(fn: (value: OperationalVolumeHeightModel) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public writeValue(value: OperationalVolumeHeightModel): void {
        if (value) {
            this.operationalVolumeHeightForm.reset(
                {
                    operationalVolumeHeightLimit: value.operationalVolumeHeightLimit,
                },
                { emitEvent: false }
            );
        } else {
            this.operationalVolumeHeightForm.reset();
        }
    }

    public validate(): ValidationErrors | null {
        if (this.operationalVolumeHeightForm.valid) {
            return null;
        }

        return this.operationalVolumeHeightForm.invalid ? { invalidOperationalVolumeHeight: true } : null;
    }
}
