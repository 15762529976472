import { Injectable } from "@angular/core";
import { Page } from "@dtm-frontend/shared/ui";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY } from "rxjs";
import { catchError, finalize, tap } from "rxjs/operators";
import { UavApiService } from "../services/uav-api.service";
import { UavError, UavListItem } from "../services/uav.models";
import { UavActions } from "./uav.actions";

export interface UavStateModel {
    isProcessing: boolean;
    uavsError: UavError | undefined;
    uavs: UavListItem[] | undefined;
    uavsPagination: Page | undefined;
}

const defaultState: UavStateModel = {
    isProcessing: false,
    uavsError: undefined,
    uavs: undefined,
    uavsPagination: undefined,
};

@State<UavStateModel>({
    name: "uav",
    defaults: defaultState,
})
@Injectable()
export class UavState {
    constructor(private readonly uavApiService: UavApiService) {
        if (uavApiService === undefined) {
            throw new Error("Initialize UavModule with .forRoot()");
        }
    }

    @Selector()
    public static isProcessing(state: UavStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static uavsError(state: UavStateModel): UavError | undefined {
        return state.uavsError;
    }

    @Selector()
    public static uavs(state: UavStateModel): UavListItem[] | undefined {
        return state.uavs;
    }

    @Selector()
    public static uavsPagination(state: UavStateModel): Page | undefined {
        return state.uavsPagination;
    }

    @Action(UavActions.GetUavs, { cancelUncompleted: true })
    public getUavs(context: StateContext<UavStateModel>, action: UavActions.GetUavs) {
        context.patchState({
            isProcessing: true,
        });

        return this.uavApiService.getUavs(action.params).pipe(
            tap((result) =>
                context.patchState({
                    uavs: result.content,
                    uavsError: undefined,
                    uavsPagination: result.pagination,
                })
            ),
            catchError((error) => {
                context.patchState({ uavsError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }
}
